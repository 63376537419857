<template>
  <div>
    <FileUpload accept=".csv" v-on:change="fileChange" />
    <div v-if="parsing">{{ $t("analyzing") }}...</div>
    <v-dialog v-model="dialog" max-width="600">
      <UserError v-on:ok="closeDialog">
        <template v-slot:title>
          {{ $t("error") }}
        </template>
        <template v-slot:text>
          {{ errorMsg }}
        </template>
      </UserError>
    </v-dialog>
  </div>
</template>

<script>
import FileUpload from "./FileUpload";
import Papa from "papaparse";
import UserError from "@/components/misc/UserError";
import LoadingSpinner from "@/components/misc/LoadingSpinner";

export default {
  name: "CsvParser",
  components: { LoadingSpinner, UserError, FileUpload },

  props: {
    accept: {
      type: String
    }
  },

  data: () => ({
    dialog: false,
    parsing: false,
    errorMsg: ""
  }),

  methods: {
    showErrorMsg(msg) {
      this.errorMsg = msg;
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("clear");
      this.dialog = false;
    },
    fileChange(file) {
      if (!file) {
        this.$emit("clear");
        return;
      }
      if (!fileIsCsv(file.name)) {
        this.showErrorMsg(this.$t("mustBeCsv"));
        return;
      }
      this.parse(file);
    },
    parse(file) {
      this.parsing = true;

      getFileText(file)
        .then(text => {
          // Use UTF8 as default, but we switch to iso88591 if we find one or more "invalid character" in the text,
          // since that is a most likley format used in a CSV produced by Microsoft Office. This will handle
          // letters like æ ø å
          // https://stackoverflow.com/a/36652446/529171
          const useUtf8 = text.search(/\uFFFD/g) === -1;
          const encoding = useUtf8 ? "utf8" : "iso88591";
          const that = this;
          Papa.parse(file, {
            encoding: encoding,
            // We handle empty lines after parsing
            skipEmptyLines: false,
            complete(res) {
              that.$emit("done", res);
              that.parsing = false;
            },
            error(error) {
              that.parsing = false;
              let errMsg = this.$t("unableToGetTxtFromFile");
              if (error.message) {
                errMsg += ":" + error.message;
              }
              this.showErrorMsg(errMsg);
            }
          });
        })
        .catch(() => {
          this.showErrorMsg(this.$t("unableToGetTxtFromFile"));
        });
    }
  }
};

const fileIsCsv = fileName => {
  return fileName.substr(-4).toLowerCase() === ".csv";
};

const getFileText = file => {
  return new Promise((resolve, reject) => {
    if (file.text) {
      file.text().then(text => {
        if (!text) {
          reject();
        } else {
          resolve(text);
        }
      });
    } else {
      // We use FileReader.readAsText if File.text() is not supported by browser
      const fr = new FileReader();
      fr.onload = function(e) {
        if (e.target && e.target.result) {
          resolve(e.target.result);
        } else {
          reject();
        }
      };
      fr.readAsText(file);
    }
  });
};
</script>
