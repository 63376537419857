<template>
  <v-card>
    <v-card-title v-if="title" class="subtitle-2">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div>
        <v-container>
          <v-row>
            <v-spacer />
            <MassDeletion
              class="mt-2 mr-4"
              type-of-deletion="contacts"
              :items="selectedContacts"
              v-on:done="onMassDeleteDone"
              v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
            />
            <MassDeletion
              v-if="groupId"
              type-of-deletion="members"
              :items="selectedContacts"
              :group-id="groupId"
              v-on:done="onMassDeleteDone"
              class="mt-2 mr-4"
              v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
            />
            <v-btn
              v-if="!groupId"
              @click="showNewContactForm"
              class="mr-4 mt-2"
              data-testid="newContactContactList"
              :block="$vuetify.breakpoint.xs"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t("createNewContact") }}
            </v-btn>
            <v-menu offset-y v-if="groupId && !readOnlyGroup">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 mt-2"
                  data-testid="addContactContactList"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("addContact") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="showAddContactsDialog"
                  data-testid="addFromContactListContactList"
                >
                  <v-list-item-title>
                    {{ $t("addFromContactList") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="showNewContactForm"
                  data-testid="createContactListContactList"
                >
                  <v-list-item-title>
                    {{ $t("createNewContact") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="showImportDialog">
                  <v-list-item-title>
                    {{ $t("importFromFile") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <LoadingSpinner
              :smart-delay="false"
              class="mr-5"
              v-if="exporting"
              id="exporting"
            />
            <ExportBtn
              v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
              v-on:click="exportContacts"
              v-if="!exporting"
              data-testid="exportBtnContactList"
              :block="$vuetify.breakpoint.xs"
              class="mr-4 mt-2"
            />
            <UpdateBtn
              :block="$vuetify.breakpoint.xs"
              v-on:click="update"
              class="mr-4 mt-2"
              data-testid="updateContactList"
            />
          </v-row>
        </v-container>
      </div>
      <br style="clear: both" />
      <br />

      <v-text-field
        :label="$t('search')"
        v-model.trim="search"
        autocomplete="off"
        data-testid="searchContactList"
        maxlength="48"
      />
      <DataTable
        :items="contacts"
        :headers="responsiveHeaders"
        item-key="Id"
        :server-items-length="contactsTotal"
        :page="pageNumber"
        :show-select="showRowSelect"
        v-model="selectedContacts"
        v-on:update:options="onOptionsUpdate"
      >
        <template v-slot:item.actions="{ item }">
          <div v-if="!readOnlyGroup || item.AdSync" class="action-btns">
            <v-btn
              v-if="item.KontaktType !== 1 && !item.AdSync"
              block
              outlined
              @click="select(item)"
              small
              class="mt-2"
              data-testid="visContactsList"
            >
              <v-icon left small>
                mdi-eye
              </v-icon>
              {{ $t("vis") }}
            </v-btn>
            <v-btn
              v-if="
                groupId &&
                  item.KontaktType !== 1 &&
                  (!item.AdSync || (item.AdSync && !readOnlyGroup))
              "
              block
              outlined
              @click="confirmContactRemove(item.Id)"
              data-testid="removeContactsList"
              class="mt-2 mb-2"
              small
            >
              <v-icon left small>
                mdi-close
              </v-icon>
              {{ $t("remove") }}
            </v-btn>
            <v-btn
              @click="confirmContactDelete(item.Id)"
              outlined
              v-if="groupId && !item.AdSync"
              small
              block
              class="mt-2 mb-2"
            >
              <v-icon left small>
                mdi-delete
              </v-icon>
              {{ $t("delete") }}
            </v-btn>
          </div>
          <div v-if="item.KontaktType === 1 && !groupId">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex justify-center align-center"
                >
                  mdi-lock
                </v-icon>
              </template>
              <span>{{ $t("smsCodewordContactsEditInfo") }}</span>
            </v-tooltip>
          </div>
          <div v-if="readOnlyGroup || isReadOnlyContact(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex justify-center align-center"
                >
                  mdi-lock
                </v-icon>
              </template>
              <span>{{ $t("impContactLockedInfo") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.Kommentar="{ item }">
          <span v-if="item.KontaktType === 1">
            {{ parseSmsCodewordContactInfo(item.Kommentar) }}
          </span>
          <span v-else>{{ item.Kommentar }}</span>
        </template>
      </DataTable>

      <v-dialog
        v-model="dialogDetails"
        scrollable
        persistent
        :max-width="dialogWidth.xLarge"
        :fullscreen="$vuetify.breakpoint.xs"
      >
        <ContactDetails
          v-if="contactDetailsId !== null"
          :contact-id="contactDetailsId"
          :group-id="groupId"
          v-on:close="closeDetailsDialog"
          v-on:delete="closeDetailsDialog"
        />
      </v-dialog>

      <v-dialog
        v-model="dialogAdd"
        v-if="dialogAdd"
        scrollable
        persistent
        :max-width="dialogWidth.xLarge"
        :fullscreen="$vuetify.breakpoint.xs"
      >
        <ContactsSelect
          :group-id-to-ignore="groupId"
          v-on:done="addContacts"
          v-on:cancel="dialogAdd = false"
        />
      </v-dialog>

      <v-dialog v-model="dialogImport">
        <GroupImport
          file-type="csv"
          v-on:done="
            dialogImport = false;
            update();
          "
          v-on:cancel="dialogImport = false"
          :default-selected-group-after-import="groupId"
        />
      </v-dialog>

      <v-dialog v-model="dialogRemove" :max-width="dialogWidth.medium">
        <UserConfirm
          :text="$t('removeContactConfirm')"
          v-on:ok="removeContact"
          v-on:cancel="dialogRemove = false"
        />
      </v-dialog>

      <v-dialog v-model="dialogDelete" :max-width="dialogWidth.medium">
        <UserConfirm
          alert-type="error"
          :text="$t('deleteContactConfirm')"
          v-on:ok="deleteContact"
          v-on:cancel="dialogDelete = false"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ContactDetails from "./ContactDetails";
import apiGroup from "../../api/v24/api.group";
import store from "../../store/index";
import apiContact from "@/api/v24/api.contact";
import debounce from "@/util/debounce";
import ContactsSelect from "@/components/contacts/ContactsSelect";
import config from "@/config/config";
import ExportBtn from "@/components/misc/ExportBtn";
import Export from "@/util/Export";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import GroupImport from "@/components/import/GroupImport";
import UpdateBtn from "@/components/misc/UpdateBtn";
import contactUtil from "@/util/contactUtil";
import DataTable from "@/components/dataTable/DataTable.vue";
import i18n from "../../i18n";
import MassDeletion from "@/components/massDeletion/MassDeletion.vue";
import UserConfirm from "@/components/misc/UserConfirm.vue";
import dateFilter from "@/filters/dateFilter";

export default {
  name: "ContactList",
  components: {
    UserConfirm,
    MassDeletion,
    DataTable,
    UpdateBtn,
    GroupImport,
    LoadingSpinner,
    ExportBtn,
    ContactsSelect,
    ContactDetails
  },

  props: {
    groupId: {
      type: Number,
      default: 0
    },
    title: {
      type: String
    },
    readOnlyGroup: {
      type: Boolean,
      default: false
    },
    updateTrigger: {
      type: Number
    }
  },

  watch: {
    groupId: {
      handler() {
        this.update();
      }
    },
    updateTrigger: {
      handler() {
        this.update();
      }
    },
    search: debounce.registerInput(function(newVal) {
      this.pageNumber = 1;
      this.searchContacts(newVal);
    }, 500),
    contactsUpdate() {
      this.update();
    }
  },

  data: () => ({
    contacts: [],
    selectedContacts: [],
    dialogDetails: false,
    dialogImport: false,
    dialogAdd: false,
    dialogDelete: false,
    dialogRemove: false,
    contactDeleteId: null,
    contactRemoveId: null,
    contact: null,
    contactDetailsId: 0,
    search: "",
    pageNumber: 1,
    sortBy: "",
    sortDesc: false,
    contactsTotal: 0,
    exporting: false,
    dialogWidth: config.dialogWith,
    dateFilter: dateFilter
  }),

  computed: {
    responsiveHeaders() {
      let headers = [
        { text: this.$t("name"), value: "Navn" },
        // Mobile and email are not sortable since they are stored in
        // JSON format in DB...
        { text: this.$t("mobile"), value: "Mobil", sortable: false },
        { text: this.$t("email"), value: "Epost", sortable: false }
      ];
      if (store.getters.getShowDepartmentInfoInLists) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          sortable: false,
          hide: "mdAndDown"
        });
      }
      headers.push(
        { text: this.$t("jobTitle"), value: "Stilling", hide: "mdAndDown" },
        { text: this.$t("otherInfo"), value: "Kommentar", hide: "mdAndDown" },
        { text: "", value: "actions", sortable: false }
      );
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    },
    contactsUpdate() {
      return store.state.contactsUpdate;
    },
    contactsPerPage() {
      return store.getters.getPreferredNumTableRows;
    },
    showRowSelect() {
      // Only valid when this component is use to show group members
      if (this.readOnlyGroup) {
        return false;
      }
      // Don't bother showing row select for mass deletion if user/customer are using AD provisioning.
      // They can't delete AD contacts. Yes, they may have non-AD contacts, but
      // they are probably few or none of them.
      return !store.state.userProfile?.Bruker.Tilgang.ADProvisjonering;
    }
  },

  methods: {
    parseSmsCodewordContactInfo(csvString) {
      // Stored with format
      // 2002;BANENOR;E;2024-04-17T10:44:26.313+02:00;
      const split = csvString.split(";");
      if (split.length === 5 && split[3]) {
        const date = new Date(split[3]);
        // Handle invalid date
        if (isNaN(date.getTime())) {
          return "";
        }
        // Use dateFilter from dateFilter.js
        return i18n.t("enrolled") + ": " + this.dateFilter(date, "short");
      }
      return "";
    },
    isReadOnlyContact(contact) {
      return contactUtil.isReadOnly(contact);
    },
    showAddContactsDialog() {
      this.dialogAdd = true;
    },
    searchContacts(searchStr) {
      if (this.groupId) {
        apiGroup
          .searchMembers(
            this.groupId,
            searchStr,
            this.pageNumber,
            this.contactsPerPage,
            this.sortBy,
            this.sortDesc
          )
          .then(res => this.setContacts(res));
      } else {
        apiContact
          .search(
            searchStr,
            this.pageNumber,
            this.contactsPerPage,
            this.sortBy,
            this.sortDesc
          )
          .then(res => this.setContacts(res));
      }
    },
    update() {
      if (this.groupId) {
        if (this.search) {
          apiGroup.searchMembers(
            this.groupId,
            this.search,
            this.pageNumber,
            this.contactsPerPage,
            this.sortBy,
            this.sortDesc
          );
        } else {
          apiGroup
            .getMembers(
              this.groupId,
              this.pageNumber,
              this.contactsPerPage,
              this.sortBy,
              this.sortDesc
            )
            .then(res => this.setContacts(res));
        }
      } else {
        if (this.search) {
          this.searchContacts(this.search);
        } else {
          apiContact
            .getMultiple(
              this.pageNumber,
              this.contactsPerPage,
              this.sortBy,
              this.sortDesc
            )
            .then(res => this.setContacts(res));
        }
      }
    },
    setContacts(fetchResult) {
      const targetFieldName = this.groupId ? "Medlem" : "Kontakter";
      this.contacts = fetchResult[targetFieldName] || [];
      this.contactsTotal = fetchResult.AntallTotalt;
    },
    onOptionsUpdate(options) {
      this.pageNumber = options.page;
      // this.contactsPerPage = options.itemsPerPage;
      this.sortBy = options.sortBy.length ? options.sortBy[0] : "Navn";
      this.sortDesc = options.sortDesc.length ? options.sortDesc[0] : false;
      this.update();
    },
    confirmContactDelete(id) {
      this.contactDeleteId = id;
      this.dialogDelete = true;
    },
    deleteContact() {
      apiContact.delete(this.contactDeleteId).then(() => {
        this.dialogDelete = false;
        this.update();
      });
    },
    confirmContactRemove(id) {
      this.contactRemoveId = id;
      this.dialogRemove = true;
    },
    removeContact() {
      apiGroup.removeContact(this.groupId, this.contactRemoveId).then(() => {
        this.dialogRemove = false;
        this.update();
      });
    },
    onMassDeleteDone() {
      this.update();
      this.selectedContacts = [];
    },
    select(contact) {
      this.contact = contact;
      this.contactDetailsId = contact.Id;
      this.dialogDetails = true;
    },
    closeDetailsDialog() {
      this.dialogDetails = false;
      this.contact = null;
      this.contactDetailsId = null;
      this.update();
    },
    showNewContactForm() {
      this.contact = null;
      this.contactDetailsId = 0;
      this.dialogDetails = true;
    },
    addContacts(contacts) {
      let validContactsCounter = 0;
      const promises = [];
      // We assume that search filter is no longer needed when user has added a
      // new contact. Can be confusing that new contact is not in list
      this.search = "";
      contacts.forEach(contact => {
        if (contact.Import && !contact.AdSync) {
          // Should never happen, but just in case, don't mess with imported contacts.
          // We allow ad sync contacts
          // https://app.zenhub.com/workspaces/varsling-24-5ba010a44b5806bc2be88fdb/issues/gh/framweb/groupalert/732
          return;
        }
        const promise = apiGroup.addContact(this.groupId, contact.Id);
        validContactsCounter++;
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        this.update();
        store.commit(
          "setAppSnackbar",
          this.$tc("xContactsAdded", validContactsCounter)
        );
      });
      this.dialogAdd = false;
    },
    exportContacts(userOptions) {
      this.exporting = true;
      let returnField;
      let promise;
      if (this.groupId) {
        returnField = "Medlem";
        promise = apiGroup.getMembers(this.groupId, 1, 9999999);
      } else {
        returnField = "Kontakter";
        promise = apiContact.getMultiple(1, 99999999);
      }
      promise.then(res => {
        let allMembers = res[returnField];
        const data = [];
        allMembers.forEach(contact => {
          let i = 0;
          data.push([
            contact.Navn,
            contact.Mobil,
            contact.Mobil2,
            contact.Mobil3,
            contact.Fast,
            contact.Fast2,
            contact.Epost,
            contact.Stilling,
            contact.Kommentar
          ]);
        });
        const fileName = this.$t("contacts").toLowerCase();
        const exporter = new Export(fileName, data);
        if (userOptions.useExcel) {
          exporter.exportAsExcel();
        } else {
          const separator = config.getLocaleProperties().csvSeparator;
          exporter.exportAsCsvFile(separator);
        }
        this.exporting = false;
      });
    },
    showImportDialog() {
      this.dialogImport = true;
    }
  }
};
</script>
<style scoped>
#exporting {
  float: right;
}
.xsBlockFix {
  width: 100%;
  margin-right: 0 !important;
}
.action-btns {
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
}
</style>
