<template>
  <div v-bind:class="[enabled ? 'elevation-3 channel-panel' : 'mb-n6']">
    <v-switch
      v-model="enabled"
      :label="$t('sendEmail')"
      v-bind:class="[enabled ? 'mt-n1' : '']"
      @change="emitChange"
      data-testid="sendEmail"
    ></v-switch>
    <div v-if="enabled">
      <v-text-field
        v-model="title"
        @change="emitChange"
        maxlength="80"
        :label="$t('subject')"
        outlined
        class="mb-n6"
        data-testid="subject"
      ></v-text-field>
      <v-textarea
        @change="emitChange"
        class="mb-n5"
        id="text-email"
        v-model="text"
        :label="$t('emailText')"
        outlined
        data-testid="emailText"
      ></v-textarea>
      <DynamicTextInfo class="mt-n8" />
      <EmailAttachmentSelect
        v-if="permissionFiles && !hideAttachmentFeature"
        v-on:change="setAttachements"
        class="mt-2 mb-4"
      />
      <TestEmailText :text="text" :subject="title" />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import DynamicTextInfo from "@/components/misc/DynamicTextInfo";
import stringUtil from "@/util/stringUtil";
import TestEmailText from "@/components/composers/TestEmailText";
import EmailAttachmentSelect from "../../../EmailAttachmentSelect";
import store from "@/store";

export default {
  name: "EmailComposer",
  components: { EmailAttachmentSelect, TestEmailText, DynamicTextInfo },
  props: {
    initEnabled: {
      default: false
    },
    initTitle: {
      default: ""
    },
    initText: {
      default: ""
    },
    hideAttachmentFeature: {
      default: false
    }
  },

  computed: {
    permissionFiles() {
      return store.state.userProfile?.Bruker._permissions.files;
    }
  },

  data: () => ({
    enabled: false,
    title: "",
    text: "",
    attachments: []
  }),

  created() {
    this.enabled = this.initEnabled;
    this.title = this.initTitle;
    this.text = this.initText;
    store.dispatch("updateUserProfile");
  },

  watch: {
    initText(newText) {
      this.text = newText;
    }
  },

  methods: {
    setAttachements(files) {
      this.attachments = files;
      this.emitChange();
    },
    emitChange() {
      this.text = stringUtil.fixUnsupportedWhitespace(this.text);
      this.title = stringUtil.fixUnsupportedWhitespace(this.title);
      this.$emit("change", {
        enabled: this.enabled,
        title: this.title,
        text: this.text,
        attachments: this.attachments
      });
    }
  }
};
</script>
