<template>
  <v-card>
    <v-card-title class="headline">{{ $t("responses") }}</v-card-title>

    <v-card-text>
      <p>{{ $t("infoResponse") }}</p>
      <p>
        <strong>{{ $t("infoResponse2") }}</strong>
      </p>
      <div v-for="res in responses">
        <v-card>
          <v-card-title>
            {{ getResponseLabel(res) }}
          </v-card-title>
          <v-card-text>
            <v-select 
              v-model="res.value" 
              outlined 
              @change="optionChanged(res)" 
              :items="choices" 
              :label="$t('select')" 
              data-testid="responseOptionsComposerCard"
              ></v-select>

            <!-- maxlength is 37 so that respons text will fit in
            ReceiverResponseRegistration view on small screens -->
            <v-text-field 
              v-if="res.useCustomValue" 
              outlined 
              v-model="res.customValue" 
              :label="$t('typeInCustfRes')" 
              maxlength="37" 
              />

            <v-text-field 
              v-if="res.useCustomValue" 
              v-model="res.link" 
              dense 
              outlined 
              :label="linkBtnLabel" 
              :hint="$t('dummyLink')" 
              class="mt-n4" 
              :rules="urlRules"
              />

            <ResponsePreview 
              :number="res.num" 
              :text="getResponseValue(res)" 
              :link="res.link" 
              />
          </v-card-text>
        </v-card>
        <br />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="done()" data-testid="responseOptionsComposerCardOK">
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ResponseChip from "./ResponseChip";
import ResponsePreview from "@/components/misc/ResponsePreview";
import formValidationRules from "@/util/formValidationRules";

export default {
  name: "ResponseOptionsComposer",
  components: { ResponsePreview, ResponseChip },

  props: {
    /**
     * Array of format
     *
     * { num: "1", value: "", customValue: "", useCustomValue: false, link: "" },
     * { num: "2", value: "", customValue: "", useCustomValue: false, link: "" },
     * etc
     */
    responses: {
      type: Array
    }
  },

  data: () => ({
    urlRules: formValidationRules.urlRules
  }),

  computed: {
    choices() {
      return [
        { text: this.$t("none"), value: "" },
        { text: this.$t("customResOpt"), value: "customResOpt" },
        { text: '"' + this.$t("ok") + '"', value: this.$t("ok") },
        { text: '"' + this.$t("yes") + '"', value: this.$t("yes") },
        { text: '"' + this.$t("no") + '"', value: this.$t("no") },
        { text: '"' + this.$t("confirmRecMsg") + '"', value: this.$t("confirmRecMsg") },
        { text: '"' + this.$t("notAvail") + '"', value: this.$t("notAvail") },
        { text: '"' + this.$t("maybe") + '"', value: this.$t("maybe") },
        { text: '"' + this.$t("iAmBusy") + '"', value: this.$t("iAmBusy") }
      ];
    },
    linkBtnLabel() {
      return `${this.$t("goToLinkBtnPress")}
        (${this.$t("optional").toLowerCase()})`;
    }
  },

  methods: {
    getResponseLabel(response) {
      return this.$t("alternative") + " " + response.num;
    },
    getResponseValue(response) {
      return response.customValue || response.value;
    },
    optionChanged(response) {
      response.customValue = "";
      if (response.value === "customResOpt") {
        response.value = "";
        response.useCustomValue = true;
      } else {
        response.useCustomValue = false;
      }
    },
    done() {
      this.responses.forEach(respons => {
        if (respons.customValue) {
          // Remove any previus values if custom value is set
          respons.value = "";
        }
      });
      this.$emit("done", this.responses);
      this.dialog = false;
    }
  }
};
</script>
