<template>
  <div>
    <div>
      <v-container>
        <v-row>
          <v-spacer />
          <UpdateBtn
            v-on:click="fetchFiles"
            :block="$vuetify.breakpoint.xs"
            class="mr-4 mt-2"
          />
          <v-btn
            v-if="isAudioFile"
            class="mr-4 mt-2"
            @click="dialogRecord = true"
            :block="$vuetify.breakpoint.xs"
          >
            <v-icon>mdi-microphone</v-icon>
            {{ $t("recording") }}
          </v-btn>
          <v-btn
            @click="dialogUpload = true"
            :block="$vuetify.breakpoint.xs"
            class="mt-2"
          >
            <v-icon>mdi-upload</v-icon>
            {{ $t("upload") }}
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <div class="mt-4">
      <v-text-field v-model="search" :label="$t('search')" />
      <DataTable
        :items="files"
        :headers="headers"
        :search="search"
        :custom-filter="filterList"
      >
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <v-icon @click="edit(item)" left>
              mdi-pencil
            </v-icon>
            <v-icon v-if="isAudioFile" @click="showAudioPlayer(item)" left>
              mdi-play
            </v-icon>
            <v-icon
              :disabled="fileIsProtected(item)"
              @click="askToDelete(item)"
              left
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
        <template
          v-if="isAudioFile"
          v-slot:item.MetaData.FJson.Duration="{ item }"
        >
          <span v-if="item.MetaData?.FJson?.Duration">
            {{ item.MetaData.FJson.Duration | durationFilter }}
          </span>
        </template>
      </DataTable>
    </div>

    <v-dialog
      v-model="dialogRecord"
      v-if="dialogRecord"
      :max-width="dialogWidth.medium"
      persistent
    >
      <AudioRecorder
        v-on:cancel="onRecordingDone(false)"
        v-on:done="onRecordingDone(true)"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogUpload"
      :max-width="dialogWidth.medium"
      v-if="dialogUpload"
    >
      <AlertFileUpload :is-audio-file="isAudioFile" v-on:done="onUploadDone" />
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      :max-width="dialogWidth.medium"
      v-if="dialogEdit"
    >
      <EditAlertFile
        :is-audio-file="isAudioFile"
        :file-to-edit="fileToEdit"
        v-on:done="onUploadDone"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogPlayback"
      v-if="dialogPlayback"
      :max-width="dialogWidth.small"
    >
      <v-card>
        <v-card-title>
          {{ playbackFile.Description }}
        </v-card-title>
        <v-card-subtitle>
          {{ playbackFile.FileName }}
        </v-card-subtitle>
        <v-card-text>
          <v-layout align-center justify-center>
            <AudioPlayback
              :audio-file-id="playbackFile.FileId"
              :autoplay="true"
            />
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogPlayback = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      :max-width="dialogWidth.medium"
      v-if="dialogDelete"
    >
      <UserConfirm
        :text="$t('deleteFileX?', { fileName: fileToDelete.FileName })"
        v-on:cancel="dialogDelete = false"
        v-on:ok="deleteFile"
      />
    </v-dialog>
  </div>
</template>

<script>
import FileUpload from "@/components/import/FileUpload";
import config from "@/config/config";
import AlertFileUpload from "@/components/files/AlertFileUpload";
import apiFile from "@/api/v24/api.file";
import durationFilter from "@/filters/durationFilter";
import UserConfirm from "@/components/misc/UserConfirm";
import EditAlertFile from "@/components/userManagement/EditAlertFile";
import UpdateBtn from "@/components/misc/UpdateBtn";
import AudioRecorder from "@/components/files/AudioRecorder.vue";
import AudioPlayback from "@/components/files/AudioPlayback.vue";
import user from "@/util/user";
import search from "@/util/search";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "FileManagement",
  components: {
    DataTable,
    AudioPlayback,
    AudioRecorder,
    UpdateBtn,
    EditAlertFile,
    UserConfirm,
    AlertFileUpload,
    FileUpload
  },

  props: {
    isAudioFile: {
      type: Boolean,
      default: false
    },
    // Array with ID to files that must not be deleted
    protectedFiles: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("description"),
          value: "Description"
        },
        {
          text: this.$t("fileName"),
          value: "FileName"
        }
      ];
      if (this.isAudioFile) {
        headers.push({
          text: this.$t("duration"),
          value: "MetaData.FJson.Duration"
        });
      }
      headers.push({ text: "", value: "actions", sortable: false });
      return headers;
    }
  },

  data: () => ({
    files: [],
    dialogRecord: false,
    dialogUpload: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogPlayback: false,
    dialogWidth: config.dialogWith,
    totSize: 0,
    fileType: "",
    durationFilter: durationFilter,
    fileToDelete: null,
    fileToEdit: null,
    search: "",
    playbackFile: ""
  }),

  created() {
    this.fileType = this.isAudioFile ? "A" : "E";
    this.fetchFiles();
  },

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    fetchFiles() {
      apiFile.getAll(this.fileType).then(res => {
        this.files = res.Files;
        this.totSize = res.TotalSize;
      });
    },

    fileIsProtected(file) {
      return this.protectedFiles.some(
        protectedFile => protectedFile === file.FileId
      );
    },

    onUploadDone() {
      this.dialogUpload = false;
      this.dialogEdit = false;
      this.onChange();
    },

    onRecordingDone(updateFiles) {
      this.dialogRecord = false;
      if (updateFiles) {
        this.onChange();
      }
    },

    onChange() {
      this.fetchFiles();
      this.emitChange();
    },

    askToDelete(file) {
      this.fileToDelete = file;
      this.dialogDelete = true;
    },

    deleteFile() {
      apiFile.deleteFile(this.fileToDelete.FileId).then(() => {
        this.fetchFiles();
        this.dialogDelete = false;
        this.emitChange();
      });
    },

    edit(file) {
      this.fileToEdit = file;
      this.dialogEdit = true;
    },

    showAudioPlayer(file) {
      this.playbackFile = file;
      this.dialogPlayback = true;
    },

    emitChange() {
      this.$emit("change");
    }
  }
};
</script>
