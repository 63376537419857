<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ text }}
      <v-textarea
        autofocus
        outlined
        v-model="userInput"
        ref="input"
        :maxlength="maxLength.toString()"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="cancel">{{ $t("cancel") }}</v-btn>
      <v-btn class="primary" @click="ok">{{ $t("ok") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UserTextInput",

  data: () => ({
    userInput: ""
  }),

  props: {
    title: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      required: true
    },
    text: {
      type: String
    },
    initInputValue: {
      type: String
    }
  },

  created() {
    this.userInput = this.initInputValue;
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    ok() {
      this.$emit("ok", this.userInput);
    }
  }
};
</script>
