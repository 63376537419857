<template>
  <div v-if="responsesDefined">
    <v-alert type="info" dense outlined class="mt-1 mb-8">
      {{ $t("checkLinkRefTxt") }}
    </v-alert>
    <div class="body-2">{{ $t("responseChoicesSmsEmail") }}</div>
    <ul>
      <li v-for="response in responseOptions" v-if="response.text">
        {{ response.text }}
        <span v-if="response.link">
          ({{ $t("willLeadUserTo").toLocaleLowerCase() }} {{ response.link }})
        </span>
      </li>
    </ul>
  </div>
  <div v-else class="mt-6">
    {{ $t("noResponseChoices") }}
  </div>
</template>

<script>
import alertResponseOptions from "@/util/alertResponseOptions";

export default {
  name: "ResponseChoiceList",

  props: {
    apiParams: {
      type: Object,
      required: true
    }
  },

  computed: {
    responsesDefined() {
      if (!this.apiParams.Svar) {
        return false;
      }
      let i = 1;
      for (i; i <= 9; i++) {
        if (this.apiParams.Svar["A" + i]) {
          return true;
        }
      }
      return false;
    },
    responseOptions() {
      return alertResponseOptions.parseApiFormat(this.apiParams.Svar);
    }
  }
};
</script>
