import apiContact from "@/api/v24/api.contact";

const contactUtil = {
  /**
   * Will return true if contact has contact data that is set up for alert.
   * Example: If contact is registred with
   * email address this method will return true if alert is setup to send
   * emails. Will not count memebers when source is "largeGroup".
   * @param {Object} contact Contact as returned from API
   * @param {boolean} sendSms Alert is setup to send SMS
   * @param {boolean} sendEmail Alert is setup to send email
   * @param {boolean} sendVoice Alert is setup to send voice message
   * @returns {boolean}
   */
  contactWillBeIncludedInAlert(contact, sendSms, sendEmail, sendVoice) {
    if (contact._source.name === "largeGroup") {
      return false;
    }

    const fields = [
      { name: "Mobil", type: "M" },
      { name: "Fast", type: "F" },
      { name: "Epost", type: "E" }
    ];
    let i = 0;
    const len = fields.length;
    for (i; i < len; i++) {
      if (
        contact[fields[i].name] &&
        this.contactDataWillBeIncludedInAlert(
          fields[i].type,
          sendSms,
          sendEmail,
          sendVoice
        )
      ) {
        return true;
      }
    }
    return false;
  },

  isReadOnly(contact) {
    return contact.Import || contact.AdSync;
  },

  contactDataWillBeIncludedInAlert(type, sendSms, sendEmail, sendVoice) {
    if (type === "M" && sendSms) {
      return true;
    } else if (type === "E" && sendEmail) {
      return true;
    } else if ((type === "F" || type === "M") && sendVoice) {
      return true;
    }
    return false;
  },

  searchForDuplicatesSingle(contactName) {
    return new Promise(resolve => {
      apiContact.search(contactName, -1, -1).then(res => {
        // API will return every contact that start with the string
        // in contactName. We want (at the time beiing at least) to only
        // regard exact match as duplicate. Therefor run the result through
        // our own dupe check before resolving
        resolve(dupeSearch([contactName], res.Kontakter));
      });
    });
  },

  searchForDuplicatesList(contactNames, abortSignal = null) {
    return new Promise((resolve, reject) => {
      apiContact.getAll(abortSignal).then(allContacts => {
        resolve(dupeSearch(contactNames, allContacts));
      }).catch(reject);
    });
  }
};

const dupeSearch = (newNames, existingContacts) => {
  let allPossibleDupes = [];
  const nameHash = {};
  existingContacts.forEach(existingContact => {
    const existingName = existingContact.Navn.trim().toLowerCase();
    if (!nameHash[existingName]) {
      nameHash[existingName] = [];
    }
    nameHash[existingName].push(existingContact);
  });
  newNames.forEach(nameToCheck => {
    const newName = nameToCheck.trim().toLowerCase();
    if (nameHash[newName]) {
      allPossibleDupes = allPossibleDupes.concat(nameHash[newName]);
    }
  });
  return allPossibleDupes;
};

export default contactUtil;
