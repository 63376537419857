<template>
  <div>
    <v-text-field
      outlined
      v-model="sender"
      :label="getLabel()"
      dense
      data-testid="senderSmsSender"
    />
    <v-alert type="error" v-if="validationErrors">
      {{ validationErrors }}
    </v-alert>
  </div>
</template>

<script>
import SmsSender from "@/util/SmsSender";

export default {
  name: "SmsSender",

  props: {
    initValue: {
      type: String
    },
    optional: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // Using watch instead of @change/@keypress since text can come from
    // right click paste with mouse
    sender: {
      handler() {
        this.change();
      }
    }
  },

  computed: {
    validationErrors() {
      const smsSender = new SmsSender(this.sender);
      if (this.sender === "" && this.optional) {
        return "";
      }
      return smsSender.validate();
    }
  },

  data: () => ({
    sender: ""
  }),

  created() {
    this.sender = this.initValue;
  },

  methods: {
    getLabel() {
      let label = this.$t('smsSender');
      if (this.optional) {
        label += " " + this.$t("optionalHint")
      }
      return label;
    },
    change() {
      const isValid = !this.validationErrors;
      this.$emit("change", {
        text: this.sender,
        isValid: isValid
      });
    }
  }
};
</script>
