import api from "./api";

const apiSms = {
  sendSingle(sender, content, recipient, spamFilterTimeout = 0) {
    const params = {
      Messages: [
        {
          Sender: sender,
          Content: content,
          Recipient: recipient,
          SpamFilter: spamFilterTimeout
        }
      ]
    };
    return new Promise((resolve, reject) => {
      api.execute("SendSmsExt", params).then(res => {
        if (res.Stats && res.Stats.SendCount) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }
};

export default apiSms;
