import numberUtil from "@/util/numberUtil";
import config from "@/config/config";

const emailUtil = {
  /**
   * @name attachmentsFileSizeIsOk
   * @param attachments Array with file structure as returned from API
   */
  attachmentsFileSizeIsOk(attachments) {
    let totSize = 0;
    attachments.forEach(selFile => {
      totSize += selFile.FileSize;
    });
    const mb = numberUtil.bytesToMegabytes(totSize, 2);
    return {
      totFileSizeMb: mb,
      limit: config.emailAttachmentMbimit,
      sizeOk: mb <= config.emailAttachmentMbimit
    };
  }
};

export default emailUtil;
