<template>
  <v-select
    :items="files"
    v-model="file"
    @change="emitChange"
    outlined
    :label="$t('chooseFile')"
    item-text="FileName"
    item-value="FileId"
    return-object
  >
    <template v-slot:item="{ item }">
      <span class="mr-6">
        {{ item.Description }}
      </span>
      <span class="font-weight-light font-italic">
        {{ item.FileName }}
      </span>
    </template>
  </v-select>
</template>

<script>
import apiFile from "@/api/v24/api.file";

export default {
  name: "FileSelect",

  props: {
    typeAudio: {
      type: Boolean,
      default: true
    },
    initFileId: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    files: [],
    file: null
  }),

  created() {
    const apiFileType = this.typeAudio ? "A" : "E";
    apiFile.getAll(apiFileType).then(res => {
      this.files = res.Files;
      if (this.initFileId) {
        this.file = this.files.find(file => file.FileId === this.initFileId);
      }
    });
  },

  methods: {
    emitChange() {
      this.$emit("change", this.file);
    }
  }
};
</script>
