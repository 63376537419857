import api from "@/api/v24/api";
import config from "@/config/config";

const apiEmail = {
  /**
   * @name send
   * @param senderName
   * @param subject
   * @param body
   * @param recipients Array with objects of type
    {
      Email: "",
      Name: ""
    }
   * @returns {Promise | Promise<unknown>}
   */
  send(senderName, subject, body, recipients) {
    const params = {
      Sender: config.defaultEmailSenderAddr,
      SenderName: senderName,
      Messages: [
        {
          Title: subject,
          Content: body,
          Simulation: false,
          SpamFilter: 0,
          Recipients: recipients
        }
      ]
    };
    return new Promise((resolve, reject) => {
      api.execute("SendEmail", params).then(res => {
        if (res.Status === "1 messages queued for sending") {
          resolve();
        } else {
          reject();
        }
      });
    });
  }
};

export default apiEmail;
