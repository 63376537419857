<template>
  <v-card>
    <v-card-title>
      {{ $t("selectContacts") }}
    </v-card-title>
    <v-card-text>
      <ContactMultiSelect
        :groupIdToIgnore="groupIdToIgnore"
        v-on:select="onSelect"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="cancel" data-testid="cancelContectsSelect">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        @click="done"
        class="primary"
        :disabled="!selectedContacts.length"
        data-testid="okContectsSelect"
      >
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ContactMultiSelect from "@/components/contacts/ContactMultiSelect";
export default {
  name: "ContactsSelect",

  components: { ContactMultiSelect },

  props: {
    groupIdToIgnore: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    selectedContacts: []
  }),

  methods: {
    onSelect(selectedContacts) {
      this.selectedContacts = selectedContacts;
    },
    cancel() {
      this.$emit("cancel");
    },
    done() {
      this.$emit("done", this.selectedContacts);
    }
  }
};
</script>
