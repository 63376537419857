<template>
  <v-chip close @click:close="emitClose">
    <v-avatar left>
      <v-icon small>
        mdi-account
      </v-icon>
    </v-avatar>
    {{ contact.Navn }}
  </v-chip>
</template>

<script>
export default {
  name: "ContactChip",

  props: {
    contact: {
      type: Object,
      required: true
    }
  },

  methods: {
    emitClose() {
      this.$emit("close", this.contact);
    }
  }
};
</script>
