const objectUtil = {
  // Sometime we must clone to avoid parent mutation. From Vue docs:
  // "Note that objects and arrays in JavaScript are passed by reference, so
  // if the prop is an array or object, mutating the object or array itself
  // inside the child component will affect parent state."
  deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }
};

export default objectUtil;
