<template>
  <div>
    <LoadingSpinner v-if="!deputyDetailsFetched" />
    <div v-else>
      <ContactChip
        :contact="selectedDeputy"
        v-if="selectedDeputy"
        v-on:close="unlinkDeputy"
      />
      <span v-else>
        {{ $t("noneSelected") }}
      </span>
      <v-btn outlined @click="dialogSelect = true" small class="ml-6" data-testid='deputySelectBtn'>
        {{ getBtnText() }}
      </v-btn>
    </div>

    <v-dialog v-model="dialogSelect" :max-width="dialogMaxWith">
      <v-card>
        <v-card-title>
          {{ $t("selectContact") }}
        </v-card-title>
        <v-card-text>
          <ContactSingleSelect
            :contacts-to-ignore="contactsToIgnore"
            v-on:change="setDeputy"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ContactSingleSelect from "@/components/contacts/ContactSingleSelect";
import apiContact from "@/api/v24/api.contact";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import config from "@/config/config";
import ContactChip from "@/components/contacts/ContactChip";

export default {
  name: "DeputySelect",
  components: {
    ContactChip,
    LoadingSpinner,
    ContactSingleSelect
  },
  props: {
    targetContact: {
      required: true,
      type: Object
    },
    initSelectedDeputyId: {
      default: null
    }
  },

  computed: {
    contactsToIgnore() {
      const ignoreList = [this.targetContact.Id];
      if (this.selectedDeputy) {
        ignoreList.push(this.selectedDeputy.Id);
      }
      return ignoreList;
    }
  },

  data: () => ({
    selectedDeputy: null,
    dialogSelect: false,
    deputyDetailsFetched: false,
    dialogMaxWith: config.dialogWith.medium
  }),

  created() {
    if (this.initSelectedDeputyId) {
      getDeputyDetails(this.initSelectedDeputyId).then(deputy => {
        this.selectedDeputy = deputy;
        this.deputyDetailsFetched = true;
      });
    } else {
      this.deputyDetailsFetched = true;
    }
  },

  methods: {
    getBtnText() {
      return this.selectedDeputy ? this.$t("change") : this.$t("select");
    },
    setDeputy(contact) {
      this.selectedDeputy = contact;
      this.dialogSelect = false;
      this.$emit("select", contact);
    },
    unlinkDeputy() {
      this.setDeputy(null);
    }
  }
};

const getDeputyDetails = deputyId => {
  return apiContact.get(deputyId);
};
</script>
