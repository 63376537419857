<template>
  <v-card :loading="!store.getters.getDefaultCountryPhoneCode">
    <v-card-title>
      {{ $t("importFromCsv") }}
    </v-card-title>
    <v-card-text>
      <!--
            Want to support more files types? Make sure that all parsers
            returns same the same format, so that this component can be used for all
            formats
            -->
      <CsvParser
        v-if="fileType === 'csv'"
        v-on:done="fileParsed"
        v-on:clear="reset"
        :key="componentKey"
      />

      <v-alert type="error" v-if="errors.length">
        <ul>
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
      </v-alert>
      <div v-if="data">
        <v-card>
          <v-card-title>
            {{ $t("settings") }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-radio-group
                  row
                  v-model="useFirstnameSurname"
                  @change="toggleCsvFieldFormat"
                >
                  <v-radio
                    :label="$t('sepColsForName')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('singleColForName')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <v-checkbox
                  v-model="excludeHeader"
                  :label="$t('firstRowIsHeader')"
                  :hint="$t('firstRowIsHeaderHint')"
                  persistent-hint
                />
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-row>
                  <v-col cols="12" md="6">
                    {{ $t("defaultCountryCodeWhenNoPrefixInfo") }}
                  </v-col>
                  <v-col cols="12" md="6">
                    <div id="contry-code-select">
                      <CountryPhoneCodeSelect
                        :init-value="defaultCountryCode"
                        v-on:change="changeDefaultCountryCode"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="prefixPlusToNumbers"
                    :label="$t('addPlusSymbolIfMissing')"
                    :hint="$t('addPlusSymbolIfMissingHint')"
                    persistent-hint
                  />
                  <div
                    v-if="prefixPlusToNumbers && !phoneColumnIsSet"
                    class="mt-4"
                  >
                    <v-alert type="warning" dense>
                      {{ $t("noPhoneColsSet") }}
                    </v-alert>
                  </div>
                  <div
                    v-if="prefixPlusToNumbers && phoneColumnIsSet"
                    class="mt-4"
                  >
                    <v-select
                      :label="$t('ignoreNumLessThan')"
                      v-model="ignoreNumLessThan"
                      :items="[4, 5, 6, 7, 8, 9, 10, 11, 12, 13]"
                      dense
                      outlined
                      style="max-width: 400px"
                    />
                    <v-alert type="warning" dense>
                      {{ $t("plusPrefixWarn") }}
                    </v-alert>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br />
        <v-card>
          <v-card-title>
            {{ $t("defineCols") }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <p>
                  <strong>{{ $t("defineColsDesc") }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-alert
                  type="info"
                  dense
                  v-if="readyToValidate && !userAutoColOverride"
                >
                  {{ $t("autoDetectedCols") }}
                </v-alert>
                <v-alert type="error" v-if="duplicateCols">
                  {{ $t("dupeColsErr") }}
                </v-alert>
              </v-col>
            </v-row>
            <v-data-table
              :items="data"
              :items-per-page="5"
              dense
              v-on:update:page="setPreviewTablePage"
            >
              <template v-slot:body="props">
                <tbody>
                  <tr v-if="fieldsMapping">
                    <td
                      class="d-block d-sm-table-cell"
                      v-for="num in fieldsMapping.length"
                    >
                      <v-select
                        :items="fieldsOptions"
                        v-model="fieldsMapping[num - 1]"
                        outlined
                        dense
                        @change="userAutoColOverride = true"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="d-block d-sm-table-cell"
                      v-for="num in fieldsMapping.length"
                    >
                      <div v-if="showPhoneTypeOptions(fieldsMapping[num - 1])">
                        <v-radio-group
                          :label="$t('type')"
                          v-model="phoneNumType[fieldsMapping[num - 1]]"
                          row
                          dense
                        >
                          <v-radio :label="$t('unspecified')" :value="0" />
                          <v-radio :label="$t('work')" :value="1" />
                          <v-radio :label="$t('private')" :value="2" />
                        </v-radio-group>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, index) in props.items"
                    v-bind:class="{
                      'text-decoration-line-through text--disabled': isExcludedRow(
                        index
                      )
                    }"
                  >
                    <td
                      class="d-block d-sm-table-cell"
                      v-for="field in Object.keys(item)"
                    >
                      <span>
                        {{ item[field] }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="cancel" data-testid="GroupImportCancel">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!readyToValidate"
        @click="validate()"
        v-show="!validating"
      >
        {{ $t("continue") }}
      </v-btn>
      <v-btn color="primary" disabled class="ml-2" v-show="validating">
        {{ $t("validating") }}
        <v-progress-circular
          v-show="validating"
          indeterminate
          size="20"
          color="primary"
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>

    <v-dialog
      v-model="dialogImport"
      :max-width="dialogWidth.xLarge"
      v-if="dialogImport"
      persistent
    >
      <v-card :loading="validationResult === null">
        <v-card-title>
          {{ $t("import") }}
        </v-card-title>
        <v-card-text v-if="validationResult">
          <LoadingSpinner v-if="savingImport" />
          <div v-else>
            <v-alert
              v-if="
                !validationResult.errors.length && !possibleDuplicates.length
              "
              type="success"
            >
              {{
                $t("rowsReadyToBeImported", {
                  amount: validationResult.okContacts.getCount()
                })
              }}
            </v-alert>

            <div
              v-if="validationResult.errors.length || possibleDuplicates.length"
            >
              <v-alert type="success" dense>
                {{
                  $t("rowsReadyToBeImported", {
                    amount: validationResult.okContacts.getCount()
                  })
                }}
              </v-alert>

              <div v-if="validationResult.errors.length">
                <v-card class="elevation-6">
                  <v-card-text>
                    <v-alert type="warning" dense>
                      {{ $t("cannotBeImported") }}
                    </v-alert>
                    <v-data-table
                      :items="validationResult.errors"
                      :headers="valErrTblHeaders"
                      :items-per-page="5"
                      dense
                    />
                  </v-card-text>
                </v-card>
              </div>

              <div v-if="possibleDuplicates.length" class="mt-8">
                <v-card class="elevation-6">
                  <v-card-text>
                    <v-alert type="warning" dense>
                      {{
                        $t("possibleDupes", {
                          amount: possibleDuplicates.length
                        })
                      }}
                    </v-alert>
                    <v-data-table
                      :items="possibleDuplicates"
                      :headers="dupesHeaders"
                      :items-per-page="5"
                      dense
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.AdSync || item.Import">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                          </template>
                          <span>{{ $t("impContactLockedInfo") }}</span>
                        </v-tooltip>
                        <v-icon
                          v-else
                          @click="viewPossibleDupeContact(item.Id)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </div>
            </div>

            <div v-if="validationResult.okContacts.getCount()">
              <v-checkbox
                v-if="alertMode && savePermission"
                v-model="showGroupAddOptions"
                :label="$t('addImpContactsToGrp')"
              />

              <div v-if="!alertMode || showGroupAddOptions">
                <v-radio-group v-model="addContactsToNewGroup" row>
                  <v-radio
                    :label="$t('createNewGroup')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('addToExistingGrp')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <div v-if="addContactsToNewGroup">
                  <DepartmentSelect
                    :init-department="department"
                    v-on:change="setDepartment"
                    dense
                  />
                  <v-text-field
                    v-model="newGroupName"
                    :label="$t('nameForNewGroup')"
                    outlined
                    maxlength="78"
                  />
                </div>
                <div v-else>
                  <GroupSelect
                    v-on:change="setGroupToAddContactsTo"
                    :exclude-external-imported-groups="true"
                    :default-selected-group-after-import="
                      defaultSelectedGroupAfterImport
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions v-if="!savingImport">
          <v-spacer />
          <v-btn
            @click="dialogImport = false"
            data-testid="GroupImportCancelBtn"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="importData"
            :disabled="importBtnDisabledState"
          >
            {{ $t("import") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadLimitWarningDialog" max-width="600">
      <UserError v-on:ok="uploadLimitWarningDialog = false">
        <template v-slot:text>
          {{ getMaxUploadWarningText() }}
        </template>
      </UserError>
    </v-dialog>

    <v-dialog
      v-model="dialogPossibleDupeContact"
      :max-width="dialogWidth.xLarge"
    >
      <ContactDetails
        :contact-id="possibleDupContactId"
        v-on:close="handleContactEdit"
        v-on:delete="handleContactEdit(true)"
        :check-for-duplicates="false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CsvParser from "./CsvParser";
import arrayUtil from "../../util/arrayUtil";
import GroupDataImport from "../../util/GroupDataImport";
import store from "../../store/index";
import GroupSelect from "../groups/GroupSelect";
import apiContact from "../../api/v24/api.contact";
import apiGroup from "../../api/v24/api.group";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import config from "@/config/config";
import UserError from "@/components/misc/UserError";
import CountryPhoneCodeSelect from "@/components/contacts/CountryPhoneCodeSelect";
import ContactUtil from "@/util/contactUtil";
import ContactDetails from "@/components/contacts/ContactDetails.vue";
import DepartmentSelect from "@/components/departments/DepartmentSelect.vue";
import user from "@/util/user";

export default {
  name: "GroupImport",
  components: {
    DepartmentSelect,
    ContactDetails,
    CountryPhoneCodeSelect,
    UserError,
    LoadingSpinner,
    GroupSelect,
    CsvParser
  },
  props: {
    fileType: {
      type: String,
      required: true
    },
    // Set to to true if imported contacts shall be added to the Vuex store of
    // alert receivers
    alertMode: {
      type: Boolean,
      default: false
    },
    defaultSelectedGroupAfterImport: {
      default: null
    }
  },
  data: () => ({
    store: store,
    errors: [],
    fieldsOptions: [],
    fieldsOptionsLegacy: [],
    fieldsMapping: null,
    useFirstnameSurname: false,
    parseResult: null,
    excludeHeader: false,
    userAutoColOverride: false,
    duplicateCols: false,
    dialogImport: false,
    dialogPossibleDupeContact: false,
    validationResult: false,
    showGroupAddOptions: false,
    addContactsToNewGroup: true,
    newGroupName: "",
    groupToAddContactsTo: null,
    savingImport: false,
    componentKey: 0,
    uploadLimitWarningDialog: false,
    defaultCountryCode: store.getters.getDefaultCountryPhoneCode,
    prefixPlusToNumbers: false,
    savePermission: false,
    ignoreNumLessThan: 9,
    validating: false,
    previewTablePage: 1,
    dialogWidth: config.dialogWith,
    possibleDuplicates: [],
    possibleDupContactId: null,
    department: "",
    abortController: null,
    // 0 (unknown), 1 (work), or 2 (private)
    phoneNumType: {
      mobile1: 1,
      mobile2: 1,
      mobile3: 1,
      landline1: 1,
      landline2: 1
    }
  }),

  watch: {
    fieldsMapping(newVal) {
      this.duplicateCols = !!arrayUtil.findDuplicates(newVal, true).length;
    }
  },

  computed: {
    data: {
      get() {
        if (!this.parseResult) {
          return null;
        }
        if (this.prefixPlusToNumbers) {
          return this.addPlusPrefixToPhoneNums(this.parseResult.data);
        } else {
          return this.parseResult.data;
        }
      },
      set(newVal) {
        if (this.parseResult) {
          this.$set(this.parseResult, "data", newVal);
        }
      }
    },
    importBtnDisabledState() {
      if (
        this.validationResult == null ||
        !this.validationResult.okContacts.getCount()
      ) {
        return true;
      }
      if (!this.alertMode || this.showGroupAddOptions) {
        if (this.addContactsToNewGroup && !this.newGroupName) {
          return true;
        }
        if (!this.addContactsToNewGroup && !this.groupToAddContactsTo) {
          return true;
        }
        const groups = store.state.groups;
        if (!this.addContactsToNewGroup && (!groups || !groups.length)) {
          return true;
        }
      }
      return this.savingImport;
    },
    readyToValidate() {
      if (this.errors.length || !this.data || this.duplicateCols) {
        return false;
      }
      let nameOk = false;
      if (this.useFirstnameSurname) {
        const firstNameOk = this.fieldsMapping.some(
          field => field === columnKeysLegacy.firstName
        );
        const surnameOk = this.fieldsMapping.some(
          field => field === columnKeysLegacy.surname
        );
        nameOk = firstNameOk && surnameOk;
      } else {
        nameOk = this.fieldsMapping.some(field => field === columnKeys.name);
      }

      const phoneDefined = this.phoneColumnIsSet;
      const emailDefined = this.fieldsMapping.some(
        field => field === columnKeys.email
      );
      return !!(nameOk && (phoneDefined || emailDefined));
    },
    /**
     * Return true if at least one column is set as phone number
     */
    phoneColumnIsSet() {
      const mobile1Selected = this.fieldsMapping.some(
        field => field === columnKeys.mobile1
      );
      const mobile2Selected = this.fieldsMapping.some(
        field => field === columnKeys.mobile2
      );
      const mobile3Selected = this.fieldsMapping.some(
        field => field === columnKeys.mobile3
      );
      const landline1Selected = this.fieldsMapping.some(
        field => field === columnKeys.landline1
      );
      const landline2Selected = this.fieldsMapping.some(
        field => field === columnKeys.landline2
      );

      return (
        mobile1Selected ||
        mobile2Selected ||
        mobile2Selected ||
        landline1Selected ||
        landline2Selected
      );
    },
    valErrTblHeaders() {
      return [
        { text: this.$t("line"), value: "lineNum" },
        { text: this.$t("error"), value: "error" }
      ];
    },
    dupesHeaders() {
      const headers = [
        { text: this.$t("name"), value: "Navn" },
        { text: this.$t("mobile"), value: "Mobil" },
        { text: this.$t("mobile2"), value: "Mobil2" },
        { text: this.$t("mobile3"), value: "Mobil3" },
        { text: this.$t("email"), value: "Epost" },
        { text: this.$t("jobTitle"), value: "Stilling" }
      ];
      if (store.getters.getShowDepartmentInfoInLists) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          sortable: false
        });
      }
      headers.push({
        text: "",
        value: "actions",
        sortable: false
      });
      return headers;
    }
  },

  created() {
    // fake import
    // this.fileParsed({
    //   data: [
    //     [
    //       "Fornavn",
    //       "Etternavn",
    //       "Stilling",
    //       "Telefon1",
    //       "Telefon2",
    //       "Telefon3",
    //       "Epost"
    //     ],
    //     [
    //       "Klara",
    //       "Olsen",
    //       "Arkitekt",
    //       "99999999",
    //       "44444444",
    //       "55555555",
    //       "asd@asdf.no"
    //     ]
    //   ],
    //   errors: []
    // });
    this.fieldsOptions = this.getFieldsOptions();
    store.dispatch("getUserPermissions").then(permissions => {
      if (permissions.manageContacts) {
        this.savePermission = true;
      }
    });

    if (this.defaultSelectedGroupAfterImport) {
      this.groupToAddContactsTo = this.defaultSelectedGroupAfterImport;
      this.addContactsToNewGroup = false;
    }

    user.getDepartmentForItemSave().then(department => {
      this.department = department;
    });
  },

  methods: {
    fileParsed(parseResult) {
      this.excludeHeader = false;
      if (parseResult.data.length > config.uploadContactsLimit) {
        this.uploadLimitWarningDialog = true;
        return;
      }
      // Overwrite error message if user uploads file with just one
      // columnm, to make it more clearer.
      if (
        parseResult.errors &&
        parseResult.errors.length &&
        parseResult.errors[0].code === "UndetectableDelimiter" &&
        parseResult.data[0].length === 1
      ) {
        parseResult.errors[0].message = this.$t("tooFewColumnsImported");
      }
      this.errors = parseResult.errors;
      // Deep clone to keep orignal result
      this.parseResult = JSON.parse(JSON.stringify(parseResult));
      this.detectNameFormat();
      this.setFieldMappingObjects();
    },
    addPlusPrefixToPhoneNums(data) {
      const that = this;
      // Get the columns that contain phone numbers
      const colsWithPhoneNumber = [];
      this.fieldsMapping.forEach((field, index) => {
        if (
          field === "mobile1" ||
          field === "mobile2" ||
          field === "mobile3" ||
          field === "landline1" ||
          field === "landline2"
        ) {
          colsWithPhoneNumber.push(index);
        }
      });

      // Loop through data and to any cleaning
      const newData = [];
      data.forEach((row, rowIndex) => {
        const newRow = [];
        if (that.excludeHeader && rowIndex === 0) {
          // User does not want to include the first row. Do nothing
          newData.push(row);
        } else {
          row.forEach((cell, cellIndex) => {
            let addPlus = false;
            const cellContainsPhoneNum = colsWithPhoneNumber.find(
              colWithPhone => colWithPhone === cellIndex
            );
            if (cellContainsPhoneNum) {
              const firstChar = cell.substr(0, 1);
              if (firstChar !== "+" && cell.length >= that.ignoreNumLessThan) {
                addPlus = true;
              }
            }
            const newCell = addPlus ? "+" + cell : cell;
            newRow.push(newCell);
          });
          newData.push(newRow);
        }
      });
      return newData;
    },
    setFieldMappingObjects() {
      this.fieldsMapping = [];
      this.fieldsMapping.length = 0;
      const indicationCols = getNamesThatIndicateColType(
        this.useFirstnameSurname
      );
      this.data[0].forEach(headerValue => {
        debug("------------");
        debug("Trying to find column for header value: " + headerValue);
        let foundMatch = false;
        for (let [colType, suggestions] of Object.entries(indicationCols)) {
          const hit = suggestions.some(suggestion => {
            debug(`${colType.toLowerCase()} === ${suggestion.toLowerCase()}?`);
            return suggestion.toLowerCase() === headerValue.toLowerCase();
          });
          if (hit) {
            foundMatch = true;
            debug("HIT: Will use colType: " + colType);
            this.fieldsMapping.push(colType);
            this.excludeHeader = true;
            break;
          } else {
            debug("No hit for this suggestion");
          }
        }
        if (!foundMatch) {
          debug("No match for header value");
          this.fieldsMapping.push("");
        }
      });
    },
    reset() {
      this.parseResult = null;
      this.data = null;
      this.errors = [];
      this.useFirstnameSurname = false;
      this.excludeHeader = false;
      this.userAutoColOverride = false;
      this.duplicateCols = false;
      this.validationResult = false;
      // this.addContactsToNewGroup = true;
      this.newGroupName = "";
      this.prefixPlusToNumbers = false;
      // Triggers re-rendering of the file input component
      this.componentKey += 1;
      this.possibleDuplicates = [];
    },
    detectNameFormat() {
      this.useFirstnameSurname = this.data[0].some(data => {
        return colNamesThatIndicateDoubleCols.some(name => {
          return data.toLowerCase() === name.toLowerCase();
        });
      });
      this.toggleCsvFieldFormat();
    },
    toggleCsvFieldFormat() {
      if (this.useFirstnameSurname) {
        this.fieldsOptions = this.getLegacyFieldsOptions();
      } else {
        this.fieldsOptions = this.getFieldsOptions();
      }
    },
    getFieldsOptions() {
      return [
        { text: this.$t("select"), value: "" },
        { text: this.$t("name"), value: columnKeys.name },
        { text: this.$t("jobTitle"), value: columnKeys.title },
        { text: this.$t("mobile"), value: columnKeys.mobile1 },
        { text: this.$t("mobile2"), value: columnKeys.mobile2 },
        { text: this.$t("mobile3"), value: columnKeys.mobile3 },
        { text: this.$t("landline"), value: columnKeys.landline1 },
        { text: this.$t("landline2"), value: columnKeys.landline2 },
        { text: this.$t("email"), value: columnKeys.email },
        { text: this.$t("otherInfo"), value: columnKeys.otherInfo }
      ];
    },
    getLegacyFieldsOptions() {
      return [
        { text: this.$t("select"), value: "" },
        { text: this.$t("firstName"), value: columnKeysLegacy.firstName },
        { text: this.$t("surname"), value: columnKeysLegacy.surname },
        { text: this.$t("jobTitle"), value: columnKeysLegacy.title },
        { text: this.$t("mobile"), value: columnKeysLegacy.mobile1 },
        { text: this.$t("mobile2"), value: columnKeysLegacy.mobile2 },
        { text: this.$t("mobile3"), value: columnKeysLegacy.mobile3 },
        { text: this.$t("landline"), value: columnKeysLegacy.landline1 },
        { text: this.$t("landline2"), value: columnKeysLegacy.landline2 },
        { text: this.$t("email"), value: columnKeysLegacy.email },
        { text: this.$t("otherInfo"), value: columnKeysLegacy.otherInfo }
      ];
    },
    validate() {
      this.validating = true;
      // Using setTimeout so that validation flag above will trigger loading
      // state in UI
      setTimeout(() => {
        this.validationResult = null;
        // Copies without keeping reference
        let data = [...this.data];
        const groupImport = new GroupDataImport(data, this.defaultCountryCode);
        let indexes = {};
        if (this.useFirstnameSurname) {
          indexes.firstName = this.fieldsMapping.indexOf(
            columnKeysLegacy.firstName
          );
          indexes.surname = this.fieldsMapping.indexOf(
            columnKeysLegacy.surname
          );
        } else {
          indexes.name = this.fieldsMapping.indexOf(columnKeys.name);
        }
        indexes.title = this.fieldsMapping.indexOf(columnKeys.title);
        indexes.mobile1 = this.fieldsMapping.indexOf(columnKeys.mobile1);
        indexes.mobile2 = this.fieldsMapping.indexOf(columnKeys.mobile2);
        indexes.mobile3 = this.fieldsMapping.indexOf(columnKeys.mobile3);
        indexes.landline1 = this.fieldsMapping.indexOf(columnKeys.landline1);
        indexes.landline2 = this.fieldsMapping.indexOf(columnKeys.landline2);
        indexes.email = this.fieldsMapping.indexOf(columnKeys.email);
        indexes.otherInfo = this.fieldsMapping.indexOf(columnKeys.otherInfo);
        this.validationResult = groupImport.validate(
          indexes,
          this.excludeHeader
        );
        if (store.state.userProfile?.Bruker._permissions.phoneNumTypes) {
          setCustomPhoneNumTypes(
            this.validationResult.okContacts.contacts,
            this.phoneNumType
          );
        }

        const contactNames = this.validationResult.okContacts.contacts.map(
          contact => {
            return contact.name;
          }
        );
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        ContactUtil.searchForDuplicatesList(contactNames, signal)
          .then(possibleDupes => {
            if (!this.validating) return;
            this.possibleDuplicates = possibleDupes;
            this.dialogImport = true;
            this.validating = false;
          })
          .catch(error => {
            console.log(error.message);
          });
      });
    },
    importData() {
      if (this.alertMode) {
        // Adds imported contacts to alert receivers list in Vuex store
        this.validationResult.okContacts.getAsApiFormat().forEach(contact => {
          store.commit("addAlertReceiver", contact);
        });
      }

      if (!this.alertMode || this.showGroupAddOptions) {
        // Creates group for imported contacts or adds to existing
        this.savingImport = true;
        if (this.addContactsToNewGroup) {
          if (this.department) {
            this.validationResult.okContacts.contacts.forEach(contact => {
              contact.setDepartment(this.department);
            });
          }
          apiGroup.create(this.newGroupName, this.department).then(group => {
            saveImportedContactsToGroup(
              this.validationResult.okContacts,
              group.Id
            )
              .then(() => {
                store.dispatch("updateGroups");
                this.closeImportDialog(group);
              })
              .finally(() => {
                this.savingImport = false;
              });
          });
        } else {
          saveImportedContactsToGroup(
            this.validationResult.okContacts,
            this.groupToAddContactsTo
          )
            .then(() => {
              this.closeImportDialog(this.groupToAddContactsTo);
            })
            .finally(() => {
              this.savingImport = false;
            });
        }
      } else {
        this.closeImportDialog();
      }
    },
    changeDefaultCountryCode(countryCode) {
      this.defaultCountryCode = countryCode;
    },
    closeImportDialog(targetGroup) {
      const closeAndEmit = group => {
        this.dialogImport = false;
        this.reset();
        this.$emit("done", group);
      };
      if (typeof targetGroup === "number") {
        // Is group ID, fetch group object from API
        apiGroup.get(targetGroup).then(targetGroupObj => {
          closeAndEmit(targetGroupObj);
        });
      } else {
        closeAndEmit(targetGroup);
      }
    },
    getMaxUploadWarningText() {
      return this.$tc("maxContactUploadWarning", config.uploadContactsLimit);
    },
    setGroupToAddContactsTo(group) {
      this.groupToAddContactsTo = group.Id;
    },
    setPreviewTablePage(page) {
      this.previewTablePage = page;
    },
    setDepartment(departmentId) {
      this.department = departmentId;
    },
    isExcludedRow(tableIndex) {
      return (
        tableIndex === 0 && this.excludeHeader && this.previewTablePage === 1
      );
    },
    viewPossibleDupeContact(id) {
      this.possibleDupContactId = id;
      this.dialogPossibleDupeContact = true;
    },
    handleContactEdit(changedContact) {
      this.dialogPossibleDupeContact = false;
      if (changedContact) {
        this.validate();
      }
    },
    showPhoneTypeOptions(selectedFieldOptionValue) {
      const phoneTypes = [
        "mobile1",
        "mobile2",
        "mobile3",
        "landline1",
        "landline2"
      ];
      const fieldIsPhone = phoneTypes.includes(selectedFieldOptionValue);
      const hasPermission =
        store.state.userProfile?.Bruker._permissions.phoneNumTypes;
      return fieldIsPhone && hasPermission;
    },
    cancel() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.validating = false;
      this.$emit("cancel");
    }
  }
};

const saveImportedContactsToGroup = (contacts, groupId) => {
  return apiContact.createContactsAndAddToGroup(contacts, groupId);
};

const columnKeys = {
  name: "name",
  title: "title",
  mobile1: "mobile1",
  mobile2: "mobile2",
  mobile3: "mobile3",
  landline1: "landline1",
  landline2: "landline2",
  email: "email",
  otherInfo: "otherInfo"
};

const columnKeysLegacy = {
  firstName: "firstName",
  surname: "surname",
  title: "title",
  mobile1: "mobile1",
  mobile2: "mobile2",
  mobile3: "mobile3",
  landline1: "landline1",
  landline2: "landline2",
  email: "email",
  otherInfo: "otherInfo"
};

const colNamesThatIndicateDoubleCols = [
  "fornavn",
  "fornamn",
  "firstname",
  "first name"
];

const getNamesThatIndicateColType = legacyFormat => {
  const namesThatIndicateColumnType = {};
  if (legacyFormat) {
    const c = columnKeysLegacy;
    namesThatIndicateColumnType[c.firstName] = [
      "fornavn",
      "first name",
      "firstname"
    ];
    namesThatIndicateColumnType[c.surname] = [
      "etternavn",
      "surname",
      "last name"
    ];
  } else {
    namesThatIndicateColumnType[columnKeys.name] = [
      "navn",
      "namn",
      "name",
      "full name",
      "fullname"
    ];
  }
  namesThatIndicateColumnType[columnKeys.title] = [
    "stilling",
    "title",
    "job title",
    "description",
    "job description"
  ];
  namesThatIndicateColumnType[columnKeys.mobile1] = [
    "mobil",
    "mobile",
    "mobil1",
    "mobile1",
    "mobiltelefon"
  ];
  namesThatIndicateColumnType[columnKeys.mobile2] = [
    "mobil 2",
    "mobile 2",
    "mobil2",
    "mobile2",
    "mobiltelefon2",
    "mobiltelefon 2"
  ];
  namesThatIndicateColumnType[columnKeys.mobile3] = [
    "mobil 3",
    "mobile 3",
    "mobil3",
    "mobile3",
    "mobiltelefon3",
    "mobiltelefon 3"
  ];
  namesThatIndicateColumnType[columnKeys.landline1] = [
    "landline",
    "land line",
    "fixed",
    "fixed line",
    "fixed phone",
    "fast",
    "fasttlf",
    "fasttlf.",
    "fasttelefon",
    "fast1",
    "landline1",
    "land line1",
    "fixed1",
    "fixed line1",
    "fixed phone1",
    "fast1",
    "fasttlf1",
    "fasttlf.1",
    "fasttelefon1",
    "fast1",
    "fast 1",
    "landline 1",
    "land line 1",
    "fixed 1",
    "fixed line 1",
    "fixed phone 1",
    "fast 1",
    "fasttlf 1",
    "fasttlf. 1",
    "fasttelefon 1",
    "fast 1"
  ];
  namesThatIndicateColumnType[columnKeys.landline2] = [
    "fast2",
    "landline2",
    "land line2",
    "fixed2",
    "fixed line2",
    "fixed phone2",
    "fast2",
    "fasttlf2",
    "fasttlf.2",
    "fasttelefon2",
    "fast2",
    "fast 2",
    "landline 2",
    "land line 2",
    "fixed 2",
    "fixed line 2",
    "fixed phone 2",
    "fast 2",
    "fasttlf 2",
    "fasttlf. 2",
    "fasttelefon 2",
    "fast 2"
  ];
  namesThatIndicateColumnType[columnKeys.email] = [
    "email",
    "e-mail",
    "epost",
    "e-post"
  ];
  namesThatIndicateColumnType[columnKeys.otherInfo] = [
    "kommentar",
    "comment",
    "information",
    "info",
    "diverse",
    "other",
    "other info",
    "misc",
    "other information",
    "desc",
    "description"
  ];
  return namesThatIndicateColumnType;
};

const setCustomPhoneNumTypes = (contacts, phoneNumType) => {
  contacts.forEach(contact => {
    if (contact.mobile1) {
      contact.setMobile1Type(phoneNumType.mobile1);
    }
    if (contact.mobile2) {
      contact.setMobile2Type(phoneNumType.mobile2);
    }
    if (contact.mobile3) {
      contact.setMobile3Type(phoneNumType.mobile3);
    }
    if (contact.landline1) {
      contact.setLandline1Type(phoneNumType.landline1);
    }
    if (contact.landline2) {
      contact.setLandline2Type(phoneNumType.landline2);
    }
  });
};

const debug = txt => {
  //console.log(txt);
};
</script>
<style scoped>
#contry-code-select {
  max-width: 100px;
}
</style>
