import Contact from "./Contact";

export default class Contacts {
  constructor() {
    this.contacts = [];
  }
  /**
   * @param {Contact} contact
   */
  add(contact) {
    this.contacts.push(contact);
  }

  getCount() {
    return this.contacts.length;
  }

  /**
   * @name getAsApiFormat
   * @param {Number} groupId Set to a number > 0 to tie contacts to given group ID.
   */
  getAsApiFormat(groupId = 0) {
    return this.contacts.map(contact => {
      return contact.getAsApiObject();
    });
  }
}
