<template>
  <v-select
    v-if="voices.length"
    v-model="voiceProps"
    outlined
    :items="voices"
    :item-text="getItemText"
    :label="$t('voice2')"
    key="Id"
    @change="onChange"
    return-object
  ></v-select>
</template>

<script>
import store from "@/store";

export default {
  name: "VoiceSelect",

  props: {
    initVoiceProps: {
      required: true
    }
  },

  data: () => ({
    voices: [],
    voiceProps: null
  }),

  created() {
    this.voiceProps = this.initVoiceProps;
    store.dispatch("updateVoices").then(voices => this.voices = voices);
  },

  methods: {
    getItemText(item) {
      return item.LanguageName + " - " + item.Name;
    },
    onChange() {
      this.$emit("change", this.voiceProps);
    },
  }
};

</script>
