<template>
  <v-card>
    <v-card-title>
      {{ $t("editName") }}
    </v-card-title>
    <v-form @submit.prevent="save" autocomplete="off" v-model="formValid" >
      <v-card-text>
        <v-text-field 
          v-model="name" 
          :label="$t('name')" 
          outlined 
          autofocus
          maxlength="80" 
          :rules="requiredFieldRule"
          @focus="resetNameField"
          data-testid='textFieldEditName' />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel" data-testid='cancelEditName'>{{ $t("cancel") }}</v-btn>
        <v-btn @click="save" class="primary" :disabled="!formValid" data-testid='saveEditName'>{{ $t("ok") }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import formValidationRules from "../../util/formValidationRules";

export default {
  name: "EditName",

  props: {
    initName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    name: "",
    requiredFieldRule: formValidationRules.requiredRule,
    formValid: true,
  }),

  created() {
    this.name = this.initName;
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("done", this.name);
    }, 
    resetNameField() {
        this.name = this.initName;
    }
  }
};
</script>
