<template>
  <div>
    <v-select
      :dense="dense"
      v-if="visible"
      v-model="selected"
      @change="userConfirm"
      :items="departments"
      item-text="Navn"
      item-value="AvdelingId"
      :loading="!departments"
      outlined
      :label="labelText"
      :hint="hint"
      :persistent-hint="!!hint"
      :disabled="disabled"
      data-testid="departmentSelect"
    />

    <v-dialog v-model="dialogConfirm" :max-width="dialogWidth.small" persistent>
      <UserConfirm
        :text="$t('confirmChangeGrpDep')"
        v-on:cancel="cancelDepartmentChange"
        v-on:ok="emitChange"
      />
    </v-dialog>
  </div>
</template>

<script>
import apiDepartment from "@/api/v24/api.department";
import store from "@/store";
import user from "@/util/user";
import config from "@/config/config";
import UserConfirm from "@/components/misc/UserConfirm.vue";

export default {
  name: "DepartmentSelect",
  props: {
    initDepartment: {
      default: ""
    },
    dense: {
      default: false
    },
    hint: {
      default: ""
    },
    label: {
      type: String,
      required: false
    },
    askForUserConfirm: {
      type: Boolean,
      default: false
    }
  },

  components: { UserConfirm },

  computed: {
    labelText() {
      return this.label ? this.label : this.$t("department");
    },
    visible() {
      if (!store.state.userProfile || !this.departments) {
        return false;
      }
      return this.departments.length > 1;
    },
    disabled() {
      if (!store.state.userProfile || !store.state.departments) {
        return false;
      }
      if (store.getters.getUserIsAdmin) {
        return false;
      }
      return !!user.getSelectedDepartment();
    },
    departments() {
      if (!store.state.userProfile || !store.state.departments) {
        return null;
      }
      const usrProfile = store.state.userProfile;
      if (store.getters.getUserIsAdmin) {
        // Admins can see all departments. And select no department.
        const departments = store.state.departments;
        return [
          {
            AvdelingId: "",
            Navn: this.$t("noDepartment")
          },
          ...departments
        ];
      } else {
        // Non-admins can only see their own departments.
        const mainDepartment = {
          AvdelingId: usrProfile.Bruker.Avdeling,
          Navn: usrProfile.Bruker.AvdelingNavn
        };
        return [mainDepartment, ...usrProfile.Bruker.Avdelinger];
      }
    }
  },

  data: () => ({
    selected: "",
    dialogConfirm: false,
    dialogWidth: config.dialogWith,
    rollbackDepartment: null
  }),

  created() {
    store.dispatch("updateUserProfile");
    store.dispatch("updateDepartments");
    this.setSelectedDepartment();
  },

  watch: {
    initDepartment(newVal) {
      // Need a watcher here to in case initDepartment is set after the

      this.setSelectedDepartment();
    },

    departments() {
      // Vue sometimes sets the this.selected to null when the departments state
      // is updated. Not sure why. Resetting it after departments change solved
      // the issue.
      this.setSelectedDepartment();
    }
  },

  methods: {
    userConfirm() {
      if (this.askForUserConfirm) {
        this.dialogConfirm = true;
      } else {
        this.emitChange();
      }
    },

    cancelDepartmentChange() {
      this.selected = this.rollbackDepartment;
      this.dialogConfirm = false;
    },

    setSelectedDepartment(departmentId) {
      if (this.initDepartment) {
        this.selected = this.initDepartment;
      }
    },

    emitChange() {
      this.rollbackDepartment = this.selected;
      this.dialogConfirm = false;
      this.$emit("change", this.selected);
    }
  }
};
</script>
