<template>
  <v-autocomplete
    v-if="groups"
    v-model="selected"
    :items="groups"
    return-object
    item-text="Navn"
    :loading="!groups"
    outlined
    :label="$t('selectGroup')"
    @change="emitChange"
    :multiple="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    data-testid="textGroupSelect"
  />
</template>

<script>
import apiGroup from "@/api/v24/api.group";

export default {
  name: "GroupSelect",

  props: {
    initValue: {
      default: null
    },
    multiple: {
      default: false
    },
    excludeExternalImportedGroups: {
      default: false
    },
    defaultSelectedGroupAfterImport: {
      default: null
    },
    forceShowAllGroups: {
      default: false
    }
  },

  data: () => ({
    selected: null,
    groups: null
  }),

  created() {
    if (this.initValue) {
      this.selected = this.initValue;
    }
    apiGroup.getAll(false, true).then(groups => {
      this.setGroups(groups);
      if (this.defaultSelectedGroupAfterImport && !this.multiple) {
        const foundGroup = this.groups.find(
          group => group.Id === this.defaultSelectedGroupAfterImport
        );
        if (foundGroup) {
          this.selected = foundGroup;
        }
      }
    });
  },

  methods: {
    setGroups(groups) {
      if (!groups) {
        this.groups = null;
      }
      else if (this.excludeExternalImportedGroups) {
        // Some workaround code to get past some strange API stuff...
        // Sintef (and others) use some custom API to update their contacts and
        // groups, where they delete **all** data before importing fresh data.
        // These groups and contacts are marked with Import=true.
        // This will not play along with our Scenario feature, since we use
        // the group(s) ID when triggering a scenario. This id will be deleted
        // for every sync with Sintef. Therefor it's possioble to hide these
        // groups from the select list.
        this.groups = groups.filter(group => {
          return group.Import === false && group.AdSync === false;
        });
      } else {
        this.groups = groups;
      }
    },
    emitChange() {
      this.$emit("change", this.selected);
    }
  }
};
</script>
