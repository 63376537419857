<template>
  <v-card :loading="loading">
    <v-card-title>{{ $t("groups") }}</v-card-title>
    <v-card-text>
      <div v-if="!loading && selectedGroups.length === 0" class="mb-4">
        <i>
          {{ $t("noGroupsUsr") }}
        </i>
      </div>
      <v-autocomplete
        v-if="groups"
        v-model="selectedGroups"
        @change="emitChange"
        :label="$t('addRemoveGroups')"
        :items="groups"
        item-value="Id"
        item-text="Navn"
        outlined
        multiple
        chips
        deletable-chips
        return-object
        data-testid="contactGroupsManage_Add"
      >
      </v-autocomplete>

      <v-btn
        @click="createNewGroup"
        small
        data-testid="contactGroupsManagmentBtn"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t("createNewGroup") }}
      </v-btn>
    </v-card-text>

    <v-dialog
      v-model="dialogNewGroup"
      v-if="dialogNewGroup"
      max-width="540"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <NewGroupForm
        v-on:cancel="newGroupDialogCanceled"
        v-on:done="newGroupDialogClosed"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import NewGroupForm from "../groups/NewGroupForm";
import store from "@/store";

export default {
  name: "ContactGroupsManagement",
  components: { NewGroupForm },

  props: {
    initSelectedGroups: {
      type: Array,
      required: true
    },
    groupsEditable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    groups() {
      if (!store.state.groups) {
        return null;
      }
      return store.state.groups.filter(group => {
        // Don't mess with groups managed externally
        return group.Import === false && group.AdSync === false;
      });
    }
  },

  data: () => ({
    loading: true,
    selectedGroups: [],
    dialogNewGroup: false
  }),

  created() {
    this.selectedGroups = this.initSelectedGroups;
    this.updateGroups();
  },

  methods: {
    newGroupDialogCanceled() {
      this.dialogNewGroup = false;
    },
    newGroupDialogClosed(newGroup) {
      this.dialogNewGroup = false;
      // Set the newly created group as selected
      this.updateGroups().then(() => {
        // Set the newly created group as selected
        this.selectedGroups.push(newGroup);
        this.emitChange();
      });
    },
    updateGroups() {
      return new Promise(resolve => {
        store.dispatch("updateGroups").then(() => {
          this.loading = false;
          resolve();
        });
      });
    },
    createNewGroup() {
      this.dialogNewGroup = true;
    },
    emitChange() {
      this.$emit("change", this.selectedGroups);
    }
  }
};
</script>
