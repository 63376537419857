<template>
  <v-form
    ref="form"
    @submit.prevent="save"
    autocomplete="off"
    v-model="formValid"
  >
    <v-card>
      <v-card-title>
        {{ $t("createNewGroup") }}
      </v-card-title>
      <v-card-text>
        <DepartmentSelect
          :init-department="departmentId"
          v-if="departmentId"
          :dense="true"
          v-on:change="setDepartmentId"
        />
        <v-text-field
          data-testid="grpName"
          v-model.trim="name"
          :label="$t('name')"
          maxlength="80"
          :rules="requiredFieldRule"
          autofocus
          outlined
        />
        <v-text-field
          v-if="showCodewordInput"
          :label="$t('grpShortnameForSmsReg') + $t('optionalHint')"
          v-model.trim="grpShortname"
          outlined
          dense
          :rules="grpShortnameRules"
        />
        <SmsCodewordInstructions :group-shortname="grpShortname" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel" data-testid="newGroupFormCancel">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          data-testid="submitGrp"
          type="submit"
          class="primary"
          :disabled="!formValid"
        >
          {{ $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogForbidden" :max-width="dialogWidth.medium">
      <UserError v-on:ok="dialogForbidden = false">
        <template v-slot:text>
          {{ $t("grpShortnameExits") }}
        </template>
      </UserError>
    </v-dialog>
  </v-form>
</template>

<script>
import apiGroup from "../../api/v24/api.group";
import formValidationRules from "../../util/formValidationRules";
import DepartmentSelect from "@/components/departments/DepartmentSelect.vue";
import user from "@/util/user";
import SmsCodewordInstructions from "@/components/groups/SmsCodewordInstructions.vue";
import UserError from "@/components/misc/UserError.vue";
import config from "@/config/config";
import store from "@/store";

export default {
  name: "NewGroupForm",
  components: { UserError, SmsCodewordInstructions, DepartmentSelect },

  computed: {
    showCodewordInput() {
      return store.getters.getUserHasSmsCodeword;
    }
  },

  data: () => ({
    name: null,
    requiredFieldRule: formValidationRules.requiredRule,
    formValid: true,
    departmentId: null,
    grpShortname: "",
    grpShortnameRules: formValidationRules.groupShortnameRules,
    dialogForbidden: false,
    dialogWidth: config.dialogWith
  }),

  methods: {
    cancel() {
      this.$emit("cancel");
      this.resetNameField();
    },
    save() {
      apiGroup
        .create(this.name, this.departmentId, this.grpShortname)
        .then(newGroup => {
          this.$emit("done", newGroup);
          this.resetNameField();
        })
        .catch(err => {
          if (err._forbidden) {
            this.dialogForbidden = true;
          } else {
            alert(err.message || this.$t("unknownError"));
          }
        });
    },
    resetNameField() {
      this.$refs.form.reset();
    },
    setDepartmentId(departmentId) {
      this.departmentId = departmentId;
    }
  },

  created() {
    user.getDepartmentForItemSave().then(departmentId => {
      this.setDepartmentId(departmentId);
    });
  }
};
</script>
