import stringUtil from "@/util/stringUtil";

export default class AudioRecorder {
  constructor(visualizerCanvas, onNewRecordingHandler) {
    this.mediaRecorder;
    this.chuncks = [];
    // Using only webm since that is the only format Chrome supports
    // (2023-02-09)
    this.format = "webm";
    this.mimeType = `audio/${this.format}`;
    this.stream;

    const constraints = { audio: true };
    const options = { type: this.mimeType };
    navigator.mediaDevices.getUserMedia(constraints, options).then(stream => {
      visualize(stream, visualizerCanvas);
      this.stream = stream;
      this.mediaRecorder = new MediaRecorder(stream, options);
      console.log("Recorder init");

      this.mediaRecorder.ondataavailable = event => {
        console.log("data avail");
        console.log(event);
        this.chuncks.push(event.data);
      };

      this.mediaRecorder.onstop = event => {
        console.log("onstop");
        const blob = new Blob(this.chuncks, { type: this.mimeType });
        this.resetRecording();
        const audioURL = window.URL.createObjectURL(blob);
        const randomStr = stringUtil.createRandomString();
        const fileName = randomStr + "." + this.format;
        const file = new File([blob], fileName, {
          type: this.mimeType
        });
        const data = {
          url: audioURL,
          file: file,
          format: this.format
        };
        onNewRecordingHandler(data);
      };
    });
  }

  start() {
    if (!this.mediaRecorder) {
      throw "No recorder available";
    }
    this.chuncks.length = 0;
    this.mediaRecorder.start();
    console.log(this.mediaRecorder.state);
    console.log("recorder started");
  }

  stop() {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
      console.log("recording stopped");
    }
  }

  // Call this method to stop mic input. This should hide any "now recording"
  // icon in browser
  destroy() {
    if (this.stream) {
      this.stream.getTracks()[0].stop();
    }
  }

  resetRecording() {
    this.chuncks.length = 0;
  }
}

// Copied from https://github.com/mdn/web-dictaphone and tweaket
const visualize = (stream, canvas) => {
  const canvasCtx = canvas.getContext("2d");
  const audioCtx = new AudioContext();

  const source = audioCtx.createMediaStreamSource(stream);

  const analyser = audioCtx.createAnalyser();
  analyser.fftSize = 4096;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);

  draw();

  function draw() {
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    requestAnimationFrame(draw);

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = "rgb(200, 200, 200)";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "rgb(0, 0, 0)";

    canvasCtx.beginPath();

    let sliceWidth = (WIDTH * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      let v = dataArray[i] / 128.0;
      let y = (v * HEIGHT) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }
};
