<template>
  <UserConfirm
    :title="title"
    :text="text"
    :cancel-button="false"
    v-on:ok="ok"
  />
</template>

<script>
import UserConfirm from "./UserConfirm";

export default {
  name: "UserWarning",
  components: { UserConfirm },
  props: ["title", "text"],
  methods: {
    ok() {
      this.$emit("ok");
    }
  }
};
</script>
