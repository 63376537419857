<template>
  <div>
    <v-tabs>
      <v-tab :key="1" v-if="usingSms()">
        {{ $t("sms") }}
      </v-tab>
      <v-tab :key="2" v-if="usingEmail()">
        {{ $t("email") }}
      </v-tab>
      <v-tab :key="3" v-if="usingVoice()">
        {{ $t("voice") }}
      </v-tab>
      <v-tab-item :key="1" v-if="usingSms()">
        <v-card>
          <v-card-text>
            <div class="body-2">{{ $t("message") }}</div>
            <SimpleCard :text="alertParams.Sms.Melding" :preformatted="true" />
            <ResponseChoiceList :api-params="alertParams" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2" v-if="usingEmail()">
        <v-card>
          <v-card-text>
            <div class="body-2">{{ $t("title") }}</div>
            <pre>{{ alertParams.Epost.Tittel }}</pre>
            <br />
            <div class="body-2">{{ $t("message") }}</div>
            <SimpleCard
              :text="alertParams.Epost.Melding"
              :preformatted="true"
            />
            <ResponseChoiceList :api-params="alertParams" />
            <p class="mt-4">
              {{ $t("attachments") }}:
              {{ alertParams.Epost.Attachments.length }}
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="3" v-if="usingVoice()">
        <v-card>
          <v-card-text>
            <div class="body-2">{{ $t("voice2") }}</div>
            <pre>{{ alertParams.Tale.Voice }}</pre>
            <br />
            <div>
              {{ $t("useSoundFile") }}:
              {{ alertParams.Tale.AudioFileId ? $t("yes") : $t("no") }}
            </div>
            <br />
            <div v-if="alertParams.Tale.Content.trim()">
              <div class="body-2">
                {{ $t("message") }}
              </div>
              <SimpleCard
                :text="alertParams.Tale.Content"
                :preformatted="true"
              />
            </div>
            <div v-if="alertParams.Tale.Choices">
              <br />
              <div class="body-2">{{ $t("responseChoicesVoice") }}</div>
              <ul>
                <li
                  v-for="(choice, idx) in alertParams.Tale.Choices"
                  v-if="choice && idx !== 'NoUserInputMsg'"
                >
                  "{{ choice | voiceResponseOptPreviewFilter }}"
                </li>
              </ul>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <div class="mt-4" v-if="showPhoneTypeFilter">
      {{ $t("alertPhoneNumType") }}: <strong>{{ selectedNumType }}</strong>
    </div>
  </div>
</template>

<script>
import SimpleCard from "@/components/misc/SimpleCard";
import ResponseChoiceList from "@/components/alert/ResponseChoiceList";
import voiceResponseOptPreviewFilter from "@/filters/voiceResponseOptPreviewFilter";
import store from "@/store";
import phoneNumType from "@/util/phoneNumType";

export default {
  name: "AlertMessagePreview",
  components: { ResponseChoiceList, SimpleCard },

  props: {
    alertParams: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedNumType() {
      return phoneNumType.getTypeText(this.alertParams.TjenesteFilter);
    },

    showPhoneTypeFilter() {
      if (store.state.userProfile.Bruker?._permissions.phoneNumTypes) {
        return this.usingSms() || this.usingVoice();
      }
      return false;
    }
  },

  data: () => ({
    voiceResponseOptPreviewFilter: voiceResponseOptPreviewFilter
  }),

  methods: {
    usingSms() {
      return this.alertParams.Sms && this.alertParams.Sms.Melding;
    },

    usingVoice() {
      return (
        this.alertParams.Tale &&
        (this.alertParams.Tale.Content || this.alertParams.Tale.AudioFileId)
      );
    },

    usingEmail() {
      return this.alertParams.Epost && this.alertParams.Epost.Melding;
    }
  }
};
</script>
