<template>
  <v-checkbox
    v-model="deleteContacts"
    @change="emitChange"
    :label="$t('deleteGrpContacts')"
    :hint="$t('deleteGrpContactsHint')"
    :disabled="disabled"
    persistent-hint
    data-testid="deleteContactsCheckBoxGroupDetails"
  />
</template>
<script>
export default {
  name: "GroupMembersDeleteOption",

  props: {
    initValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    deleteContacts: false
  }),

  created() {
    this.deleteContacts = this.initValue;
  },

  methods: {
    emitChange() {
      this.$emit("change", this.deleteContacts);
    }
  }
};
</script>
