<template>
  <v-card>
    <v-card-title>
      {{ $t("possibleDupe") }}
    </v-card-title>
    <v-card-text>
      <p class="font-weight-bold">
        {{ $t("contactExistsInfo") }}
      </p>
      <v-data-table :headers="headers" :items="contacts">
        <template v-slot:item.actions="{ item }">
          <v-icon @click="viewContact(item.Id)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="emitCancel">{{ $t("cancel") }}</v-btn>
      <v-btn @click="emitSave" class="primary">
        <span v-if="noDupes">{{ $t("save") }}</span>
        <span v-else>{{ $t("saveAnyway") }}</span>
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogViewPossibleDupe" :max-width="dialogWith.xLarge">
      <ContactDetails
        :contact-id="viewContactId"
        v-on:close="handleContactEdit"
        v-on:delete="handleContactEdit(true)"
        :check-for-duplicates="false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import store from "@/store";
import config from "@/config/config";
import UserConfirm from "@/components/misc/UserConfirm.vue";

export default {
  name: "DuplicateContactsWarning",
  components: {
    // Need to do it this way to avoid ContactDetails becoming
    // both the parent and child of itself.
    // https://stackoverflow.com/a/58875919/529171
    ContactDetails: () => import("@/components/contacts/ContactDetails.vue"),
    UserConfirm
  },

  props: {
    contacts: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    dialogViewPossibleDupe: false,
    dialogWith: config.dialogWith,
    viewContactId: null
  }),

  computed: {
    headers() {
      const headers = [
        { text: this.$t("name"), value: "Navn" },
        { text: this.$t("mobile"), value: "Mobil" },
        { text: this.$t("mobile2"), value: "Mobil2" },
        { text: this.$t("mobile3"), value: "Mobil3" },
        { text: this.$t("email"), value: "Epost" },
        { text: this.$t("jobTitle"), value: "Stilling" }
      ];
      if (store.getters.getShowDepartmentInfoInLists) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          sortable: false
        });
      }
      headers.push({
        text: "",
        value: "actions",
        sortable: false
      });
      return headers;
    },
    noDupes() {
      return this.contacts.length === 0;
    },
  },

  methods: {
    viewContact(id) {
      this.viewContactId = id;
      this.dialogViewPossibleDupe = true;
    },

    handleContactEdit(changedContact) {
      this.dialogViewPossibleDupe = false;
      if (changedContact) {
        this.emitChange();
      }
    },

    emitChange() {
      this.$emit("change");
    },

    emitCancel() {
      this.$emit("cancel");
    },

    emitSave() {
      this.$emit("save");
    }
  }
};
</script>
