<template>
  <div v-if="groupShortname && smsCodeword">
    {{ $t("smsKeywordInstructions1") }}
    <code>{{ smsCodeword }} {{ groupShortNameDisplayName }}</code>
    {{ $t("smsKeywordInstructions2", { number: smsCodewordNumber }) }}
    <br />
    {{ $t("smsKeywordInstructions3") }}
    <code>{{ smsCodeword }} {{ groupShortNameDisplayName }} STOPP</code>
    {{ $t("smsKeywordInstructions4") }}
  </div>
</template>
<script>
import user from "@/util/user";
export default {
  name: "SmsCodewordInstructions",

  props: {
    groupShortname: {
      type: String
    }
  },

  computed: {
    groupShortNameDisplayName() {
      return this.groupShortname.toUpperCase();
    }
  },

  data: () => ({
    smsCodeword: "",
    smsCodewordNumber: ""
  }),

  created() {
    user.getSmsCodeword().then(codewordData => {
      if (!codewordData) return;
      this.smsCodeword = codewordData.Keyword;
      this.smsCodewordNumber = codewordData.Portal;
    });
  }
};
</script>
