const arrayUtil = {
  /**
   * @name findDuplicates
   * @param {Array} items
   * @param {Boolean} ignoreEmptyElements
   * @returns {*}
   */
  findDuplicates(items, ignoreEmptyElements) {
    let itemsCopy = items.slice();
    let results = [];
    let seen = new Set();
    for (let i = 0; i < itemsCopy.length; i++) {
      if (ignoreEmptyElements && !itemsCopy[i]) {
        continue;
      }
      if (seen.has(itemsCopy[i])) {
        results.push(itemsCopy[i]);
      } else {
        seen.add(itemsCopy[i]);
      }
    }
    return results;
  },

  everyItemIsPresent(needlesArr, haystackArr) {
    return needlesArr.every(r => haystackArr.includes(r));
  },

  fieldNameExitsInObjectArray(targetArray, fieldName) {
    return targetArray.some(obj => {
      let keys = Object.keys(obj);
      return keys.some(key => key === fieldName);
    });
  },

  accumulateSum(targetArray, fieldDescName, fieldValueName) {
    let result = Object.values(
      targetArray.reduce((acc, curr) => {
        let item = acc[curr[fieldDescName]];

        if (item) {
          item[fieldValueName] += curr[fieldValueName];
        } else {
          acc[curr[fieldDescName]] = curr;
        }
        return acc;
      }, {})
    );
    return result;
  }
};

export default arrayUtil;
