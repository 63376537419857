import alertResponseOptions from "@/util/alertResponseOptions";
import voiceProperties from "@/util/voiceProperties";
import config from "@/config/config";
import objectUtil from "@/util/objectUtil";

export default class ScenarioParams {
  constructor(scenario) {
    this.scenario = objectUtil.deepClone(scenario);
  }

  /**
   * @name getStartScenarioApiParams
   * There are two fields in API object for response options. One for
   * SMS/email and one for voice. But since this app has one single component
   * that is used for all channels, we save our custom format in the "Custom"
   * field in scenario (where the client can save whatever) and then
   * map it to the API fields before alert. This is done here
   * @returns {Promise<unknown>}
   */
  getStartScenarioApiParams() {
    return new Promise(resolve => {
      const startScenarioParams = Object.assign({}, this.scenario);
      // Using scenario name as alert name, so need to clip the scenario name
      // if it's too long
      let alertName = startScenarioParams.Navn;
      if (alertName.length > 50) {
        alertName = alertName.substring(0, 47) + "...";
      }
      startScenarioParams.Navn = alertName;

      if (!hasResponseOptions(this.scenario)) {
        resolve(startScenarioParams);
      } else {
        if (this.scenario.Sms.Melding) {
          startScenarioParams.Sms.Melding += "\n" + config.responseUrl;
        }
        if (this.scenario.Epost.Melding) {
          startScenarioParams.Epost.Melding += "\n\n" + config.responseUrl;
        }
        const responseObjects = this.scenario.Custom.responses.options;
        startScenarioParams.Svar = alertResponseOptions.getApiFormatSmsEmail(
          responseObjects
        );
        if (isVoiceEnabled(this.scenario)) {
          voiceProperties
            .getPropsFromVoicePersonName(this.scenario.Tale.Voice)
            .then(voiceProps => {
              const voiceProperites = alertResponseOptions.getApiFormatVoice(
                responseObjects,
                voiceProps.LanguageCode
              );
              startScenarioParams.Tale.Choices = voiceProperites.choices;
              startScenarioParams.Tale.Confirm = voiceProperites.confirmText;
              startScenarioParams.Tale.RetryOption.Delay =
                startScenarioParams.Tale.RetryOption.Delay * 60;
              // Add one since API uses "MAX total calls attempts" where the
              // first call is attempt 1
              const maxCalls =
                startScenarioParams.Tale.RetryOption.MaxCalls + 1;
              startScenarioParams.Tale.RetryOption.MaxCalls = resolve(
                startScenarioParams
              );
            });
        } else {
          resolve(startScenarioParams);
        }
      }
    });
  }
}

const isVoiceEnabled = scenario => {
  return scenario.Tale && scenario.Tale.Content;
};

const hasResponseOptions = scenario => {
  const respnoses = scenario.Custom.responses.options;
  return alertResponseOptions.oneOrMoreDefined(respnoses);
};
