<template>
  <v-card>
    <v-card-title>
      {{ $t("contactData") }}
    </v-card-title>
    <v-card-text>
      <p>
        {{ $t("atLeastOneContactData") }}
      </p>
      <v-row>
        <v-col cols="12">
          <PhoneNumberInput
            :country-code="mobile1.countryCode"
            :number="mobile1.number"
            :label="$t('mobile')"
            :number-type="mobile1.numberType"
            v-on:change="setMobile1($event)"
            :hide-phone-type="true"
            data-testid="contactData_mobile1"
          />
        </v-col>
      </v-row>
      <v-row v-show="mobile2Visible">
        <v-col cols="12">
          <PhoneNumberInput
            :country-code="mobile2.countryCode"
            :number="mobile2.number"
            :label="$t('mobile2')"
            :number-type="mobile2.numberType"
            v-on:change="setMobile2($event)"
            :hide-phone-type="true"
            data-testid="contactData_mobile2"
          />
        </v-col>
      </v-row>
      <v-row v-show="mobile3Visible">
        <v-col cols="12">
          <PhoneNumberInput
            :country-code="mobile3.countryCode"
            :number="mobile3.number"
            :number-type="mobile3.numberType"
            :label="$t('mobile3')"
            v-on:change="setMobile3($event)"
            :hide-phone-type="true"
            data-testid="contactData_mobile3"
          />
        </v-col>
      </v-row>
      <v-row v-show="landline1Visible">
        <v-col cols="12">
          <PhoneNumberInput
            :country-code="landline1.countryCode"
            :number="landline1.number"
            :number-type="landline1.numberType"
            :label="$t('landline')"
            v-on:change="setLandline1($event)"
            :hide-phone-type="true"
            icon="mdi-phone"
            data-testid="contactData_landline"
          />
        </v-col>
      </v-row>
      <v-row v-show="landline2Visible">
        <v-col cols="12">
          <PhoneNumberInput
            :country-code="landline2.countryCode"
            :number="landline2.number"
            :number-type="landline2.numberType"
            :label="$t('landline2')"
            v-on:change="setLandline2($event)"
            :hide-phone-type="true"
            icon="mdi-phone"
            data-testid="contactData_landline2"
          />
        </v-col>
      </v-row>

      <v-row v-show="extraPhonesBtnVisible">
        <v-col cols="12" class="mb-6">
          <v-menu offset-y v-if="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                data-testid="contactDataManagementAddPhoneBtn"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("addPhone") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="setPhoneVisible('mobile2')"
                v-if="!mobile2Visible"
              >
                <v-list-item-title>
                  {{ $t("mobile2") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="setPhoneVisible('mobile3')"
                v-if="!mobile3Visible"
              >
                <v-list-item-title>
                  {{ $t("mobile3") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="setPhoneVisible('landline1')"
                v-if="!landline1Visible"
              >
                <v-list-item-title>
                  {{ $t("landline") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="setPhoneVisible('landline2')"
                v-if="!landline2Visible"
              >
                <v-list-item-title>
                  {{ $t("landline2") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :label="$t('email')"
            type="email"
            v-model.trim="email"
            outlined
            append-icon="mdi-email"
            maxlength="200"
            @input="emitChange()"
            :rules="emailRules"
            data-testid="contactData_email"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PhoneNumberInput from "@/components/misc/PhoneNumberInput";
import PhoneNumber from "@/util/PhoneNumber";
import store from "@/store";
import formValidationRules from "@/util/formValidationRules";

export default {
  name: "ContactDataManagement",
  components: { PhoneNumberInput },

  props: {
    contact: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    defaultCountryCode: "",
    mobile1: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      numberType: 1
    },
    mobile2: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      numberType: 1
    },
    mobile3: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      numberType: 1
    },
    landline1: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      numberType: 1
    },
    landline2: {
      countryCode: store.getters.getDefaultCountryPhoneCode,
      number: "",
      numberType: 1
    },
    email: "",
    emailRules: formValidationRules.emailRules,
    visableExtraPhones: []
  }),

  computed: {
    extraPhonesBtnVisible() {
      return this.visableExtraPhones.length < 4;
    },
    mobile2Visible() {
      return this.visableExtraPhones.indexOf("mobile2") !== -1;
    },
    mobile3Visible() {
      return this.visableExtraPhones.indexOf("mobile3") !== -1;
    },
    landline1Visible() {
      return this.visableExtraPhones.indexOf("landline1") !== -1;
    },
    landline2Visible() {
      return this.visableExtraPhones.indexOf("landline2") !== -1;
    }
  },

  created() {
    if (this.contact.Mobil) {
      this.mobile1 = this.createPhoneData(
        this.contact.Mobil,
        this.contact.MobilType
      );
    }
    if (this.contact.Mobil2) {
      this.mobile2 = this.createPhoneData(
        this.contact.Mobil2,
        this.contact.Mobil2Type
      );
      this.visableExtraPhones.push("mobile2");
    }
    if (this.contact.Mobil3) {
      this.mobile3 = this.createPhoneData(
        this.contact.Mobil3,
        this.contact.Mobil3Type
      );
      this.visableExtraPhones.push("mobile3");
    }
    if (this.contact.Fast) {
      this.landline1 = this.createPhoneData(
        this.contact.Fast,
        this.contact.FastType
      );
      this.visableExtraPhones.push("landline1");
    }
    if (this.contact.Fast2) {
      this.landline2 = this.createPhoneData(
        this.contact.Fast2,
        this.contact.Fast2Type
      );
      this.visableExtraPhones.push("landline2");
    }
    this.email = this.contact.Epost;
  },

  methods: {
    setPhoneVisible(phoneName) {
      this.visableExtraPhones.push(phoneName);
    },
    createPhoneData(number, numberType) {
      const phoneNum = new PhoneNumber(number);
      return phoneNum.getAsApiFormat(numberType);
    },
    setMobile1(mobile) {
      this.mobile1 = mobile;
      this.emitChange();
    },
    setMobile2(mobile) {
      this.mobile2 = mobile;
      this.emitChange();
    },
    setMobile3(mobile) {
      this.mobile3 = mobile;
      this.emitChange();
    },
    setLandline1(landline) {
      this.landline1 = landline;
      this.emitChange();
    },
    setLandline2(landline) {
      this.landline2 = landline;
      this.emitChange();
    },
    emitChange() {
      this.$emit("change", {
        mobile1: this.mobile1,
        mobile2: this.mobile2,
        mobile3: this.mobile3,
        landline1: this.landline1,
        landline2: this.landline2,
        email: this.email
      });
    }
  }
};
</script>