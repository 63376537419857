<template>
  <div>
    <v-text-field
      v-model.trim="searchInput"
      :label="$t('search')"
      autocomplete="off"
    />
    <v-data-table
      :headers="headers"
      item-key="Id"
      :items="contacts"
      :items-per-page="contactsPerPage"
      :label="$t('selectContact')"
      mobile-breakpoint="0"
      :page="pageNumber"
      :server-items-length="contactsTotal"
      :footer-props="tblFooterProps"
      v-on:update:options="onOptionsUpdate"
    >
      <template v-slot:item.actions="{ item }">
        <div class="text-end">
          <v-btn
            outlined
            @click="selectContact(item)"
            data-testid="contactSingleSelectBtn"
          >
            {{ $t("select") }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiContact from "@/api/v24/api.contact";
import debounce from "@/util/debounce";
import config from "@/config/config";

export default {
  name: "ContactSingleSelect",

  props: {
    contactsToIgnore: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    headers() {
      let headers = [
        { text: this.$t("name"), value: "Navn" },
        { text: this.$t("jobTitle"), value: "Stilling", hide: "mdAndDown" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ];
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    }
  },

  data: () => ({
    contacts: [],
    searchInput: "",
    selectedContact: null,
    contactsPerPage: config.defaultTblRowsPerPage,
    contactsTotal: 0,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    },
    pageNumber: 1,
    sortBy: "",
    sortDesc: false
  }),

  watch: {
    searchInput: debounce.registerInput(function(newVal) {
      if (newVal === null) return;
      this.searchContacts(newVal);
    }, 500)
  },

  methods: {
    getLabel() {
      if (this.label) {
        return this.label;
      }
      return this.multiple
        ? this.$t("selectContacts")
        : this.$t("selectContact");
    },
    searchContacts(searchStr) {
      if (!searchStr) {
        searchStr = "";
      }
      apiContact
        .search(
          searchStr,
          this.pageNumber,
          this.contactsPerPage,
          this.sortBy,
          this.sortDesc,
          this.contactsToIgnore
        )
        .then(res => {
          this.contacts = res.Kontakter;
          this.contactsTotal = res.AntallTotalt;
        });
    },
    onOptionsUpdate(options) {
      this.pageNumber = options.page;
      this.contactsPerPage = options.itemsPerPage;
      this.sortBy = options.sortBy.length ? options.sortBy[0] : "";
      this.sortDesc = options.sortDesc.length ? options.sortDesc[0] : false;
      this.searchContacts(this.searchInput);
    },
    selectContact(contact) {
      this.$emit("change", contact);
    }
  }
};
</script>
