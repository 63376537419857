<template>
  <v-select
    :items="attemptOptions"
    :label="$t('attemptsNoResponse')"
    :value="value"
    outlined
    @change="onChange($event)"
    data-testid="attemptsVoiceAttemptsSelect" 
  >
  </v-select>
</template>

<script>
export default {
  name: "VoiceAttemptSelect",
  props: ["value"],
  data: () => ({
    attemptOptions: [
      { text: "0", value: 0 },
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 }
    ]
  }),
  methods: {
    onChange(event) {
      this.$emit('input', event);
      this.$emit("change");
    }
  }
};
</script>
