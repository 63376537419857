<template>
  <v-card>
    <v-card-title>
      {{ $t("confirmStartScenario", { name: scenario.Navn }) }}
    </v-card-title>
    <v-card-text>
      <p>
        <AlertMessagePreview v-if="alertParams" :alert-params="alertParams" />
      </p>
      <h3>
        {{ $t("receiverGroups") }}
      </h3>
      <v-col cols="12" sm="12">
        <span v-for="group in scenario.Grupper" class="group-chip">
          <GroupChip
            :group-id="group.Gruppeid"
            :group-name="group.Navn"
            :permission-to-edit="groupIsEditable(group.Gruppeid)"
          />
        </span>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="cancel" data-testid="cancelScenarioConfirm">{{
        $t("cancel")
      }}</v-btn>
      <v-btn @click="ok" class="primary" data-testid="okScenarioConfirm">{{
        $t("ok")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AlertMessagePreview from "@/components/alert/AlertMessagePreview";
import GroupChip from "@/components/groups/GroupChip";
import ScenarioParams from "@/util/ScenarioParams";
import store from "@/store";

export default {
  name: "ScenarioConfirm",
  components: { GroupChip, AlertMessagePreview },

  props: {
    scenario: {
      type: Object,
      required: true
    },
    editableGroups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      alertParams: null
    };
  },

  created() {
    this.setScenarioParams();
    store.dispatch("updateUserProfile");
  },
  watch: {
    scenario() {
      this.setScenarioParams();
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    ok() {
      this.$emit("ok");
    },
    setScenarioParams() {
      const scenarioParams = new ScenarioParams(this.scenario);
      scenarioParams.getStartScenarioApiParams().then(startParams => {
        this.alertParams = startParams;
      });
    },
    groupIsEditable(groupId) {
      if (store.getters.getUserIsAdmin) {
        return true;
      } else if (!this.editableGroups.includes(groupId)) {
        return false;
      } else {
        return store.state.userProfile.Bruker._permissions.manageContacts;
      }
    }
  }
};
</script>
