<template>
  <div class="mb-4">
    <div v-for="res in responses" v-if="showResponsePanel()">
      <ResponseChip :text="res.customValue || res.value" :number="res.num" />
    </div>
  </div>
</template>

<script>
import ResponseChip from "@/components/alert/ResponseChip";

export default {
  name: "ResponsesPreview",
  components: { ResponseChip },

  props: {
    responses: {
      type: Array,
      required: true
    }
  },

  methods: {
    showResponsePanel() {
      let i = 0;
      let len = this.responses.length;
      for (i; i < len; i++) {
        const response = this.responses[i];
        if (response.value || response.customValue) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>
