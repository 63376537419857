import i18n from "@/i18n";

const phoneNumType = {
  getTypeText(typeNum) {
    const types = [i18n.t("all"), i18n.t("work"), i18n.t("private")];
    return types[typeNum];
  }
};

export default phoneNumType;
