<template>
  <div class="caption mb-1 ">
    {{ $t("write") }} <code>{{ tagName }}</code>
    {{ $t("toMergeRecNameIntoText") }}.
    <Tooltip placement="left">
      <template v-slot:content>
        <div>
          {{ $t("dynMergeInfo1") }}
          <br />
          <pre>{{ $t("dynMergeInfo2") }}</pre>
          {{ $t("dynMergeInfo3") }}
          <pre>{{ $t("dynMergeInfo4") }}</pre>
          {{ $t("and") }}
          <pre>{{ $t("dynMergeInfo5") }}</pre>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/misc/Tooltip";
import store from "@/store";

export default {
  name: "DynamicTextInfo",
  components: { Tooltip },
  computed: {
    tagName() {
      return store.state.tagName;
    }
  }
};
</script>
<style scoped>
pre {
  margin: 16px;
}
</style>
