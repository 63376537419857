<template>
  <!-- Disabling download option since format is often or allways webm, and
   Windows does not have built in support to play that file after download -->
  <audio controls controlsList="nodownload" :autoplay="autoplay">
    <source :src="url" />
    {{ $t("noAudioSupport") }}
  </audio>
</template>

<script>
import config from "@/config/config";

export default {
  name: "AudioPlayback",

  props: {
    audioUrl: {
      type: String,
      default: ""
    },
    audioFileId: {
      type: String,
      default: ""
    },
    alertAudioFileId: {
      type: String,
      default: ""
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },

  created() {
    if (this.audioFileId) {
      this.url = config.getAudioPlaybackUrl(this.audioFileId);
    } else if (this.alertAudioFileId) {
      this.url = config.getAudioPlaybackUrl(this.alertAudioFileId, "V");
    } else {
      this.url = this.audioUrl;
    }
  },

  data: () => ({
    url: ""
  })
};
</script>
