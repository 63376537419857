import api from "@/api/v24/api";
import config from "@/config/config";
import eventLogger from "@/util/eventLogger";

const apiFile = {
  getFileMetadata(fileId) {
    const params = { FileId: fileId };
    return api.execute("GetFileMetadata", params, null, false, true);
  },
  /**
   * @name getAll
   * @param fileType "A" for audio. "E" for email
   * @returns {Promise<unknown>}
   */
  getAll(fileType) {
    const params = {
      FileClassFilter: fileType,
      IncludeActive: true,
      IncludeDeleted: false
    };
    return api.execute("GetFiles", params);
  },

  uploadAudio(file, description, replaceId) {
    eventLogger.voiceFileUploaded();
    return this.uploadFile("A", file, description, replaceId);
  },

  uploadEmailFile(file, description, replaceId) {
    eventLogger.emailFileUploaded();
    return this.uploadFile("E", file, description, replaceId);
  },

  getAudioFileUrl(fileId) {
    return config.urlV24AudioApi + fileId;
  },

  uploadFile(uploadType, file, description, replaceId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uploadtype", uploadType);
    formData.append("description", description);
    if (replaceId) {
      formData.append("replacefileid", replaceId);
    }
    return api.execute("UploadFile", null, null, true, false, formData);
  },

  deleteFile(id) {
    return api.execute("DeleteFile", { FileId: id });
  },

  changeDescription(fileId, newDescription) {
    const params = {
      FileId: fileId,
      Description: newDescription
    };
    return api.execute("UpdateFileDescription", params);
  }
};

export default apiFile;
