<template>
  <div>
    <v-file-input
      v-model="file"
      @change="change"
      v-on:focus="file = null"
      :label="$t('chooseFile')"
      :accept="accept"
      outlined
      class="fileInput"
    ></v-file-input>

    <v-alert v-if="errorMessage" type="error">{{ this.errorMessage }}</v-alert>
  </div>
</template>

<script>
import config from "@/config/config";
import numberUtil from "@/util/numberUtil";

export default {
  name: "FileUpload",

  props: {
    accept: {
      type: String
    }
  },

  data: () => ({
    file: null,
    errorMessage: ""
  }),

  methods: {
    change() {
      this.errorMessage = "";
      if (!this.file) {
        return;
      }
      console.log(this.file);
      const fileSizeMb = numberUtil.bytesToMegabytes(this.file.size);
      if (fileSizeMb <= config.emailAttachmentMbimit) {
        this.$emit("change", this.file);
      } else {
        this.errorMessage = this.$t("fileSizeError", {
          count: config.emailAttachmentMbimit
        });
      }
    }
  }
};
</script>

<style scoped>
.fileInput {
  max-width: 500px;
}
</style>
