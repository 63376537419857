<template>
  <v-card>
    <v-card-title>
      {{ $t("edit") }}
    </v-card-title>
    <v-card-text>
      <p>
        {{ $t("editDescOrFile") }}
      </p>
      <v-text-field
        autofocus
        v-model="description"
        outlined
        :label="$t('description')"
      />
      <FileUpload v-on:change="setFile" :accept="acceptedFiles" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="emitDone">{{ $t("cancel") }}</v-btn>
      <v-btn @click="save" class="primary" :disabled="!formValid">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileUpload from "@/components/import/FileUpload";
import apiFile from "@/api/v24/api.file";
import config from "@/config/config";
import eventLogger from "@/util/eventLogger";

export default {
  name: "EditAlertFile",
  components: { FileUpload },
  props: {
    fileToEdit: {
      // As returned from API
      type: Object
    },
    isAudioFile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formValid() {
      return this.description;
    },
    acceptedFiles() {
      const okAudio = config.supportedAudioFileTypes;
      const okEmail = config.supportedEmailFileTypes;
      return this.isAudioFile ? okAudio : okEmail;
    }
  },

  data: () => ({
    file: null,
    fileName: "",
    description: ""
  }),

  created() {
    this.fileName = this.fileToEdit.FileName;
    this.description = this.fileToEdit.Description;
  },

  methods: {
    setFile(file) {
      this.file = file;
    },
    save() {
      const promises = [];
      const fileType = this.isAudioFile ? "A" : "E";
      if (this.file) {
        const promiseFile = apiFile.uploadFile(
          fileType,
          this.file,
          this.description,
          this.fileToEdit.FileId
        );
        promises.push(promiseFile);
      } else {
        const promiseDesc = apiFile.changeDescription(
          this.fileToEdit.FileId,
          this.description
        );
        promises.push(promiseDesc);
      }
      Promise.all(promises).then(() => {
        if (fileType === "A") {
          eventLogger.voiceFileEdited(this.fileToEdit.FileId);
        } else {
          eventLogger.emailFileEdited(this.fileToEdit.FileId);
        }
        this.emitDone();
      });
    },
    emitDone() {
      this.$emit("done");
    }
  }
};
</script>
