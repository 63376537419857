<template>
  <div>
    <v-btn
      @click="dialog = true"
      v-if="items.length && !limitExceeded"
      color="error"
      :outlined="this.deletionType.outlinedBtn"
      v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
    >
      {{ $tc(this.deletionType.i18n.deleteBtn, items.length) }}
    </v-btn>
    <v-alert type="error" outlined v-if="limitExceeded" class="mr-4">
      {{ $tc("massDeleteLimitMsg", deletionType.limit) }}
    </v-alert>

    <v-dialog v-model="dialog" :max-width="dialogWidth.medium">
      <v-card>
        <v-card-title>
          {{ $tc("confirmDeletion", deleteTypeNumber) }}
        </v-card-title>
        <v-card-text>
          <v-alert v-if="itemsToDelete.length" type="error" icon="mdi-alert">
            {{
              $t(deletionType.i18n.confirm, {
                itemType: $tc(
                  this.deletionType.i18n.item,
                  itemsToDelete.length
                ),
                count: itemsToDelete.length
              })
            }}
          </v-alert>
          <v-alert
            v-if="readOnlyItems.length"
            type="info"
            outlined
            class="mt-4"
          >
            {{
              $t("readOnlyItemsPresent", {
                count: readOnlyItems.length,
                itemsPlur: $tc("itemsPlur", readOnlyItems.length),
                singPlur: $tc("singPlur", readOnlyItems.length)
              })
            }}
          </v-alert>
          <GroupMembersDeleteOption
            v-if="this.deletionType.deleteMembersOption"
            v-on:change="setDeleteGroupMembers"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancel">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            @click="deleteSelection"
            class="error"
            :disabled="!itemsToDelete.length"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogError" :max-width="dialogWidth.medium">
      <v-card>
        <v-card-title>
          {{ $t("error") }}
        </v-card-title>
        <v-card-text>
          <v-alert type="error">
            {{ dialogErrorText }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogError = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiContact from "@/api/v24/api.contact";
import config from "@/config/config";
import store from "@/store";
import apiGroup from "@/api/v24/api.group";
import GroupMembersDeleteOption from "@/components/groups/GroupMembersDeleteOption.vue";

export default {
  name: "MassDeletion",
  components: { GroupMembersDeleteOption },

  props: {
    items: {
      type: Array,
      required: true
    },

    typeOfDeletion: {
      type: String,
      required: true
    },

    // Only used for members deletion
    groupId: {
      type: Number,
      default: null
    }
  },

  watch: {
    items: {
      handler() {
        this.readOnlyItems = [];
        this.itemsToDelete = [];
        this.items.forEach(item => {
          if (item.Import === true) {
            this.readOnlyItems.push(item);
          } else {
            this.itemsToDelete.push(item);
          }
        });
      }
    }
  },

  data: () => ({
    readOnlyItems: [],
    itemsToDelete: [],
    dialog: false,
    dialogError: false,
    dialogErrorText: "",
    deletionType: {},
    dialogWidth: config.dialogWith,
    deleteGroupMembers: false
  }),

  computed: {
    readOnlyItemsPresent() {
      return this.readOnlyItems.length > 0;
    },
    limitExceeded() {
      return this.itemsToDelete.length > this.deletionType.limit;
    },
    deleteTypeNumber() {
      return this.typeOfDeletion === "members" ? 2 : 1;
    }
  },

  created() {
    if (this.typeOfDeletion === "contacts") {
      this.deletionType = getDeletionType("contacts");
    } else if (this.typeOfDeletion === "groups") {
      this.deletionType = getDeletionType("groups");
    } else if (this.typeOfDeletion === "members") {
      if (!this.groupId) {
        throw "groupId is required for members deletion";
      }
      this.deletionType = getDeletionType("members");
    } else {
      throw "Invalid deletion type";
    }
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    setDeleteGroupMembers(state) {
      this.deleteGroupMembers = state;
    },

    deleteSelection() {
      this.dialog = false;
      let promise;
      const ids = this.itemsToDelete.map(item => item.Id);
      if (this.deletionType.name === "contacts") {
        promise = apiContact.deleteMultiple(ids, true);
      } else if (this.deletionType.name === "groups") {
        promise = apiGroup.deleteAll(ids, this.deleteGroupMembers);
      } else if (this.deletionType.name === "members") {
        const membersToDelete = this.itemsToDelete.map(item => {
          return {
            GruppeId: this.groupId,
            KontaktId: item.Id
          };
        });
        promise = apiGroup.deleteMembers(membersToDelete);
      } else {
        throw "Invalid deletion type";
      }
      promise
        .then(() => {
          this.emitDone();
        })
        .catch(err => {
          if (err._forbidden) {
            this.dialogErrorText = this.$t("cannotDeleteScenarioGroups");
          } else if (err._notFound) {
            this.dialogErrorText = this.$t(this.deletionType.i18n.error404);
          } else {
            this.dialogErrorText = this.$t("errorOccurred");
          }
          this.dialogError = true;
          this.emitDone();
        });
    },

    emitDone() {
      this.dialog = false;
      this.$emit("done");
    }
  }
};

const getDeletionType = typeName => {
  const deletionTypes = {
    contacts: {
      name: "contacts",
      deleteMembersOption: false,
      limit: config.massDeleteLimit.contacts,
      outlinedBtn: false,
      i18n: {
        item: "contactsPlur",
        deleteBtn: "deleteXcontacts",
        confirm: "confirmXdeletionText",
        error404: "contact404"
      },
      storeUpdateMethod: "updateContacts"
    },
    groups: {
      name: "groups",
      deleteMembersOption: true,
      limit: config.massDeleteLimit.groups,
      outlinedBtn: false,
      i18n: {
        item: "groupsPlur",
        deleteBtn: "deleteXgroups",
        confirm: "confirmXdeletionText",
        error404: "group404"
      },
      storeUpdateMethod: "updateGroups"
    },
    members: {
      name: "members",
      deleteMembersOption: false,
      limit: config.massDeleteLimit.members,
      outlinedBtn: true,
      i18n: {
        item: "contactsPlur",
        deleteBtn: "deleteXmembers",
        confirm: "confirmXremoveText",
        error404: "contact404"
      },
      storeUpdateMethod: "updateGroups"
    }
  };
  return deletionTypes[typeName];
};
</script>

<style scoped>
.xsBlockFix {
  width: 100%;
  margin-right: 0 !important;
}
</style>
