<template>
  <div>
    <v-text-field
      v-model.trim="search"
      :label="$t('search')"
      autocomplete="off"
      data-testid="searchContactMultiSelect"
    />
    <v-data-table
      v-model="selectedContacts"
      :headers="headers"
      item-key="Id"
      show-select
      :items="contacts"
      :label="$t('selectContacts')"
      :items-per-page="contactsPerPage"
      mobile-breakpoint="0"
      :page="pageNumber"
      :server-items-length="contactsTotal"
      :footer-props="tblFooterProps"
      v-on:update:options="onOptionsUpdate"
      data-testid="tableContectMultiSelect"
    >
      <template v-slot:item.data-table-select="{ item, isSelected }">
        <div v-if="item.Import && !item.AdSync">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
            </template>
            <span>{{ $t("impContactSelectLockedInfo") }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <div v-if="item.KontaktType === 1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
              </template>
              <span>{{ $t("smsCodewordContactsLockedInfo") }}</span>
            </v-tooltip>
          </div>
          <v-checkbox
            v-else
            v-model="isSelected"
            :input-value="isSelected"
            @change="rowSelected(item, isSelected)"
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import config from "@/config/config";
import apiContact from "@/api/v24/api.contact";
import debounce from "@/util/debounce";
import contactUtil from "@/util/contactUtil";

export default {
  name: "ContactMultiSelect",

  props: {
    groupIdToIgnore: {
      type: Number,
      default: null
    }
  },

  watch: {
    search: debounce.registerInput(function(newVal) {
      this.pageNumber = 1;
      this.searchContacts(newVal);
    }, 500),
    selectedContacts: {
      handler() {
        this.onSelect();
      }
    }
  },

  computed: {
    headers() {
      let headers = [
        { text: this.$t("name"), value: "Navn" },
        { text: this.$t("jobTitle"), value: "Stilling", hide: "mdAndDown" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ];
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    }
  },

  data: () => ({
    search: "",
    selectedContacts: [],
    contacts: [],
    contactsTotal: 0,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    },
    contactsPerPage: config.defaultTblRowsPerPage,
    pageNumber: 1,
    sortBy: "",
    sortDesc: false
  }),

  created() {
    // onOptionsUpdate gets trigged on created, so no need to fetch then twice
    // this.fetchContacts();
  },

  methods: {
    fetchContacts() {
      this.searchContacts(this.search);
    },

    isReadOnlyContact(contact) {
      return contactUtil.isReadOnly(contact);
    },

    searchContacts(searchStr) {
      apiContact
        .search(
          searchStr,
          this.pageNumber,
          this.contactsPerPage,
          this.sortBy,
          this.sortDesc,
          [],
          this.groupIdToIgnore
        )
        .then(res => this.setContacts(res));
    },

    setContacts(fetchResult) {
      this.contacts = fetchResult.Kontakter;
      this.contactsTotal = fetchResult.AntallTotalt;
    },

    onOptionsUpdate(options) {
      this.pageNumber = options.page;
      this.contactsPerPage = options.itemsPerPage;
      this.sortBy = options.sortBy.length ? options.sortBy[0] : "";
      this.sortDesc = options.sortDesc.length ? options.sortDesc[0] : false;
      this.fetchContacts();
    },

    onSelect() {
      this.$emit("select", this.selectedContacts);
    },

    rowSelected(contact, isSelected) {
      if (isSelected) {
        this.selectedContacts.push(contact);
      } else {
        this.selectedContacts = this.selectedContacts.filter(
          c => c.Id !== contact.Id
        );
      }
    }
  }
};
</script>
