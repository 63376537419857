<template>
  <div v-if="text">
    <div>
      <strong>{{ $t("voicePronunciation") }}</strong>
      <i>
        "{{ $t("pressPhone") }} <span>{{ number }}</span> {{ $t("for") }}:
        <span>{{ text }}"</span>
      </i>
    </div>
    <div>
      <strong>{{ $t("sms") }}/{{ $t("email") }}:</strong>
      <i> {{ $t("linksToWebBtn") }}: </i>
      <v-btn small :href="link" target="_blank">
        {{ text }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsePreview",

  props: {
    number: {
      required: true
    },
    text: {
      required: true,
      type: String
    },
    link: {
      default: ""
    }
  },
};
</script>
