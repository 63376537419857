<template>
  <v-chip v-if="text" label id="chip" v-bind:class="{ fullwidth: fullWidth }">
    <v-avatar left v-if="avatar">
      <v-icon large>mdi-numeric-{{ number }}-box</v-icon>
    </v-avatar>
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: "ResponseChip",

  props: {
    text: {
      required: true
    },
    number: {
      required: true
    },
    avatar: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getResponseValue(response) {
      return response.customValue || response.value;
    }
  }
};
</script>

<style scoped>
#chip {
  margin: 2px;
}
.fullwidth {
  width: 100%;
}
</style>
