<template>
  <div>
    <p v-if="group.KodeNavn">
      {{ $t("grpShortnameForSmsReg") }}:
      <strong>{{ group.KodeNavn.toUpperCase() }}</strong>
    </p>
    <p>
      <SmsCodewordInstructions :group-shortname="group.KodeNavn" />
    </p>
  </div>
</template>
<script>
import SmsCodewordInstructions from "@/components/groups/SmsCodewordInstructions.vue";

export default {
  name: "GroupShortname",
  components: { SmsCodewordInstructions },

  props: {
    group: {
      type: Object,
      required: true
    }
  }
};
</script>
