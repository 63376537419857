<template>
  <v-tooltip
    id="tooltip"
    :bottom="bottomPlacement"
    :right="rightPlacement"
    :left="leftPlacement"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-information-outline
      </v-icon>
    </template>
    <span v-if="text">{{ text }}</span>
    <slot name="content"></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",

  props: {
    // Use slot "content" instead for rich text/content
    text: {
      type: String
    },
    placement: {
      type: String,
      default: "bottom"
    }
  },

  computed: {
    bottomPlacement() {
      return this.placement === "bottom";
    },
    rightPlacement() {
      return this.placement === "right";
    },
    leftPlacement() {
      return this.placement === "left";
    }
  }
};
</script>
