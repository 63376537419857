const sms = {

  /**
   * @name getNumOfSumFromText
   * @param text
   * @returns {Object}
   * @desc
   * From docs/ITC_SMS_Gateway_API_2.2.4.pdf:
   * 3.4 Message Content
   * You can specify the content of the SMS message using the “content”
   * parameter. For text messages, the max length of a single message is
   * 160 characters. If you provide text that is longer than 160
   * characters the SMSGW will automatically split the message into
   * concatenated text messages. When SMS messages are concatenated, some of
   * the SMS payload is needed for the UDH, resulting in the max message length
   * for each concatenated message being 153 characters. The Intelecom platform
   * allows a maximum of six concatenated messages, meaning that the maximum
   * character length of the content field is 918 characters (153*6). The GSM
   * specification allows for a much higher number of concatenated messages but
   * because of restrictions in the MNO SMSCs, the max limit of six
   * concatenated messages had to be included.
   */
  charCount(text) {
    const maxSingleSmsLength = 160;
    const internalLength = 153;
    let smsLength = text.length;
    let numOfSms = 0;

    smsLength += extendedCharactersCount(text);

    if (smsLength <= 0) {
      numOfSms = 0;
    } else if (smsLength <= maxSingleSmsLength) {
      numOfSms = 1;
    } else {
      numOfSms = smsLength / internalLength;
    }
    return {
      count: smsLength,
      // Round up. 4.1 SMS is in reality 5 SMS.
      numOfSms: Math.ceil(numOfSms)
    }
  },

  /**
   * @name findInnvalidChars
   * @param text
   * @returns {String} Returns first invalid char. Returns empty string if
   * text is valid
   */
  findInvalidSymbols(text) {
    // Why these chars?
    // This is the GSM Character Set without the greek letters. When I testet
    // all letters the greek ones became question marks. And € became a
    // whitespace. Not sure it our backend or Puzzel API that has trouble with
    // these letters
    const okChars =
      "\n\r" + // accept line break!
      " @£$¥èéùìòÇØøÅå_^{}[~]|ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\\";

    for (const symbol of text) {
      const index = okChars.indexOf(symbol);
      if (index === -1) {
        return symbol;
      }
    }
    return "";
  }
};

const extendedCharactersCount = text => {
  let count = 0;
  // The GSM charset uses 7-bit alphabet encoding, but the escape characters
  // require 14 bits to encode, thus taking up two characters.
  const extendedCharacters = "€{}[]^|~\\";
  const chars = text.split("");
  let i = 0;
  const len = chars.length
  for (i; i < len; i++) {
    const index = extendedCharacters.indexOf(chars[i]);
    if (index >= 0) count++;
  }
  return count;
};

export default sms;
