<template>
  <v-card :loading="!contact || !defaultCountryPhoneCode || saving || loading">
    <v-card-title class="display-1" v-if="contact">
      <span v-if="newContact">
        {{ $t("createNewContact") }}
      </span>
      <span v-else>
        {{ contact.Navn }}
      </span>
    </v-card-title>
    <v-card-text v-if="contact && defaultCountryPhoneCode" v-show="!saving">
      <v-form v-model="formValid" autocomplete="off">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                :label="$t('name')"
                v-model.trim="contact.Navn"
                outlined
                maxlength="50"
                :rules="requiredFieldRule"
                autofocus
                data-testid="contactDetailsName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                :label="createOptionalLabelText($t('jobTitle'))"
                v-model.trim="contact.Stilling"
                outlined
                maxlength="50"
                data-testid="contactDetailsTitle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6 mb-n2">
            <v-col cols="6">
              <v-text-field
                v-model="contact.Kommentar"
                :label="createOptionalLabelText($t('otherInfo'))"
                outlined
                maxlength="512"
                data-testid="contactDetailsOther"
              />
            </v-col>
            <v-col cols="6">
              <DepartmentSelect
                :init-department="contact.Avdeling"
                :optional-hint="true"
                v-on:change="setDepartment"
                data-testid="contactDetailsDepSelect"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" lg="6">
              <ContactDataManagement
                :contact="contact"
                v-on:change="setContactData"
                data-testid="contactDetailsContactData"
              />
            </v-col>
            <v-col cols="12" sm="12" lg="6">
              <ContactGroupsManagement
                v-if="groups && !groupId"
                :init-selected-groups="groups"
                v-on:change="setGroups"
              />

              <v-card class="mt-4">
                <v-card-title>
                  {{ createOptionalLabelText($t("deputies")) }}
                </v-card-title>
                <v-card-text>
                  <p>
                    {{ $t("deputyInfo", { targetContactName: contact.Navn }) }}
                  </p>
                  <v-card>
                    <v-card-title class="subtitle-1">
                      {{ $t("deputy") }} 1
                    </v-card-title>
                    <v-card-text>
                      <DeputySelect
                        :target-contact="contact"
                        :init-selected-deputy-id="contact.Vikar1"
                        v-on:select="setDeputy1"
                        data-testid="contactDetailsDep1"
                      />
                    </v-card-text>
                  </v-card>
                  <br />
                  <v-card>
                    <v-card-title class="subtitle-1">
                      {{ $t("deputy") }} 2
                    </v-card-title>
                    <v-card-text>
                      <DeputySelect
                        :target-contact="contact"
                        :init-selected-deputy-id="contact.Vikar2"
                        v-on:select="setDeputy2"
                      />
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions v-show="!saving">
      <v-btn
        class="error"
        @click="dialogDelete = true"
        v-if="!newContact"
        small
        data-testid="contactDetailsDelete"
      >
        {{ $t("deleteContact") }}
      </v-btn>
      <v-spacer />
      <v-btn @click="cancel" data-testid="cancelContactsDetails">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        type="submit"
        class="primary"
        v-if="contact"
        @click="validate"
        :disabled="!formValid"
        data-testid="contactDetailsOk"
      >
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>

    <v-dialog
      v-model="dialogDelete"
      :max-width="dialogWidth.small"
      v-if="contact"
    >
      <UserConfirm
        v-on:cancel="dialogDelete = false"
        v-on:ok="deleteContact"
        :title="$t('deleteContact') + ' ' + contact.Navn + '?'"
        :text="getDeleteConfirmText()"
      />
    </v-dialog>

    <v-dialog v-model="dialogValMsg" :max-width="dialogWidth.small">
      <UserWarning
        :title="$t('warning')"
        :text="$t('contactDataValidationErr')"
        v-on:ok="dialogValMsg = false"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogDepartmentRestriction"
      :max-width="dialogWidth.small"
    >
      <UserError v-on:ok="dialogDepartmentRestriction = false">
        <template v-slot:text>
          {{ $t("noDepPermissionContact") }}
        </template>
      </UserError>
    </v-dialog>

    <v-dialog v-model="dialogDuplicate" :max-width="dialogWidth.xLarge">
      <DuplicateContactsWarning
        :key="keyDupeWarn"
        :contacts="possibleDuplicates"
        v-on:save="save"
        v-on:cancel="dialogDuplicate = false"
        v-on:change="updatePossibleDupes"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import apiContact from "../../api/v24/api.contact";
import store from "../../store";
import apiGroup from "../../api/v24/api.group";
import formValidationRules from "../../util/formValidationRules";
import Contact from "../../util/Contact";
import ContactGroupsManagement from "./ContactGroupsManagement";
import UserConfirm from "../misc/UserConfirm";
import UserWarning from "../misc/UserWarning";
import DeputySelect from "@/components/contacts/DeputySelect";
import ContactDataManagement from "@/components/contacts/ContactDataManagement";
import DepartmentSelect from "@/components/departments/DepartmentSelect";
import user from "@/util/user";
import UserError from "@/components/misc/UserError.vue";
import contactUtil from "@/util/contactUtil";
import DuplicateContactsWarning from "@/components/contacts/DuplicateContactsWarning.vue";
import config from "@/config/config";

export default {
  name: "ContactDetails",
  components: {
    DuplicateContactsWarning,
    UserError,
    ContactDataManagement,
    DeputySelect,
    UserWarning,
    UserConfirm,
    ContactGroupsManagement,
    DepartmentSelect
  },

  props: {
    contactId: {
      type: Number,
      required: true
    },
    // If not set user will get the possiblity to connect to any groups
    groupId: {
      type: Number,
      required: false
    },

    // User will by default get a warning if he/she tries to create a contact
    // thay may already exist. Set to false to disable this feature
    checkForDuplicates: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    /**
     * Remember to toggle this component with v-if i parent if handler needs to
     * be rerun for same contactId. This watch will only trigger on contact
     * **ID** changes.
     */
    contactId: {
      immediate: true,
      handler() {
        if (this.contactId) {
          this.loading = true;
          this.newContact = false;
          this.getContact().then(() => {
            // Copies without keeping reference
            this.groups = [...this.contact.Grupper];
            this.loading = false;
          });
        } else {
          this.newContact = true;
          this.contact = getEmptyContact();
          this.email = "";
          this.groups = [];
          user.getDepartmentForItemSave().then(department => {
            this.contact.Avdeling = department;
          });
        }
      }
    }
  },

  computed: {
    defaultCountryPhoneCode() {
      return store.getters.getDefaultCountryPhoneCode;
    },
    userIsAdmin() {
      return store.getters.getUserIsAdmin;
    }
  },

  data: () => ({
    contact: null,
    mobile1: null,
    mobile2: null,
    mobile3: null,
    landline1: null,
    landline2: null,
    email: "",
    dialogDeputy: false,
    groups: null,
    saving: false,
    loading: false,
    formValid: true,
    requiredFieldRule: formValidationRules.requiredRule,
    dialogDelete: false,
    dialogValMsg: false,
    dialogDepartmentRestriction: false,
    dialogDuplicate: false,
    possibleDuplicates: [],
    newContact: false,
    dialogWidth: config.dialogWith,
    keyDupeWarn: 0
  }),

  created() {
    store.dispatch("updateUserProfile");
  },

  methods: {
    getContact() {
      this.contact = null;

      return new Promise(resolve => {
        apiContact
          .get(this.contactId)
          .then(contact => {
            this.contact = contact;
            resolve();
          })
          .catch(err => {
            if (err._notFound) {
              store.commit("setAppSnackbar", this.$t("contact404"));
              store.commit("updateContacts");
              this.cancel();
            }
          });
      });
    },
    createOptionalLabelText(label) {
      return label + " (" + this.$t("optional").toLowerCase() + ")";
    },
    setContactData(contactData) {
      this.mobile1 = contactData.mobile1;
      this.mobile2 = contactData.mobile2;
      this.mobile3 = contactData.mobile3;
      this.landline1 = contactData.landline1;
      this.landline2 = contactData.landline2;
      this.email = contactData.email;
    },
    setDepartment(departmentId) {
      this.contact.Avdeling = departmentId;
    },
    setGroups(groups) {
      this.groups = groups;
    },
    setDeputy1(deputy) {
      this.contact.Vikar1 = deputy ? deputy.Id : 0;
    },
    setDeputy2(deputy) {
      this.contact.Vikar2 = deputy ? deputy.Id : 0;
    },
    cancel() {
      this.$emit("close");
    },
    deleteContact() {
      this.departmentValidation().then(ok => {
        if (!ok) {
          return;
        }
        this.saving = true;
        apiContact.delete(this.contact.Id).then(() => {
          store.commit("updateContacts");
          store.commit("setAppSnackbar", this.$t("contactDeleted"));
          this.saving = false;
          this.$emit("delete");
        });
      });
    },
    getDeleteConfirmText() {
      return this.$t("deleteContactWarning");
    },
    departmentValidation() {
      return new Promise(resolve => {
        user.validateDepartment(this.contact.Avdeling).then(depOk => {
          if (depOk) {
            resolve(true);
          } else {
            this.dialogDelete = false;
            this.dialogDepartmentRestriction = true;
            resolve(false);
          }
        });
      });
    },
    updatePossibleDupes() {
      // User has edited one of the contacts id the possible duplicates list,
      // let's rerun the duplicate check
      duplicateCheck(this.contact.Navn).then(possibleDuplicates => {
        this.possibleDuplicates = possibleDuplicates;
        // Vue won't detect array prop change. Using component key to force update
        this.keyDupeWarn++;
      });
    },
    validate() {
      // Extra validation of contact data after Save button press. Could do
      // this by disabling the button, but it can be hard to understand
      if (
        !this.mobile1.number &&
        !this.mobile2.number &&
        !this.mobile3.number &&
        !this.landline1.number &&
        !this.landline2.number &&
        !this.email
      ) {
        this.dialogValMsg = true;
        return;
      }

      this.departmentValidation().then(validationOk => {
        if (!validationOk) {
          return;
        }

        if (!this.newContact || !this.checkForDuplicates) {
          this.save();
          return;
        }

        this.saving = true;
        duplicateCheck(this.contact.Navn).then(possibleDuplicates => {
          if (possibleDuplicates.length) {
            this.possibleDuplicates = possibleDuplicates;
            this.dialogDuplicate = true;
            this.saving = false;
          } else {
            this.save();
          }
        });
      });
    },

    save() {
      this.saving = true;
      let promises = [];

      const contact = new Contact(this.contact.Navn);
      contact.setTitle(this.contact.Stilling);
      contact.setOtherInfo(this.contact.Kommentar);
      if (this.email) {
        contact.setEmail(this.email);
      }
      if (this.contact.Id) {
        contact.setId(this.contact.Id);
      }
      if (this.mobile1.number) {
        contact.setMobile1(
          this.mobile1.countryCode + this.mobile1.number,
          this.mobile1.numberType
        );
      }
      if (this.mobile2.number) {
        contact.setMobile2(
          this.mobile2.countryCode + this.mobile2.number,
          this.mobile2.numberType
        );
      }
      if (this.mobile3.number) {
        contact.setMobile3(
          this.mobile3.countryCode + this.mobile3.number,
          this.mobile3.numberType
        );
      }
      if (this.landline1.number) {
        contact.setLandline1(
          this.landline1.countryCode + this.landline1.number,
          this.landline1.numberType
        );
      }
      if (this.landline2.number) {
        contact.setLandline2(
          this.landline2.countryCode + this.landline2.number,
          this.landline2.numberType
        );
      }
      contact.setDepartment(this.contact.Avdeling);

      contact.setDeputy1(this.contact.Vikar1);
      contact.setDeputy2(this.contact.Vikar2);

      let returnContact;
      let promise;
      if (this.newContact) {
        promise = apiContact.create(contact);
      } else {
        promise = apiContact.change(contact);
      }
      promises.push(promise);
      promise.then(createdContact => {
        returnContact = createdContact;
        if (this.newContact) {
          if (this.groupId) {
            // Add this contact to given group ID
            promises.push(apiGroup.addContact(this.groupId, createdContact.Id));
          } else {
            // Add any new groups to this new contact
            this.groups.forEach(selectedGroup => {
              promises.push(
                apiGroup.addContact(selectedGroup.Id, createdContact.Id)
              );
            });
          }
        } else {
          // Remove any groups that are removed from contact
          this.contact.Grupper.forEach(currentGroup => {
            const hit = this.groups.filter(
              selectedGroup => selectedGroup.Id === currentGroup.Id
            );
            if (!hit.length) {
              promises.push(
                apiGroup.removeContact(currentGroup.Id, this.contact.Id)
              );
            }
          });

          // Add any groups that are added to contact
          this.groups.forEach(selectedGroup => {
            const hit2 = this.contact.Grupper.filter(
              currentGroup => currentGroup.Id === selectedGroup.Id
            );
            if (!hit2.length) {
              promises.push(
                apiGroup.addContact(selectedGroup.Id, this.contact.Id)
              );
            }
          });
        }
      });

      Promise.all(promises)
        .then(() => {
          store.commit("updateContacts");
          this.saving = false;
          const usrMsg = this.$t("contactSaved");
          store.commit("setAppSnackbar", usrMsg);
          this.$emit("close", returnContact);
        })
        .catch(() => {
          this.saving = false;
        });
    }
  }
};

const getEmptyContact = () => {
  return {
    Id: 0,
    Navn: "",
    Stilling: "",
    Kommentar: "",
    Vikar1: 0,
    Vikar2: 0,
    Grupper: [],
    Mobil: "",
    Mobil2: "",
    Fast: "",
    Fast2: "",
    Avdeling: ""
  };
};

const duplicateCheck = newContact => {
  return contactUtil.searchForDuplicatesSingle(newContact);
};
</script>
