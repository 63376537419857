<template>
  <v-container grid-list-md fluid>
    <h2>{{ $t("scenario") }}</h2>
    <p>
      {{ $t("scenarioInfo") }}
    </p>
    <v-row>
      <v-col cols="12">
        <v-btn x-large @click="create" data-testid="createScenario">
          <v-icon>mdi-plus</v-icon>
          {{ $t("createScenario") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="scenario in scenarios"
        :key="scenario.Id"
        cols="12"
        md="4"
        xs="12"
      >
        <ScenarioCard
          :scenario="scenario"
          :editable-groups="usersGroups"
          v-on:save="scenarioSaved"
          v-on:started="scenarioStarted"
          v-on:delete="scenarioDeleted"
          v-on:cancel="scenarioCancel"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="createDialog"
      max-width="1600"
      v-if="createDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      persistent
    >
      <ScenarioDetails
        v-on:save="scenarioSaved"
        v-on:created="scenarioCreated"
        v-on:cancel="createDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ScenarioCard from "@/components/scenario/ScenarioCard";
import apiScenario from "@/api/v24/api.scenario";
import ScenarioDetails from "@/components/scenario/ScenarioDetails";
import appSnackbar from "@/util/appSnackbac";
import eventLogger from "@/util/eventLogger";
import apiGroup from "@/api/v24/api.group";

export default {
  name: "Scenario",
  components: { ScenarioDetails, ScenarioCard },

  data: () => ({
    scenarios: [],
    createDialog: false,
    usersGroups: []
  }),

  created() {
    this.update();
    this.getUsersGroups();
  },

  methods: {
    update() {
      apiScenario.getAll().then(scenarios => (this.scenarios = scenarios));
    },
    create() {
      this.createDialog = true;
    },
    getUsersGroups() {
      apiGroup.getAll().then(groups => {
        this.usersGroups = groups.map(group => group.Id);
      });
    },
    scenarioSaved() {
      this.update();
      appSnackbar.setMessage(this.$t("scenarioSaved"));
    },
    scenarioCreated() {
      this.update();
      this.createDialog = false;
      appSnackbar.setMessage(this.$t("scenarioCreated"));
    },
    scenarioStarted() {
      this.update();
      const snackbarOptions = {
        text: this.$t("scenarioStartOk"),
        navButton: {
          text: this.$t("goToLog"),
          path: "/log"
        }
      };
      appSnackbar.setMessage(snackbarOptions);
      eventLogger.scenarioUsed();
    },
    scenarioDeleted() {
      this.update();
      this.createDialog = false;
      appSnackbar.setMessage(this.$t("scenarioDeleted"));
    },
    scenarioCancel() {
      this.update();
      this.createDialog = false;
    }
  }
};
</script>
