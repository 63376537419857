<template>
  <div>
    <v-chip
      @click="showGroupDetails"
      class="chip-group card-section"
      :class="{ 'elevation-6': permissionToEdit }"
      outlined
      link
      data-testid="groupChip"
    >
      <v-avatar left>
        <v-icon small>
          mdi-account-group
        </v-icon>
      </v-avatar>
      {{ groupName }}
    </v-chip>

    <v-dialog v-model="dialog" max-width="1200" persistent v-if="dialog">
      <GroupDetails
        :group-id="groupId"
        v-on:close="hideGroupDetails"
        :close-btn="true"
        :delete-button="false"
      ></GroupDetails>
    </v-dialog>

    <v-dialog v-model="dialogPermission" max-width="400">
      <UserError v-on:ok="dialogPermission = false">
        <template v-slot:title>
          {{ $t("notAuthorized") }}
        </template>
        <template v-slot:text>
          {{ $t("noGrpPermission") }}
        </template>
      </UserError>
    </v-dialog>
  </div>
</template>

<script>
import GroupDetails from "@/components/groups/GroupDetails";
import UserError from "@/components/misc/UserError.vue";

export default {
  name: "GroupChip",
  components: { GroupDetails, UserError },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    groupName: {
      type: String,
      required: true
    },
    permissionToEdit: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    dialog: false,
    dialogPermission: false
  }),

  methods: {
    showGroupDetails() {
      if (this.permissionToEdit) {
        this.dialog = true;
      } else {
        this.dialogPermission = true;
      }
    },

    hideGroupDetails() {
      this.dialog = false;
    }
  }
};
</script>
