<template>
  <v-card>
    <v-card-title>
      <span v-if="details.Id">{{ details.Navn }}</span>
      <span v-else>{{ $t("createScenario") }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>
                  {{ $t("name") }}
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="details.Navn"
                    outlined
                    autofocus
                    maxlength="100"
                    :rules="requiredFieldRule"
                    data-testid="nameScenarioDetails"
                  />
                  <DepartmentSelect
                    :init-department="details?.Avdeling"
                    :dense="true"
                    v-on:change="setDepartment"
                    data-testid="departmentSelectScenarioDetails"
                  />
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>
                  {{ $t("receiverGroups") }}
                </v-card-title>
                <v-card-text>
                  <GroupSelect
                    v-if="groups"
                    :multiple="true"
                    :init-value="groups"
                    :exclude-external-imported-groups="true"
                    :force-show-all-groups="true"
                    v-on:change="setGroups"
                    data-testid="groupSelectScenarioDetails"
                  />
                  <p v-if="adProvisioningUser" class="mt-n4">
                    {{ $t("adProvGrpCannotUse") }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12">
              <SmsComposer
                :init-enabled="enabledComposers.sms"
                :init-text="details.Sms.Melding"
                :init-sender="details.Sms.Avsender"
                :auto-sms-sender="true"
                v-on:change="setSmsProps"
                data-testid="smsScenarioDetails"
              />
            </v-col>
            <v-col cols="12">
              <EmailComposer
                :init-enabled="enabledComposers.email"
                :init-title="details.Epost.Tittel"
                :init-text="details.Epost.Melding"
                v-on:change="setEmailProps"
                data-testid="emailScenarioDetails"
                :hide-attachment-feature="true"
              />
            </v-col>
            <v-col cols="12">
              <VoiceComposer
                v-if="voiceProps"
                :init-voice-props="voiceProps"
                :init-enabled="enabledComposers.voice"
                :init-text="details.Tale.Content"
                :init-attempts="details.Tale.RetryOption.MaxCalls"
                :init-delay="details.Tale.RetryOption.Delay"
                :show-deputy-options="false"
                v-on:change="setVoiceProps"
                data-testid="voiceScenarioDetails"
              />
            </v-col>
            <v-col cols="12">
              <ResponseComposer
                :init-enabled="details.Custom.responses.enabled"
                :in-responses="details.Custom.responses.options"
                v-on:change="setResponseProps"
                data-testid="responseScenarioDetails"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="details.Simulering"
                :label="$t('simMode')"
                data-testid="simulateScenarioDetails"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="error"
        @click="dialogDelete = true"
        v-if="details.Id"
        small
        data-testid="deleteScenarioDetails"
      >
        {{ $t("deleteScenario") }}
      </v-btn>
      <v-spacer />
      <v-btn @click="cancel" data-testid="cancelScenarioDetails">{{
        $t("cancel")
      }}</v-btn>
      <v-btn
        @click="save"
        class="primary"
        :disabled="!formValid"
        data-testid="saveScenarioDetails"
        >{{ $t("save") }}</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialogValidationErr" max-width="550">
      <UserError v-on:ok="dialogValidationErr = false">
        <template v-slot:title>
          {{ $t("error") }}
        </template>
        <template v-slot:text>
          <ul>
            <li v-for="err in validationErrors">
              {{ err }}
            </li>
          </ul>
        </template>
      </UserError>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="550">
      <UserConfirm
        :title="$t('confirm')"
        :text="$t('deleteScenario?')"
        v-on:cancel="dialogDelete = false"
        v-on:ok="deleteScenario"
      />
    </v-dialog>
    <!-- Just to please IntellJ IDE inspection -->
    <span style="display: none" class="channel-panel"></span>
  </v-card>
</template>

<script>
import GroupSelect from "@/components/groups/GroupSelect";
import SmsComposer from "@/components/composers/SmsComposer";
import apiScenario from "@/api/v24/api.scenario";
import apiGroup from "@/api/v24/api.group";
import EmailComposer from "@/components/composers/EmailComposer";
import config from "@/config/config";
import VoiceComposer from "@/components/composers/VoiceComposer";
import UserError from "@/components/misc/UserError";
import ResponseComposer from "@/components/composers/ResponseComposer";
import UserConfirm from "@/components/misc/UserConfirm";
import voiceProperties from "@/util/voiceProperties";
import configVoice from "@/config/configVoice";
import alertResponseOptions from "@/util/alertResponseOptions";
import store from "@/store";
import DepartmentSelect from "@/components/departments/DepartmentSelect";
import user from "@/util/user";
import formValidationRules from "../../util/formValidationRules";

export default {
  name: "ScenarioDetails",
  components: {
    DepartmentSelect,
    UserConfirm,
    ResponseComposer,
    UserError,
    VoiceComposer,
    EmailComposer,
    SmsComposer,
    GroupSelect
  },

  props: {
    scenarioObject: {
      type: Object,
      required: false
    }
  },

  computed: {
    userIsAdmin() {
      return store.getters.getUserIsAdmin;
    },
    formValid() {
      return !!this.details.Navn && !!this.details.Navn.trim();
    }
  },

  data: () => ({
    scenario: null,
    enabledComposers: {
      sms: false,
      email: false,
      voice: false
    },
    groups: null,
    details: null,
    dialogValidationErr: false,
    dialogDelete: false,
    validationErrors: [],
    voiceProps: null,
    validSmsSender: true,
    adProvisioningUser: false,
    requiredFieldRule: formValidationRules.requiredRule,
    department: ""
  }),

  created() {
    if (this.scenarioObject) {
      this.scenario = this.scenarioObject;
    } else {
      this.scenario = getEmptyScenario();
    }
    this.details = Object.assign({}, this.scenario);

    user.getDepartmentForItemSave().then(id => {
      this.details.Avdeling = id;
    });

    this.enabledComposers.sms = isScenarioChannelEnabled(
      this.details,
      "Sms",
      "Melding"
    );
    this.enabledComposers.email = isScenarioChannelEnabled(
      this.details,
      "Epost",
      "Melding"
    );
    this.enabledComposers.voice = isScenarioChannelEnabled(
      this.details,
      "Tale",
      "Content"
    );

    const voicePersonName = this.scenario.Tale.Voice;
    if (voicePersonName) {
      voiceProperties
        .getPropsFromVoicePersonName(voicePersonName)
        .then(voiceProps => (this.voiceProps = voiceProps));
    } else {
      configVoice
        .getDefaultProps()
        .then(voiceProps => (this.voiceProps = voiceProps));
    }

    apiGroup.getAll(false, true).then(groups => {
      this.groups = parseGroups(groups, this.details.Grupper);
    });

    store.dispatch("getUserPermissions").then(permissions => {
      this.adProvisioningUser = permissions.activeDirProvisioning;
    });
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    setDepartment(departmentId) {
      this.details.Avdeling = departmentId;
    },
    setSmsProps(props) {
      this.enabledComposers.sms = props.enabled;
      this.validSmsSender = props.validSender;
      if (props.enabled) {
        this.details.Sms.Avsender = props.sender;
        this.details.Sms.Melding = props.text;
      } else {
        // API uses message text to check if SMS is "enabled" or not
        this.details.Sms.Melding = "";
      }
    },
    setEmailProps(props) {
      this.enabledComposers.email = props.enabled;
      if (props.enabled) {
        this.details.Epost.Tittel = props.title;
        this.details.Epost.Melding = props.text;
      } else {
        this.details.Epost.Melding = "";
      }
    },
    setVoiceProps(props) {
      this.enabledComposers.voice = props.enabled;
      if (props.enabled) {
        this.voiceProps = props.voiceProps;
        this.details.Tale.Content = props.text;
        this.details.Tale.Voice = props.voiceProps.Name;
        this.details.Tale.RetryOption.MaxCalls = props.attempts;
        this.details.Tale.RetryOption.Delay = props.delay;
        this.details.Tale.RetryOption.EndOnState = props.endOnState;
      } else {
        this.details.Tale.Content = "";
      }
    },
    setResponseProps(resOptions) {
      this.details.Custom.responses.enabled = resOptions.enabled;
      this.details.Custom.responses.options = resOptions.responses;
    },
    setDefaultResponseProps() {
      if (!this.details.Custom) {
        this.details.Custom = {};
      }
      this.details.Custom.responses = getDefaultResponseProps();
    },
    validate() {
      this.validationErrors = [];
      if (!this.details.Navn) {
        this.validationErrors.push(this.$t("missingName"));
      }
      if (!this.groups.length) {
        this.validationErrors.push(this.$t("selectAtLeastOneGroup"));
      }
      const ec = this.enabledComposers;
      if (!ec.sms && !ec.email && !ec.voice) {
        this.validationErrors.push(this.$t("channelMissing"));
      }
      if (ec.sms) {
        if (!this.details.Sms.Melding) {
          this.validationErrors.push(this.$t("smsTxtMissing"));
        }
        if (!this.validSmsSender) {
          this.validationErrors.push(this.$t("invalidSmsSender"));
        }
      }
      if (ec.email) {
        if (!this.details.Epost.Tittel) {
          this.validationErrors.push(this.$t("emailTitleMissing"));
        }
        if (!this.details.Epost.Melding) {
          this.validationErrors.push(this.$t("emailTxtMissing"));
        }
      }
      if (ec.voice) {
        const vs = this.details.Tale;
        if (!vs.Voice) {
          this.validationErrors.push(this.$t("voiceMissing"));
        }
        if (!vs.Content) {
          this.validationErrors.push(this.$t("voiceTxtMissing"));
        }
        if (
          vs.RetryOption.EndOnState === "confirmed" &&
          vs.RetryOption.MaxCalls > 0 &&
          !this.details.Custom.responses.enabled
        ) {
          this.validationErrors.push(this.$t("endOnStateError"));
        }
      }

      return !this.validationErrors.length;
    },
    setEmailSenderProps() {
      if (this.details.Epost) {
        if (!this.details.Epost.Avsender) {
          this.details.Epost.Avsender = config.defaultEmailSenderAddr;
        }
        if (!this.details.Epost.AvsenderNavn) {
          this.details.Epost.AvsenderNavn = config.appName;
        }
      }
    },
    save() {
      if (!this.validate()) {
        this.dialogValidationErr = true;
        return;
      }
      this.setEmailSenderProps();
      let params = this.details;

      store.dispatch("updateUserProfile").then(profile => {
        params.Grupper = createGroupsParamField(this.groups);
        let promise;
        let eventName;
        console.log(this.details);
        if (this.details.Id) {
          promise = apiScenario.change(params);
          eventName = "save";
        } else {
          promise = apiScenario.create(params);
          eventName = "created";
        }

        promise.then(() => {
          this.$emit(eventName);
        });
      });
    },
    deleteScenario() {
      apiScenario.delete(this.details.Id).then(() => {
        this.$emit("delete");
      });
    },
    setGroups(groups) {
      this.groups = groups;
    }
  }
};

const parseGroups = (allGroups, scenarioGroups) => {
  return allGroups.filter(group => {
    return scenarioGroups.find(apiObj => group.Id === apiObj.Gruppeid);
  });
};

const createGroupsParamField = selectedGroups => {
  return selectedGroups.map(group => {
    return {
      Gruppeid: group.Id
    };
  });
};

const isScenarioChannelEnabled = (scenario, channelName, textFieldName) => {
  if (!scenario[channelName]) {
    return false;
  }
  return !!scenario[channelName][textFieldName];
};

const getEmptyScenario = () => {
  return {
    Sms: {
      Avsender: "",
      AdresseInfo: false,
      Melding: ""
    },
    Avdeling: "",
    SmsKode: "",
    Epost: {
      Avsender: "",
      AvsenderNavn: "",
      Tittel: "",
      Melding: ""
    },
    Grupper: [],
    Simulering: false,
    Tale: {
      Pitch: "",
      Choices: {
        NoUserInputMsg: "",
        Key9: "",
        Key8: "",
        Key7: "",
        Key6: "",
        Key5: "",
        Key4: "",
        Key3: "",
        Key2: "",
        Key1: "",
        Key0: ""
      },
      ConferenceOption: {
        Key: 0,
        Enabled: false,
        Recording: false,
        Name: ""
      },
      Language: "",
      Voice: "",
      Introduction: {
        PressKey1ToContinue: false,
        PressKey1ToContinueMsg: "",
        Text: ""
      },
      Rate: "",
      RetryOption: {
        EndOnState: "confirmed",
        MaxCalls: 0,
        Delay: 5
      },
      RepeatOption: {
        Key: 9,
        Enabled: true
      },
      Content: "",
      Confirm: ""
    },
    Svar: {
      A9: "",
      A8: "",
      A7: "",
      A6: "",
      A5: "",
      A4: "",
      A3: "",
      A2: "",
      A1: ""
    },
    Navn: "",
    Custom: {
      responses: getDefaultResponseProps()
    }
  };
};

const getDefaultResponseProps = () => {
  return alertResponseOptions.getDefaultScenarioSettings();
};
</script>
<style scoped>
.channel-panel {
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
}
</style>
