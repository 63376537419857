<template>
  <v-card>
    <v-card-title>
      {{ title || $t('confirm') }}
    </v-card-title>
    <v-card-text>
      <v-alert :type="alertType">
        {{ text }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn data-testid='userConfirmCancel' v-if="cancelButton" @click="cancel">{{ $t("cancel") }}</v-btn>
      <v-btn data-testid='userConfirmOK' @click="ok" class="primary">{{ $t("ok") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UserConfirm",
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    cancelButton: {
      type: Boolean,
      default: true
    },
    alertType: {
      type: String,
      default: "warning"
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    ok() {
      this.$emit("ok");
    }
  }
};
</script>
