import store from "@/store";

const voiceProperties = {
  getPropsFromVoicePersonName(name) {
    return new Promise(resolve => {
      getVoices().then(voices => {
        const voice = voices.find(voice => name === voice.Name);
        resolve(voice);
      });
    });
  }
};

const getVoices = () => {
  return new Promise(resolve => {
    store.dispatch("updateVoices").then(voices => resolve(voices));
  });
};

export default voiceProperties;
