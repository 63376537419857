<template>
  <div v-if="text.length && sender.length">
    <v-btn outlined x-small @click="sendTestMessage">
      {{ $t("testSms") }}
    </v-btn>
    <Tooltip>
      <template v-slot:content>
        <p>
          {{ $t("youWillRecSms", { mobile: usrMobile }) }}
        </p>
        <p>
          {{ $t("youWillRecMsgLimitations") }}
        </p>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/misc/Tooltip";
import store from "@/store";
import appSnackbar from "@/util/appSnackbac";
import apiSms from "@/api/v24/api.sms";

export default {
  name: "TestSmsText",
  components: { Tooltip },

  props: {
    text: {
      required: true,
      type: String
    },
    sender: {
      required: true,
      type: String
    }
  },

  data: () => ({
    usrMobile: ""
  }),

  created() {
    store.dispatch("updateUserProfile").then(usrProfile => {
      this.usrMobile = usrProfile.Bruker.Mobilnr;
    });
  },

  methods: {
    sendTestMessage() {
      apiSms
        .sendSingle(this.sender, this.text, this.usrMobile)
        .then(() => {
          appSnackbar.setMessage(this.$t("testMessageSent"));
        })
        .catch(() => {
          appSnackbar.setMessage(this.$t("testMessageFailed"));
        });
    }
  }
};
</script>
