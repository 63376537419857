<template>
  <v-card>
    <v-card-title>
      {{ $t("recording") }}
    </v-card-title>
    <v-card-text>
      <v-snackbar v-model="snackbar" top absolute>
        {{ $t("recordingTimeout", { limit: timeoutLimit / 1000 / 60 }) }}
      </v-snackbar>
      <div class="text-center">
        <v-btn
          icon
          elevation="4"
          x-large
          class="mr-4"
          outlined
          color="red"
          @click="record"
          v-if="!recordingInProgress"
        >
          <v-icon>mdi-microphone</v-icon>
        </v-btn>
        <v-btn icon x-large elevation="4" @click="stop" v-else>
          <v-icon>mdi-stop</v-icon>
        </v-btn>
        <p class="mt-8" v-if="!recordingInProgress">
          {{ $t("recordNow") }}
        </p>
        <p class="mt-8" v-if="recordingInProgress">
          <v-icon color="red">mdi-microphone</v-icon>
          {{ $t("nowRecording") }}
        </p>
        <br />
        <canvas
          v-show="recordingInProgress"
          ref="visualizer"
          style="width: 450px; height: 150px"
        />
        <div v-if="recordingInProgress">
          {{ (recordingDuration / 1000) | durationFilter }}
          / 0{{ timeoutLimit / 1000 / 60 }}:00
        </div>
        <div class="mt-14">
          <AudioPlayback :audio-url="recordData.url" v-if="recordData" />
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="cancel">{{ $t("cancel") }}</v-btn>
      <v-btn class="primary" @click="openSaveDialog" v-if="recordData">
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogSave" max-width="400" persistent>
      <v-form @submit.prevent="save" autocomplete="off">
        <v-card :loading="saving">
          <v-card-title>
            {{ $t("newSoundFile") }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              :disabled="saving"
              v-model="fileDesc"
              :label="$t('description')"
              outlined
              ref="fileDesc"
              id="fileDesc"
            />
          </v-card-text>
          <v-card-actions v-if="!saving">
            <v-spacer />
            <v-btn @click="dialogSave = false">{{ $t("cancel") }}</v-btn>
            <v-btn type="submit" class="primary">{{ primaryBtnText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogRecordError" max-width="600" persistent>
      <v-card>
        <v-card-title>
          {{ $t("error") }}
        </v-card-title>
        <v-card-text>
          <v-alert type="error">
            {{ $t("recordFail") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
              dialogRecordError = false;
              cancel();
            "
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn @click="reload" class="primary">
            {{ $t("recordTryAgain") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserTextInput from "@/components/misc/UserTextInput.vue";
import AudioRecorder from "@/util/AudioRecorder";
import apiFile from "@/api/v24/api.file";
import stringUtil from "@/util/stringUtil";
import AudioPlayback from "@/components/files/AudioPlayback.vue";
import fileUtil from "@/util/fileUtil";
import UserError from "@/components/misc/UserError.vue";
import timeUtil from "@/util/timeUtil";
import eventLogger from "@/util/eventLogger";

export default {
  name: "AudioRecorder",
  components: { UserError, AudioPlayback, UserTextInput },

  props: {
    saveAndUseBtn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    primaryBtnText() {
      return this.saveAndUseBtn ? this.$t("saveAndUse") : this.$t("save");
    }
  },

  data: () => ({
    dialogSave: false,
    recorder: null,
    recordData: null,
    recordingInProgress: false,
    fileDesc: "",
    saving: false,
    timeoutProcess: null,
    snackbar: false,
    recordingDuration: 0,
    timeoutLimit: 2 * 60 * 1000,
    dialogRecordError: false
  }),

  mounted() {
    const visualizerCanvas = this.$refs.visualizer;
    this.recorder = new AudioRecorder(visualizerCanvas, this.setNewRecording);
  },

  beforeDestroy() {
    clearInterval(this.timeoutProcess);
    this.recorder.destroy();
  },

  methods: {
    record() {
      try {
        this.recorder.start();
      } catch (e) {
        this.dialogRecordError = true;
        return;
      }
      this.recordingInProgress = true;
      this.recordData = null;
      let startTime = Date.now();
      this.timeoutProcess = setInterval(() => {
        this.recordingDuration = Date.now() - startTime;
        console.log(this.recordingDuration);
        if (
          this.recordingInProgress &&
          this.recordingDuration > this.timeoutLimit
        ) {
          this.stop();
          clearTimeout(this.timeoutProcess);
          this.snackbar = true;
        }
      }, 1000);
    },

    stop() {
      clearInterval(this.timeoutProcess);
      this.recordingInProgress = false;
      this.recorder.stop();
    },

    setNewRecording(audioData) {
      this.recordData = audioData;
    },

    reload() {
      window.location.reload();
    },

    cancel() {
      this.recorder.stop();
      this.$emit("cancel");
    },

    openSaveDialog() {
      this.fileDesc = this.createDefaultFileDesc();
      this.dialogSave = true;
      // Trick to select input that that is not in DOM when method is executed
      this.$nextTick(() => {
        setTimeout(() => {
          window.document.getElementById("fileDesc").select();
        });
      });
    },

    createDefaultFileDesc() {
      const now = new Date();
      return this.$t("recording") + " " + timeUtil.createDateTimeString(now);
    },

    save() {
      this.saving = true;
      const file = fileUtil.rename(this.recordData.file, this.createFileName());
      apiFile
        .uploadAudio(file, this.fileDesc)
        .then(res => {
          this.dialogSave = false;
          this.$emit("done", res.FileId);
          eventLogger.voiceFileRecorded(res.FileId);
        })
        .finally(() => (this.saving = false));
    },

    createFileName() {
      const fileDesc = this.fileDesc
        ? this.fileDesc
        : this.createDefaultFileDesc();
      let fileName = stringUtil.makeDownloadFriendlyName(fileDesc);
      fileName += "." + this.recordData.format;
      return fileName;
    }
  }
};
</script>
