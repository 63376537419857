import configVoice from "@/config/configVoice";

const alertResponseOptions = {
  /**
   * Creates array from alert template to be used as prop for
   * ResponseOptionsComposer.vue
   * @param template
   * @returns {Array}
   */
  createResponseOptArrayFromTemplate(template) {
    template.responseOptions = fillMissingOptions(template.responseOptions);
    const responses = [];
    template.responseOptions.forEach(resOption => {
      responses.push({
        customValue: resOption.text,
        value: !!resOption.text ? "customResOpt" : "",
        num: resOption.number,
        useCustomValue: !!resOption.text,
        link: resOption.link
      });
    });
    return responses;
  },

  getDefaultScenarioSettings() {
    return {
      enabled: false,
      options: this.getEmpty()
    };
  },

  getEmpty() {
    return [
      { num: "1", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "2", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "3", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "4", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "5", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "6", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "7", value: "", customValue: "", useCustomValue: false, link: "" },
      { num: "8", value: "", customValue: "", useCustomValue: false, link: "" }
    ];
  },

  oneOrMoreDefined(responseOptions) {
    return !!responseOptions.find(response => {
      return response.value || response.customValue;
    });
  },

  getApiFormatSmsEmail(responseObjects) {
    let i = 0;
    const len = responseObjects.length;
    const responseObj = {};
    for (i; i < len; i++) {
      const response = responseObjects[i];
      const text = response.customValue || response.value;
      const link = response.link ? "|" + response.link : "";
      const responsNum = i + 1;
      responseObj["A" + responsNum] = text + link;
    }
    return responseObj;
  },

  /**
   * @name getApiFormatVoice
   * @param responseObjects Set to null to just get repeat option
   * @param languageCode
   * @returns {{confirmText: (string|*), choices: {}}}
   */
  getApiFormatVoice(responseObjects, languageCode) {
    const choices = {};
    const voiceLanguageData = getVoiceLanguageData(languageCode);
    if (responseObjects) {
      const responses = flatten(responseObjects);
      choices["Key1"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[0],
        1
      );
      choices["Key2"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[1],
        2
      );
      choices["Key3"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[2],
        3
      );
      choices["Key4"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[3],
        4
      );
      choices["Key5"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[4],
        5
      );
      choices["Key6"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[5],
        6
      );
      choices["Key7"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[6],
        7
      );
      choices["Key8"] = createVoiceResponseOption(
        voiceLanguageData,
        responses[7],
        8
      );
    }
    choices["Key9"] = voiceLanguageData.responseOptText.repeat;
    choices["NoUserInputMsg"] =
      voiceLanguageData.responseOptText.noUserInputMsg;

    return {
      choices: choices,
      confirmText: voiceLanguageData.responseOptText.responseConfirm
    };
  },

  /**
   * Parses out text and link from respons option strings
   * @param responseOptions
   */
  parseApiFormat(responseOptions) {
    const returnData = [];

    let i = 1;
    for (i; i <= 9; i++) {
      if (!responseOptions.hasOwnProperty("A" + i)) {
        break;
      }
      const responseOption = responseOptions["A" + i];
      const splitData = responseOption.split("|");
      const text = splitData[0];
      const link = splitData[1] || "";
      // noinspection JSUnfilteredForInLoop
      returnData.push({
        number: i,
        text: text,
        link: link
      });
    }
    return returnData;
  }
};

const createVoiceResponseOption = (voiceLanguageData, option, choiceNum) => {
  if (!option) {
    return "";
  }
  const responseText = voiceLanguageData.responseOptText;
  // Add some pause for "press 1". It gets read to fast. For Norwegian at least.
  // Works well with English also.
  const choiceNumVoiceFriendly = choiceNum === 1 ? ",1," : choiceNum;

  return (
    responseText.press +
    " " +
    choiceNumVoiceFriendly +
    " " +
    responseText.for +
    ": " +
    option
  );
};

const getVoiceLanguageData = languageCode => {
  return configVoice.supportedVoiceLanguages.find(
    element => element.code === languageCode
  );
};

const flatten = responseObjects => {
  const responsesFlat = [];
  responseObjects.forEach(response => {
    responsesFlat.push(response.customValue || response.value);
  });
  return responsesFlat;
};

/**
 * Makes sure we all options are defined. We had an issue where imported
 * templates via custom script only set the options that had a value. That
 * caused that those issues where not visible in the UI.
 * @param responseOptions
 * @returns {Array}
 */
const fillMissingOptions = responseOptions => {
  const fixedOptions = [
    { number: 1, text: "", link: "" },
    { number: 2, text: "", link: "" },
    { number: 3, text: "", link: "" },
    { number: 4, text: "", link: "" },
    { number: 5, text: "", link: "" },
    { number: 6, text: "", link: "" },
    { number: 7, text: "", link: "" },
    { number: 8, text: "", link: "" }
  ];

  const optionsDefined = responseOptions.filter(response => {
    return response.text;
  });
  optionsDefined.forEach(response => {
    const targetOption = fixedOptions.find(option => {
      return option.number === response.number;
    });
    targetOption.text = response.text;
    targetOption.link = response.link;
  });

  return fixedOptions;
};

export default alertResponseOptions;
