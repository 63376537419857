<template>
  <v-select
    :items="delayOptions"
    :label="$t('delayBetweenAttempts')"
    v-model="voiceDelay"
    outlined
    @change="change"
  >
  </v-select>
</template>

<script>
export default {
  name: "VoiceDelaySelect",
  props: {
    initValue: {
      default: 5
    }
  },
  data: () => ({
    voiceDelay: 0,
    delayOptions: []
  }),
  created() {
    this.voiceDelay = this.initValue;
    this.delayOptions = [
      { text: "3 " + this.$t("minutes"), value: 3 },
      { text: "5 " + this.$t("minutes"), value: 5 },
      { text: "10 " + this.$t("minutes"), value: 10 },
      { text: "15 " + this.$t("minutes"), value: 15 },
      { text: "30 " + this.$t("minutes"), value: 30 },
      { text: "45 " + this.$t("minutes"), value: 45 },
      { text: "60 " + this.$t("minutes"), value: 60 },
      { text: "90 " + this.$t("minutes"), value: 90 },
      { text: "120 " + this.$t("minutes"), value: 120 }
    ];
  },
  methods: {
    change() {
      this.$emit("change", this.voiceDelay);
    }
  }
};
</script>
