<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :items-per-page="itemsPerPage"
    :footer-props="tblFooterProps"
    v-on:update:options="onOptionsUpdate"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>

<script>
// Wrapper component for v-data-table. Main purpose is to is to prevent
// redefining props for every table.
// Props, events and slots are passed through to v-data-table.
// This wrapper will handle items-per-page and footer-props.
// Rows per page are stored with Vuex and localStorage so that they are the same
// for every table using this wrapper. To get rows per page for pagination in
// parent table, fetch it from store with getPreferredNumTableRows as a computed
// property.

import store from "@/store";
import config from "@/config/config";
export default {
  name: "DataTable",

  // This is important to prevent Vue from adding all attributes to the root element
  inheritAttrs: false,

  computed: {
    itemsPerPage: {
      get() {
        return store.getters.getPreferredNumTableRows;
      },
      set(value) {
        store.commit("setPreferredNumTableRows", value);
      }
    }
  },

  data: () => ({
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    }
  }),

  methods: {
    onOptionsUpdate(options) {
      this.itemsPerPage = options.itemsPerPage;
    }
  }
};
</script>
