<template>
  <div v-if="text.length && subject.length">
    <v-btn outlined x-small @click="sendTestMessage">
      {{ $t("testEmail") }}
    </v-btn>
    <Tooltip>
      <template v-slot:content>
        <p>
          {{ $t("youWillRecEmail", { email: usrEmail }) }}
        </p>
        <p>
          {{ $t("youWillRecMsgLimitations") }}
        </p>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/misc/Tooltip";
import store from "@/store";
import appSnackbar from "@/util/appSnackbac";
import apiEmail from "@/api/v24/api.email";

export default {
  name: "TestEmailText",
  components: { Tooltip },

  props: {
    text: {
      required: true,
      type: String
    },
    subject: {
      required: true,
      type: String
    }
  },

  data: () => ({
    usrEmail: "",
    sender: ""
  }),

  created() {
    store.dispatch("updateUserProfile").then(usrProfile => {
      this.usrEmail = usrProfile.Bruker.Epost;
      this.usrName = usrProfile.Bruker.BrukerNavn;
      this.sender = usrProfile.Firmanavn;
    });
  },

  methods: {
    sendTestMessage() {
      const recipients = [{ Email: this.usrEmail, Name: this.usrName }];
      apiEmail
        .send(this.sender, this.subject, this.text, recipients)
          .then(() => {
            appSnackbar.setMessage(this.$t("testMessageSent"));
          })
          .catch(() => {
            appSnackbar.setMessage(this.$t("testMessageFailed"));
          });
    }
  }
};
</script>
