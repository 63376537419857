import i18n from "@/i18n";
import stringUtil from "@/util/stringUtil";
import PhoneNumber from "@/util/PhoneNumber";

export default class SmsSender {
  constructor(senderPhoneNumOrName) {
    this.sender = senderPhoneNumOrName;
  }

  /**
   * Validates SMS sender.
   * @returns {VueI18n.TranslateResult|string}
   */
  validate() {
    if (!this.sender || !this.sender.length) {
      return i18n.t("smsSenderMustBeDef");
    }
    if (seemsToBePhoneNumber(this.sender)) {
      const pn = new PhoneNumber(this.sender);
      if (!hasInternationalPrefix(this.sender)) {
        return i18n.t("missingIntPrefix");
      }
      if (!pn.isValid()) {
        return i18n.t("invalidPhoneNumber");
      }
      if (!pn.isMobile(true)) {
        return i18n.t("smsSenderMustBeMob");
      }
      if (hasWhitespace(this.sender)) {
        return i18n.t("smsSenderSpace");
      }
    } else {
      if (stringUtil.hasNorwegianLetters(this.sender)) {
        return i18n.t("smsSenderNoNo");
      }
      if (this.sender.length < 3) {
        return i18n.t("smsSenderMinChars");
      }
      if (this.sender.length > 11) {
        return i18n.t("smsSenderMaxChars");
      }
      const test = /[^a-zA-Z\d ]/.test(this.sender);
      if (test) {
        return i18n.t("smsSenderNoSymbol");
      }
    }
    return "";
  }
}

const hasWhitespace = str => {
  return str.indexOf(" ") >= 0;
};

const seemsToBePhoneNumber = str => {
  const validChars = " +0123456789";
  let char;
  let i = 0;
  for (i; i < str.length; i++) {
    char = str.charAt(i);
    if (validChars.indexOf(char) === -1) {
      return false;
    }
  }
  return true;
};

const hasInternationalPrefix = usrInput => {
  return usrInput.charAt(0) === "+";
};
