import Vue from "vue";
import timeUtil from "../util/timeUtil";
import i18n from "../i18n";

const dateFilter = dateString => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  let day;
  if (timeUtil.isToday(date)) {
    day = i18n.t("today")
  } else if (timeUtil.isYesterday(date)) {
    day = i18n.t("yesterday")
  } else {
    day = i18n.d(date, "short");
  }
  return day + " " + i18n.d(date, "timeOfDay");
};

Vue.filter("dateFilter", dateFilter);

export default dateFilter;
