<template>
  <div v-bind:class="[enabled ? 'elevation-3 channel-panel' : 'mb-n6']">
    <v-switch
      v-model="enabled"
      :label="$t('sendSms')"
      v-bind:class="[enabled ? 'mt-n1' : '']"
      @change="emitChange"
      data-testid="sendSms"
    ></v-switch>
    <div v-if="enabled">
      <SmsSender
        v-if="!hideSmsSender"
        :init-value="sender"
        v-on:change="setSender"
      />
      <v-textarea
        v-model="text"
        :label="$t('smsText')"
        outlined
        @change="emitChange"
        data-testid="smsText"
      ></v-textarea>
      <!--
       Cannot use maxlength since some symbols count as two chars
       in GSM standard.
       Setting max 857 since 918 (max at Puzzel) subtracted with a response
       URL with 61 chars
       \nhttps://groupalert-test.azurewebsites.net/r?i=90583374881241
       is 857.
       This will handle groupalert-test that has a longer URL than production.
       I will create an issue to dynamically set max-length with regareds
       to server environment.
       -->
      <CharCounter
        class="mt-n6"
        :text="text"
        v-on:validationupdate="setSmsLengthValid"
        :max="857"
      ></CharCounter>
      <DynamicTextInfo />

      <TestSmsText v-if="!smsTextInvalid" :text="text" :sender="sender" />

      <div v-if="!hideSmsSender">
        <v-alert v-if="smsTextInvalid" type="error">
          {{ $t("smsTextIsInvalid") }}. {{ $t("symbolNotAccepted") }}:
          {{ smsTextInvalid }}
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import SmsSender from "@/components/alert/SmsSender";
import CharCounter from "@/components/misc/CharCounter";
import sms from "@/util/sms";
import store from "@/store";
import DynamicTextInfo from "@/components/misc/DynamicTextInfo";
import stringUtil from "@/util/stringUtil";
import TestSmsText from "@/components/composers/TestSmsText";
import user from "@/util/user";
import apiDepartment from "@/api/v24/api.department";

export default {
  name: "SmsComposer",
  components: { TestSmsText, DynamicTextInfo, CharCounter, SmsSender },

  computed: {
    smsTextInvalid() {
      return sms.findInvalidSymbols(this.text);
    }
  },

  props: {
    initEnabled: {
      type: Boolean,
      default: false
    },
    initSender: {
      type: String,
      default: ""
    },
    initText: {
      type: String,
      default: ""
    },
    hideSmsSender: {
      type: Boolean,
      default: false
    },
    // If initSender not defined it will set the value to the selected
    // departments sender value. If that is not set/available it will
    // use the value of the users main department
    autoSmsSender: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    enabled: false,
    sender: "",
    validSender: false,
    text: ""
  }),

  created() {
    this.enabled = this.initEnabled;
    this.text = this.initText;
    this.sender = this.initSender;
    if (!this.initSender && this.autoSmsSender) {
      this.setSmsSender();
    }
  },

  methods: {
    setSender(senderProps) {
      this.sender = senderProps.text;
      this.validSender = senderProps.isValid;
      this.emitChange();
    },
    setSmsLengthValid(state) {
      this.smsLengthValid = state;
      this.emitChange();
    },
    emitChange() {
      this.text = stringUtil.fixUnsupportedWhitespace(this.text);
      this.$emit("change", {
        enabled: this.enabled,
        sender: this.sender,
        validSender: this.validSender,
        text: this.text,
        lengthValid: this.smsLengthValid,
        textValid: !this.smsTextInvalid
      });
    },
    setSmsSender() {
      store.dispatch("updateUserProfile").then(usrProfile => {
        const selectedDepId = user.getSelectedDepartment();
        if (!selectedDepId) {
          // No department selected. Default users default sms sender defined
          // in the user profile
          this.sender = usrProfile.SmsAvsender;
          this.emitChange();
        } else {
          // Use selected department sms sender, if set.
          // Workaround: API does not return the SMS sender for the main
          // department in HentBrukerProfil. So we do an API callSMS|
          // to get this.
          apiDepartment.get(selectedDepId).then(selectedDep => {
            const depSender = selectedDep.SmsAvsender;
            this.sender = depSender ? depSender : usrProfile.SmsAvsender;
            this.emitChange();
          });
        }
      });
    }
  }
};
</script>
