<template>
  <div v-bind:class="[enabled ? 'elevation-3 channel-panel' : 'mb-n6']">
    <v-switch
      v-model="enabled"
      @change="onEnableStateChanged"
      :label="$t('sendResponseOptions')"
      data-testid="sendResponseOptions"
    ></v-switch>
    <div v-if="enabled">
      <ResponsesPreview :responses="responses" :edit-button="true" />
      <v-btn small block @click="dialogCreator = true" data-testid="editResponseComposer">
        {{ $t("changeResponse") }}
      </v-btn>
    </div>
    <v-dialog v-model="dialogCreator" width="500" scrollable persistent>
      <ResponseOptionsComposer
        :responses="responses"
        v-on:done="responsesUpdated"
      />
    </v-dialog>
  </div>
</template>

<script>
import ResponseOptionsComposer from "@/components/alert/ResponseOptionsComposer";
import ResponsesPreview from "@/components/composers/ResponsesPreview";
import alertResponseOptions from "@/util/alertResponseOptions";

export default {
  name: "ResponseComposer",
  components: { ResponsesPreview, ResponseOptionsComposer },

  props: {
    inResponses: {
      required: true
    }
  },

  data: () => ({
    enabled: false,
    responses: [],
    dialogCreator: false
  }),

  created() {
    if (this.inResponses) {
      this.responses = this.inResponses;
    } else {
      this.responses = alertResponseOptions.getEmpty();
    }
    this.toggleEnabledState();
  },

  methods: {
    responsesUpdated(responses) {
      this.responses = responses;
      this.toggleEnabledState();
      this.emitChange();
    },
    toggleEnabledState() {
      const oneOrMoreDefined = alertResponseOptions.oneOrMoreDefined(
        this.responses
      );
      this.enabled = !!oneOrMoreDefined;
      this.dialogCreator = false;
    },
    onEnableStateChanged() {
      if (this.enabled) {
        this.dialogCreator = true;
      } else {
        this.responses = alertResponseOptions.getEmpty();
      }
      this.emitChange();
    },
    emitChange() {
      this.$emit("change", {
        enabled: this.enabled,
        responses: this.responses
      });
    }
  }
};
</script>
