<template>
  <v-card>
    <v-card-title v-if="title">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <pre v-if="preformatted">{{ text }}</pre>
      <div v-else>
        <span class="{ italic: italic }">
          {{ text }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SimpleCard",
  props: {
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    italic: {
      type: Boolean,
      required: false
    },
    preformatted: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style scoped>
.italic {
  font-style: italic;
}
  pre {
    overflow: auto;
    white-space: pre-wrap;
  }
</style>
