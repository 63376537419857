import i18n from "../i18n";
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import Contact from "./Contact";
import Contacts from "./Contacts";
import ContactSource from "@/util/ContactSource";
import formValidationRules from "@/util/formValidationRules";
import config from "@/config/config";

export default class GroupDataImport {
  /**
   * @name constructor
   * @param {Array} data Array of type
   * [
   * ["John", "Doe", "Job title", "+4799999991", "+4799999992",
   * "+4799999993", "my@email.com", "other info"],
   * ...
   * ]
   * @param{String} defaultCountryCode For example "+47"
   */
  constructor(data, defaultCountryCode) {
    this.data = data;
    this.defaultCountryCode = defaultCountryCode;
  }

  /**
   * @name validate
   * @param {Object} indexes Object with index numbers that tell where to find
   * differnt types of data in this.data.
   * Example:
   * {
   *   name: 0,
   *   title: 3,
   *   mobile1: 6,
   *   mobile2: 7
   *   mobile3: 8
   *   landline1: 9,
   *   landline2: 10,
   *   email: 11
   *   otherInfo: 12
   * }
   *
   * or with first name & surname:
   *
   * {
   *   firstName: 0,
   *   surname: 1,
   *   title: 3,
   *   mobile1: 6,
   *   mobile2: 7,
   *   mobile3: 8,
   *   landline1: 9,
   *   landline2: 9,
   *   email: 10,
   *   otherInfo: 11
   * }
   *
   * Fields can be set to -1 if data for fields does not exits. But remember:
   * name and at least one phone/email
   * is mandatory.
   *
   * @param {Boolean} ignoreFirstLine
   * @returns {{okContacts: Contacts, errors: []}}
   */
  validate(indexes, ignoreFirstLine) {
    let line = 0;
    let errors = [];
    const contacts = new Contacts();

    this.data.forEach(row => {
      const lineErrors = [];
      let phones = [];
      line++;
      if (ignoreFirstLine && line === 1) {
        return;
      }
      if (rowIsEmpty(row)) {
        return;
      }
      let name;
      if (indexes.hasOwnProperty("firstName")) {
        const firstName = getFieldValue(row, indexes.firstName);
        const surname = getFieldValue(row, indexes.surname);
        name = firstName + " " + surname;
      } else {
        name = getFieldValue(row, indexes.name);
      }
      const jobTitle = getFieldValue(row, indexes.title);
      let mobile1 = getFieldValue(row, indexes.mobile1);
      let mobile2 = getFieldValue(row, indexes.mobile2);
      let mobile3 = getFieldValue(row, indexes.mobile3);
      let landline1 = getFieldValue(row, indexes.landline1);
      let landline2 = getFieldValue(row, indexes.landline2);
      const email = getFieldValue(row, indexes.email);
      const otherInfo = getFieldValue(row, indexes.otherInfo);

      validateName(lineErrors, name, line);
      validateJobTitle(jobTitle, lineErrors, line);
      validateOtherInfo(otherInfo, lineErrors, line);

      if (
        !mobile1 &&
        !mobile2 &&
        !mobile3 &&
        !landline1 &&
        !landline2 &&
        !email
      ) {
        lineErrors.push(
          createErrorObject(line, i18n.t("contactDataValidationErr"))
        );
      }

      mobile1 = validatePhone(
        mobile1,
        lineErrors,
        line,
        this.defaultCountryCode
      );
      mobile2 = validatePhone(
        mobile2,
        lineErrors,
        line,
        this.defaultCountryCode
      );
      mobile3 = validatePhone(
        mobile3,
        lineErrors,
        line,
        this.defaultCountryCode
      );
      landline1 = validatePhone(
        landline1,
        lineErrors,
        line,
        this.defaultCountryCode
      );
      landline2 = validatePhone(
        landline2,
        lineErrors,
        line,
        this.defaultCountryCode
      );
      validateEmail(email, lineErrors, line);

      if (!lineErrors.length) {
        const importedContact = new Contact(name);
        importedContact.setTitle(jobTitle);
        if (mobile1) {
          importedContact.setMobile1(
            "+" + mobile1.countryCallingCode + mobile1.nationalNumber
          );
        }
        if (mobile2) {
          importedContact.setMobile2(
            "+" + mobile2.countryCallingCode + mobile2.nationalNumber
          );
        }
        if (mobile3) {
          importedContact.setMobile3(
            "+" + mobile3.countryCallingCode + mobile3.nationalNumber
          );
        }
        if (landline1) {
          importedContact.setLandline1(
            "+" + landline1.countryCallingCode + landline1.nationalNumber
          );
        }
        if (landline2) {
          importedContact.setLandline2(
            "+" + landline2.countryCallingCode + landline2.nationalNumber
          );
        }
        if (email) {
          importedContact.setEmail(email);
        }
        importedContact.setOtherInfo(otherInfo);
        const contactSrc = new ContactSource();
        contactSrc.setImport(Date.now() + line);
        importedContact.setSource(contactSrc);
        contacts.add(importedContact);
      }
      errors = errors.concat(lineErrors);
    });

    return {
      errors: errors,
      okContacts: contacts
    };
  }
}

const rowIsEmpty = row => {
  return row.every(field => field === "");
};

const validateName = (errorArray, name, line) => {
  if (!name) {
    errorArray.push(createErrorObject(line, i18n.t("missingName")));
  } else if (name.length > 50) {
    errorArray.push(createErrorObject(line, i18n.t("nameTooLong")));
  }
};

const validateJobTitle = (jobTitle, errorArray, line) => {
  if (jobTitle && jobTitle.length > 50) {
    errorArray.push(createErrorObject(line, i18n.t("jobTitleTooLong")));
  }
};

const validateOtherInfo = (text, errorArray, line) => {
  if (text && text.length > 512) {
    errorArray.push(createErrorObject(line, i18n.t("otherInfoTooLong")));
  }
};

const validatePhone = (phoneStr, errorArray, line, defaultCountryCode) => {
  if (!phoneStr) {
    return;
  }
  const phone = new PhoneNumber(phoneStr, defaultCountryCode);

  if (!phone.isValid()) {
    errorArray.push(
      createErrorObject(line, i18n.t("phoneXinvalid", { phoneNum: phoneStr }))
    );
  } else {
    // Do some extra validation if PhoneNumber did not find any errors.
    // Libphonenum used by PhoneNumber is a bit liberal on phone length
    const nationalNum = phone.getPhoneNumProperties().nationalNumber;
    if (nationalNum.length < config.phoneLengthLimit.min) {
      errorArray.push(createErrorObject(line, i18n.t("invalidPhoneNumber")));
    } else if (nationalNum.length > config.phoneLengthLimit.max) {
      errorArray.push(createErrorObject(line, i18n.t("tooManyDigits")));
    }
  }
  return phone.getPhoneNumProperties();
};

const validateEmail = (email, errorsArray, line) => {
  if (email && !emailValid(email)) {
    errorsArray.push(createErrorObject(line, i18n.t("invalidEmailAddr")));
  }
};

const createErrorObject = (lineNum, errorText) => {
  return {
    lineNum: lineNum,
    error: errorText
  };
};

const emailValid = emailAddr => {
  const email = new Email(emailAddr);
  return email.isValid();
};

const getFieldValue = (row, index) => {
  if (index === -1) {
    return "";
  }
  let fieldValue = row[index];
  if (fieldValue) {
    fieldValue = fieldValue.trim();
  }
  return fieldValue;
};
