<template>
  <div v-bind:class="[enabled ? 'elevation-3 channel-panel' : 'mb-n6']">
    <v-switch
      v-model="enabled"
      @change="emitChange"
      :label="$t('sendVoice')"
      v-bind:class="[enabled ? 'mt-n1' : '']"
      data-testid="sendVoice"
    ></v-switch>
    <div v-if="enabled">
      <VoiceSelect
        v-if="voiceProps"
        :init-voice-props="voiceProps"
        v-on:change="setVoiceProps"
        data-testid="voiceSelectVoiceComposer"
      />
      <v-textarea
        class="mb-n5"
        v-model="text"
        :label="$t('voiceText')"
        outlined
        @change="emitChange"
        data-testid="voiceText"
      ></v-textarea>
      <TestVoiceText :text="text" :voice-person="voiceProps.Name" />
      <slot name="text-footer">
        <v-btn
          outlined
          x-small
          v-if="voiceProps.Name === 'Liv'"
          @click="showVoiceTips"
        >
          {{ $t("tipsForVoiceMsg") }}
        </v-btn>
      </slot>

      <v-row v-if="permissionFiles && showSoundFileOption">
        <v-col cols="12">
          <v-checkbox
            v-model="useAudioFile"
            @change="useAudioFileChange"
            :label="$t('useSoundFile')"
            :hint="$t('useSoundFileHint')"
            persistent-hint
            class="mb-4"
          />
        </v-col>
      </v-row>

      <!-- v-if on dialogManageFiles is to force component destroy/create to
      fetch fresh data after dialogMangeFiles is closed -->
      <div v-if="useAudioFile && !dialogManageFiles">
        <v-row>
          <v-col cols="10">
            <FileSelect
              :key="fileSelectKey"
              :init-file-id="audioFileId"
              :type-audio="true"
              v-on:change="setAudioFile($event.FileId)"
            />
          </v-col>
          <v-col cols="2" v-if="audioFileId">
            <v-btn icon @click="dialogPlayback = true" class="mt-2" outlined>
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div>
          <v-btn @click="dialogRecord = true" outlined small class="mr-2">
            <v-icon small>
              mdi-microphone
            </v-icon>
            {{ $t("newRecording") }}
          </v-btn>
          <v-btn @click="manageAudioFiles" outlined small>
            {{ $t("manageFiles") }}
          </v-btn>
        </div>
        <br /><br />
      </div>
    </div>

    <v-expansion-panels
      v-model="advancedOptPanel"
      v-if="enabled"
      class="mt-2"
      data-testid="advancedVoiceComposer"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t("advancedVoiceSettings") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <VoiceAttemptSelect v-model="attempts" @change="emitChange" />
          <div v-if="attempts">
            <VoiceDelaySelect
              :init-value="delay"
              v-on:change="setVoiceDelay"
              data-testid="delayVoiceComposer"
            />
          </div>
          <v-switch
            v-show="showDeputyOptions"
            v-model="deputy"
            @change="emitChange"
            :label="$t('useDeputy')"
            data-testid="useDeputyVoiceComposer"
          ></v-switch>
          <div v-if="deputy" class="mt-n4 text-body-2">
            {{ $t("deputyUsageInfo") }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialogVoiceTips" :max-width="dialogWidth.medium">
      <v-card>
        <v-card-title>
          {{ $t("tipsForVoiceMsg") }}
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" outlined dense>
            {{ $t("thisOnlyAppliesToVoice") }}
            "Liv" ({{ $t("norwegian").toLowerCase() }} bokmål)
          </v-alert>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("text") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pronounced") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>..</td>
                  <td>"punktum"</td>
                </tr>
                <tr>
                  <td>"pause"</td>
                  <td>
                    <i>{{ $t("insertsShortBreak") }}</i>
                  </td>
                </tr>
                <tr>
                  <td>kl. 12:00</td>
                  <td>"klokka tolv null null"</td>
                </tr>
                <tr>
                  <td>"09.04"</td>
                  <td>"niende april"</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogVoiceTips = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogManageFiles" :max-width="dialogWidth.large">
      <v-card>
        <v-card-title>
          {{ $t("manageFiles") }}
        </v-card-title>
        <v-card-text>
          <FileManagement
            :key="fileAdminKey"
            :is-audio-file="true"
            :protected-files="protectedFiles"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogManageFiles = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRecord"
      v-if="dialogRecord"
      :max-width="dialogWidth.medium"
    >
      <AudioRecorder
        :save-and-use-btn="true"
        v-on:cancel="dialogRecord = false"
        v-on:done="setAudioFile"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogPlayback"
      v-if="dialogPlayback"
      :max-width="dialogWidth.small"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <AudioPlayback
            :audio-file-id="audioFileId"
            :autoplay="true"
            class="mt-6"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogPlayback = false">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VoiceSelect from "@/components/alert/VoiceSelect";
import VoiceAttemptSelect from "@/components/alert/VoiceAttemptSelect";
import VoiceDelaySelect from "@/components/alert/VoiceDelaySelect";
import TestVoiceText from "@/components/composers/TestVoiceText";
import config from "@/config/config";
import FileManagement from "@/components/files/FileManagement";
import FileSelect from "@/components/files/FileSelect";
import store from "@/store";
import AudioRecorder from "@/components/files/AudioRecorder.vue";
import AudioPlayback from "@/components/files/AudioPlayback.vue";
import configVoice from "@/config/configVoice";
import eventLogger from "@/util/eventLogger";

export default {
  name: "VoiceComposer",
  components: {
    AudioPlayback,
    AudioRecorder,
    FileSelect,
    FileManagement,
    TestVoiceText,
    VoiceDelaySelect,
    VoiceAttemptSelect,
    VoiceSelect
  },
  props: {
    initVoiceProps: {
      required: true
    },
    initEnabled: {
      default: false
    },
    initText: {
      default: ""
    },
    initAttempts: {
      default: 0
    },
    initDelay: {
      default: 5
    },
    initDeputy: {
      default: false
    },
    initPanelState: {
      default: null
    },
    showDeputyOptions: {
      default: true
    },
    showSoundFileOption: {
      default: false
    }
  },

  watch: {
    initText(newText) {
      this.text = newText;
    }
  },

  computed: {
    protectedFiles() {
      if (this.audioFileId) {
        return [this.audioFileId];
      }
      return [];
    },
    permissionFiles() {
      return store.state.userProfile?.Bruker._permissions.files;
    }
  },

  data: () => ({
    enabled: false,
    voiceProps: null,
    text: "",
    advancedOptPanel: null,
    attempts: 0,
    delay: 0,
    deputy: false,
    dialogVoiceTips: false,
    dialogManageFiles: false,
    dialogWidth: config.dialogWith,
    dialogRecord: false,
    dialogPlayback: false,
    useAudioFile: false,
    audioFileId: null,
    fileSelectKey: 0,
    fileAdminKey: 0
  }),

  created() {
    store.dispatch("updateUserProfile");
    this.enabled = this.initEnabled;
    this.text = this.initText;
    this.attempts = this.initAttempts;
    this.delay = this.initDelay;
    this.deputy = this.initDeputy;
    this.advancedOptPanel = this.initPanelState;
    if (this.initVoiceProps?.Id) {
      this.voiceProps = this.initVoiceProps;
    } else {
      configVoice.getDefaultProps().then(props => {
        this.voiceProps = props;
      });
    }
  },

  methods: {
    showVoiceTips() {
      this.dialogVoiceTips = true;
    },
    setVoiceProps(voiceProps) {
      this.voiceProps = voiceProps;
      this.emitChange();
    },
    setVoiceDelay(delay) {
      this.delay = delay;
      this.emitChange();
    },
    setAudioFile(fileId) {
      this.dialogRecord = false;
      this.audioFileId = fileId;
      // Force component update
      this.fileSelectKey++;
      this.fileAdminKey++;

      this.emitChange();
    },
    manageAudioFiles() {
      this.dialogManageFiles = true;
    },
    useAudioFileChange() {
      if (!this.useAudioFile) {
        this.audioFileId = null;
        this.emitChange();
      }
    },
    emitChange() {
      if (this.audioFileId && !this.text) {
        // Workaround for issue where API insists on text even though file is set
        this.text = " ";
      }
      this.$emit("change", {
        enabled: this.enabled,
        text: this.text,
        voiceProps: this.voiceProps,
        advancedOptPanelState: this.advancedOptPanel,
        attempts: this.attempts,
        delay: this.delay,
        deputy: this.deputy,
        audioFile: this.audioFileId,
        // API supports "confirmed" (receiver presses key on phone) and
        // "active" (call get answered). We supported both initilally, but
        // are now forcing use of "confirmed" since "active" does not handle
        // voice mail (call flow stops when machine answers)
        endOnState: "confirmed"
      });
    }
  }
};
</script>
