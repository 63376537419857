<template>
  <div>
    {{ $t("chars") }}: {{ charCount.count }}/{{ max }} ({{ charCount.numOfSms }}
    SMS)
    <v-alert type="error" v-if="!lengthOk">
      {{ $t("smsTooLong") }}
    </v-alert>
  </div>
</template>

<script>
import sms from "../../util/sms";
import config from "@/config/config";

export default {
  name: "CharCounter",
  props: {
    text: {
      required: true,
      type: String,
      default: ""
    },
    max: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    lengthOk: true
  }),

  computed: {
    charCount() {
      const charCount = sms.charCount(this.text);
      // We got the SMS char count, but we also need to check if "dynamic text"
      // is used: [name], [navn] and [name] (and any other futre tag names)
      // gets replaced by receiver name on server side.
      // Name can be up to 50 chars when creating a contact in GroupAlert, so
      // we need to make place for this
      config.dynamicNameTags.forEach(nameTag => {
        const searchStr = "\\[" + nameTag + "\\]";
        const regExp = new RegExp(searchStr, "g");
        const matches = this.text.match(regExp);
        if (matches && matches.length > 0) {
          const numOfMatches = matches.length;
          const maxLengthName = 50;
          const dynamicCharsLength = maxLengthName * numOfMatches;
          const tagTextLength = searchStr.length * numOfMatches;
          const charsInSms = dynamicCharsLength - tagTextLength;
          charCount.count += charsInSms;
        }
      });
      this.lengthOk = charCount.count <= this.max;
      this.$emit("validationupdate", this.lengthOk);
      return charCount;
    }
  }
};
</script>
