import api from "@/api/v24/api";
import alertResponseOptions from "@/util/alertResponseOptions";
import user from "@/util/user";

const apiScenario = {
  getAll() {
    return new Promise(resolve => {
      const params = {
        Avdeling: user.getSelectedDepartment()
      };
      api.execute("HentScenarier", params, "Scenarios").then(scenarios => {
        scenarios.map(scenario => {
          if (!scenario.Custom?.responses) {
            scenario.Custom = {
              responses: alertResponseOptions.getDefaultScenarioSettings()
            };
          }
          return scenario;
        });
        resolve(scenarios);
      });
    });
  },
  start(scenario) {
    const params = { Scenario: scenario };
    return api.execute("StartScenario", params);
  },
  change(scenario) {
    const params = { Scenario: scenario };
    return api.execute("EndreScenario", params);
  },
  create(scenario) {
    const params = { Scenario: scenario };
    return api.execute("NyttScenario", params);
  },
  delete(id) {
    return api.execute("SletteScenario", { Id: id });
  }
};

export default apiScenario;
