import Vue from "vue";

/**
 * Filters out any syntax "hacks" for voice pronunciation
 * @param input
 */
const voiceResponseOptPreviewFilter = input => {
  return input.replace(" ,1, ", " 1 ");
};

Vue.filter("voiceResponseOptPreviewFilter", voiceResponseOptPreviewFilter);

export default voiceResponseOptPreviewFilter;