<template>
  <v-card>
    <v-form v-model="formValid" autocomplete="off" @submit.prevent>
      <v-card-title>
        {{ $t("uploadFile") }}
      </v-card-title>
      <v-card-text>
        <LoadingSpinner v-if="loading" />
        <div v-else>
          <v-text-field
            autofocus
            v-model="description"
            maxlength="50"
            outlined
            :label="$t('description')"
            :rules="formValidationRules.requiredRule"
          />
          <FileUpload v-on:change="setFile" :accept="acceptedFiles" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="emitDone">{{ $t("cancel") }}</v-btn>
        <v-btn
          @click="save"
          class="primary"
          :disabled="!description || !file || loading"
        >
          {{ primaryBtnText }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import apiFile from "@/api/v24/api.file";
import FileUpload from "@/components/import/FileUpload";
import formValidationRules from "@/util/formValidationRules";
import config from "@/config/config";
import LoadingSpinner from "@/components/misc/LoadingSpinner";

export default {
  name: "AlertFileUpload",
  components: { LoadingSpinner, FileUpload },

  props: {
    isAudioFile: {
      type: Boolean,
      default: false
    },
    saveAndUseBtn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    acceptedFiles() {
      const okAudio = config.supportedAudioFileTypes;
      const okEmail = config.supportedEmailFileTypes;
      return this.isAudioFile ? okAudio : okEmail;
    },

    primaryBtnText() {
      return this.saveAndUseBtn ? this.$t("uploadAndUse") : this.$t("upload");
    },
  },

  data: () => ({
    file: null,
    description: "",
    formValid: null,
    formValidationRules: formValidationRules,
    loading: false
  }),

  methods: {
    setFile(file) {
      this.file = file;
    },

    save() {
      this.loading = true;
      let promise;
      if (this.isAudioFile) {
        promise = apiFile.uploadAudio(this.file, this.description);
      } else {
        promise = apiFile.uploadEmailFile(this.file, this.description);
      }
      promise
        .then(res => {
          this.emitDone(res.FileId);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    emitDone(fileId) {
      this.$emit("done", fileId);
    }
  }
};
</script>
