<template>
  <div>
    <ul v-for="selectedFile in selectedFiles">
      <li>{{ selectedFile.FileName }}</li>
    </ul>
    <div v-if="totalSize !== '0.00'">
      {{ $t("totalFileSize") }}: {{ totalSize }} MB
    </div>
    <v-alert type="error" v-if="fileSizeLimitWarning">
      {{ $t("totFileSizeError", { count: fileSizeLimit }) }}
    </v-alert>
    <v-btn outlined small @click="dialogSelect = true">
      <v-icon @click="edit(item)" left>
        mdi-attachment
      </v-icon>
      {{ $t("addAttachment") }}
    </v-btn>

    <v-dialog
      v-model="dialogSelect"
      v-if="dialogSelect"
      :max-width="dialogWidth.small"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("selectFiles") }}
        </v-card-title>
        <v-card-text>
          <v-select
            :items="files"
            v-model="selectedFiles"
            @change="emitChange"
            :label="$t('files')"
            multiple
            item-value="FileId"
            :item-text="getItemText"
            outlined
            return-object
          />
          <v-btn @click="dialogNew = true" outlined small class="mr-2">
            {{ $t("newAttachment") }}
          </v-btn>
          <v-btn @click="dialogManageFiles = true" outlined small>
            {{ $t("manageFiles") }}
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogSelect = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNew"
      v-if="dialogNew"
      :max-width="dialogWidth.small"
    >
      <AlertFileUpload
        :save-and-use-btn="true"
        v-on:cancel="dialogNew = false"
        v-on:done="addAlertFile"
      />
    </v-dialog>

    <v-dialog v-model="dialogManageFiles" :max-width="dialogWidth.large">
      <v-card>
        <v-card-title>
          {{ $t("manageFiles") }}
        </v-card-title>
        <v-card-text>
          <FileManagement
            :is-audio-file="false"
            v-on:change="fetchFiles"
            :protected-files="selectedFiles.map(selFile => selFile.FileId)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogManageFiles = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiFile from "@/api/v24/api.file";
import config from "@/config/config";
import FileManagement from "@/components/files/FileManagement";
import numberUtil from "@/util/numberUtil";
import emailUtil from "@/util/EmailUtil";
import AudioRecorder from "@/components/files/AudioRecorder.vue";
import AlertFileUpload from "@/components/files/AlertFileUpload.vue";

export default {
  name: "EmailAttachmentSelect",
  components: { AlertFileUpload, AudioRecorder, FileManagement },

  data: () => ({
    dialogSelect: false,
    dialogManageFiles: false,
    dialogNew: false,
    files: [],
    selectedFiles: [],
    dialogWidth: config.dialogWith,
    fileSizeLimitWarning: false,
    fileSizeLimit: null
  }),

  computed: {
    totalSize() {
      const attachmentData = emailUtil.attachmentsFileSizeIsOk(
        this.selectedFiles
      );
      this.fileSizeLimit = attachmentData.limit;
      this.fileSizeLimitWarning = !attachmentData.sizeOk;
      return attachmentData.totFileSizeMb.toFixed(2);
    }
  },

  created() {
    this.fetchFiles();
  },

  methods: {
    fetchFiles() {
      return new Promise(resolve => {
        apiFile.getAll("E").then(res => {
          this.files = res.Files;
          resolve();
        });
      });
    },
    addAlertFile(fileId) {
      this.dialogNew = false;
      this.fetchFiles().then(() => {
        this.selectedFiles = this.selectedFiles.concat(
          this.files.filter(file => file.FileId === fileId)
        );
        this.emitChange();
      });
    },
    getItemText(item) {
      return item.Description + " - " + item.FileName;
    },
    emitChange() {
      this.$emit("change", this.selectedFiles);
    }
  }
};
</script>
